import React, { useEffect, useState } from "react";
import Selector from "../../components/Selector/Selector";
import "./Events.css";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import centralApi from "../../services/centralApi";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { selectedPlatform } from "../../helperFunctions/events";
import { aggregateDataByPlatform } from "../../helperFunctions/events";
import { getDatesRange } from "../../helperFunctions/common";
import Spinner from "../../components/Spinner/Spinner";
import AddAtributeModel from "../../components/Models/AddAttributesModel/AddAttributesModal";
import { downloadCsv } from "../../helperFunctions/common";
import useMessage from "../../hooks/useMessage";
import CustomHighChart from "components/Charts/CustomHighChart/CustomHighChart";

interface DrillDownAttributesProps {
  platformVersion: any;
  excludedKeys: any;
  loadings: any;
  dateRange: any;
}

const DrillDownAttributes: React.FC<DrillDownAttributesProps> = ({
  platformVersion,
  excludedKeys,
  loadings,
  dateRange,
}) => {
  type getDateRange = {
    startDate: string;
    endDate: string;
  };

  type DateArray = string[];
  interface EventList {
    data: any[];
  }
  const [form] = Form.useForm();
  const { showError, showSuccess } = useMessage();
  const [statsData, setStatsData] = useState<any[]>([]);

  const [eventList, setEventList] = useState<EventList>({ data: [] });

  const { appId } = useSelector((state: any) => state.activeApp);

  const loginUser = useSelector((state: any) => state.loginUser);
  
  const { endDate_C, endDate_CNew, startDate_C, startDate_CNew } =
    dateRange || {};
  const api_key = loginUser.data.api_key;

  const [allEvents, setAllEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModalAttributes, setSelectedModalAttributes] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState([]); // Initialize with an empty array

  const [loading, setLoading] = useState(loadings);

  async function getEventList() {
    const payload = {
      app_id: appId,

      api_key: api_key,
      d: new Date().getTime(),
    };
    const url: string = `/o/metrices/getEventList`;
    if (appId && api_key) {
      try {
        setLoading(true);
        const data = await centralApi("GET", url, null, payload);
        setEventList(data);
        const eventLists = data.data.map((item: any) => item.event);
        const filteredEventLists = eventLists.filter(
          (event: any) => event.trim() !== ""
        );

        setAllEvents(filteredEventLists);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  }
  const allDates = selectedPlatform(platformVersion, statsData);
  const aggregatedData = aggregateDataByPlatform(
    platformVersion,
    statsData,
    allDates,
    excludedKeys
  );

  useEffect(() => {
    if (appId && api_key && dateRange) {
      getEventList();
    }
  }, [loginUser, endDate_C, platformVersion, appId, api_key]);

  const handleEventSelect = (event: any) => {
    setSelectedEvent(event); // Set the selected eve
  };
  async function getEventAttributeStats(attributeName: any) {
    const payload = {
      args: JSON.stringify({
        startDate: startDate_C,
        endDate: endDate_C,
        startDateNew: startDate_CNew,
        endDateNew: endDate_CNew,
        eventName: selectedEvent,
        attributeName: attributeName,
      }),
      app_id: appId,

      api_key: api_key,
      d: startDate_C,
    };
    const url: string = `/o/metrices/getEventAttributeStats`;
    try {
      setLoading(true);
      const data = await centralApi("GET", url, null, payload);
      setStatsData(data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  const handleAtttributeSelect = (event: any) => {
    const attributeName = event;

    getEventAttributeStats(attributeName);
  };

  useEffect(() => {
    // Use the selectedEvent value directly within the effect
    const selectedEventData = eventList?.data?.find(
      (item: any) => item.event === selectedEvent
    );
    const selectedList = selectedEventData ? selectedEventData.list : [];
    setSelectedAttribute(selectedList);
  }, [selectedEvent, eventList]); // Add eventList as a dependency if it's used inside the effect

  const handleModalOpen = () => {
    setIsModalVisible(true);
  };
  const handleModalOk = () => {
    setIsModalVisible(false);

    form.submit();
  };
  const getDateRange: getDateRange = {
    startDate: startDate_CNew,
    endDate: endDate_CNew,
  };
  const fileName = "eventsAttribute.csv";

  const allDatesRange: DateArray = getDatesRange(getDateRange);

  const onFormSubmit = async () => {
    const payload = {
      app_id: appId,
      api_key: api_key,
      offset: 19800,
      args: JSON.stringify({
        filter: {
          eventName: selectedEvent,
          attributeName: selectedModalAttributes,
          dateRange: {
            sd: startDate_C,
            ed: endDate_C,
            dates: allDatesRange,
          },
          platform: platformVersion,
        },
      }),
      filename: fileName,
    };

    const url: string = `/o/metrices/downloadEventsAttributeStats`;

    try {
      const response = await centralApi("GET", url, null, payload, true);
      downloadCsv(response, fileName);
      setLoading(false);
      showSuccess(t("downloadedSuccessfullyLabel"));
    } catch (error: any) {
      setLoading(false);
      showError(error.message);
    }
  };
  function labelFormatter(this: any) {
    const legendLabel: string = this.name; // Explicitly specify the type as string
    const total =
      totalsByProperty.find((item) => item.name === legendLabel)?.total || 0;
    const formattedLabel = `${legendLabel} (${total})`; // Display legend label with total count

    return formattedLabel;
  }
  const categories2 = aggregatedData.map((item) => item.date);

  const seriesData2 =
    (aggregatedData || []).length > 0
      ? Object.keys(
          aggregatedData.reduce((acc, obj) => {
            Object.keys(obj).forEach((key) => {
              if (key !== "date") acc[key] = true;
            });
            return acc;
          }, {} as { [key: string]: boolean })
        ).map((category) => {
          const data = aggregatedData.map((item) => item[category] || 0);
          const total = data.reduce((acc, curr) => acc + (curr || 0), 0);
          return {
            name: category,
            data: data,
            total: total,
          };
        })
      : [];
  const totalsByProperty = seriesData2.map((series: any) => ({
    name: series.name,
    total: series.data.reduce((acc: number, value: number) => acc + value, 0),
  }));
  const options = {
    title: {
      text: "",
    },
    xAxis: {
      categories: categories2,
      title: {
        text: "Date",
      },
    },
    yAxis: {
      title: {
        text: "Value",
      },
      gridLineWidth: 0, // Remove horizontal grid lines

      stackLabels: {
        enabled: false,
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
     },
    series: seriesData2.map((series: any, index: number) => ({
      name: series.name,
      data: series.data,

      type: "column", // Specify column type
    })),

    legend: {
      align: "right", // Position legends on the right side
      verticalAlign: "middle", // Align vertically in the middle
      layout: "vertical", // Display legends vertically
      scrollable: true, // Enable legend scrolling
      itemStyle: {
        cursor: "pointer",
      },
      labelFormatter: labelFormatter, // Reference the labelFormatter function here
      series: {
        events: {
          legendItemClick: function (this: any) {
            const seriesIndex = this.index;
            const series = this.chart.series[seriesIndex];
            series.setVisible(!series.visible); // Toggle series visibility in the chart
            return false; // Prevent default behavior of toggling series visibility in the legend
          },
        },
      },
    },
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleAttributeModalSelect = (selectedAttributes: any) => {
    
    setSelectedModalAttributes(selectedAttributes);
  };

  function EventsCardDrillAttributes({ title, className }: any) {
    return (
      <div className={`Events-card-container ${className} `}>
        <div>
          <div className="d-flex justify-content-between mt-4">
            <div className="Events-card-heading d-flex justify-content-between flex-wrap align-items-center p-2">
              <strong>{title}</strong>
            </div>
            <div>
              <Form form={form} layout="inline" onFinish={onFormSubmit}>
                <Form.Item
                  // label="Events"
                  name="eventName"
                  rules={[
                    { required: true, message: "Please select an option" },
                  ]}
                >
                  <Selector
                    style={{ minWidth: 160 }}
                    placeholder="Select Events"
                    maxTagCount="responsive"
                    options={allEvents.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={handleEventSelect}
                    defaultValue={selectedEvent}
                  />
                </Form.Item>
                <Form.Item
                  // label="Events"
                  name="attributeName"
                  rules={[
                    { required: true, message: "Please select an option" },
                  ]}
                >
                  <Selector
                    style={{ minWidth: 160 }}
                    placeholder="Select Attributes"
                    maxTagCount="responsive"
                    options={selectedAttribute.map((item: any) => ({
                      value: item?.name,
                      label: item?.name,
                    }))}
                    onChange={handleAtttributeSelect}
                    defaultValue={selectedAttribute}
                  />
                </Form.Item>
                <Form.Item>
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    onClick={handleModalOpen}
                    style={{ border: "none", width: "90px" }}
                  >
                    {t("downloadLabel")}
                  </PrimaryButton>
                </Form.Item>
              </Form>
              <AddAtributeModel
                isVisible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                selectedAttributes={selectedAttribute}
                handleAttributeSelect={handleAttributeModalSelect}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid p-sm-2 p-1 ">
      <div className="px-sm-3 px-1 events-container">
        <div>
          <EventsCardDrillAttributes
            title={t("eventsChartLabelAttributes")}
            className="mx-1"
          ></EventsCardDrillAttributes>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <CustomHighChart options={options} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DrillDownAttributes;
