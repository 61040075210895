
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingModule from "highcharts/modules/exporting";
import offlineExportingModule from 'highcharts/modules/offline-exporting';

import dataModule from "highcharts/modules/data";
import ExportData from "highcharts/modules/export-data";
import SkeletonLoader from "components/SkeletonLoader/SkeletonLoader";
import NoData from "components/NoData/NoData";
import funnelModule from "highcharts/modules/funnel";

// Initialize Highcharts modules
funnelModule(Highcharts);
exportingModule(Highcharts);
dataModule(Highcharts);
ExportData(Highcharts);
offlineExportingModule(Highcharts);  // For enabling offline exporting (download without server)



interface CustomHighChartProps {
  options: any;
}

const CustomHighChart: React.FC<CustomHighChartProps> = ({ options }) => {
  // Set chart options (without credits)
  const modifiedOptions = {
    ...options,
    credits: {
      enabled: false, // Disable Highcharts credits 
    },
    exporting: {
      enabled: true,  // Enable exporting toolbar
      
    },
  };

  // Check if the chart has data
  const hasData = options && options.series && options.series.length > 0;
  return (
    <>
    {hasData ? (

    <div >
      {options?.loading?<SkeletonLoader paragraph={{rows:8}} type="default"/>:<HighchartsReact   highcharts={Highcharts} options={modifiedOptions} loading={true} />}
    </div>)
    :(
    <NoData/>)}
    </>
  );
};

export default CustomHighChart;
