
import {  Form } from "antd";
import { useForm } from "antd/es/form/Form";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import TextInput from "components/Inputs/TextInput/TextInput";
import Selector from "components/Selector/Selector";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import {useSelector } from "react-redux";
interface AddRowProps {
  addRowState: { open: boolean };
  setAddRowState: (value: { open: boolean }) => void;
  setFormData: (data: any) => void;
  columns:any;
  onSave: (data: any) => void;  // Adding onSave as a prop to handle the form data in the parent

}

const AddRow: FC<AddRowProps> = ({
  addRowState,
  setAddRowState,
  setFormData,
  columns,
  onSave
}) => {
  const [form] = useForm();
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [loading,setLoading]=useState(false)
  const [metaData, setMetaData] = useState<any>({});
  const [placeholder, setPlaceholder] = useState("");
  const [data, setData] = useState([]);

  
  //handle form change 
  const handleFormChange = (key: string, value: string) => {
    setData((prevState) => ({ ...prevState, [key]: value }));
  };
  //handle form submit
  const onSubmit = (values) => {
    setFormData(values)
    onSave(values);  // Call the parent's onSave function with form values

  };
  return (
    <CustomDrawer
      footer={
        <div>
          <PrimaryButton type="primary" loading={loading} disabled={loading} onClick={() => form.submit()}>
            {t("saveLabel")}
          </PrimaryButton>
        </div>
      }
      open={addRowState.open}
      placement="right"
      closable={false}
      width={600}

      onClose={() => setAddRowState({ open: false })}
    >
      {columns.length >0?
      <div className=" mt-1 ">
        <h5 className="fw-semibold">{t("addRowLabel")}</h5>
        <div className="mt-3">
          <Form
            colon={false}
            labelAlign="left"
            layout="vertical"
            form={form}
            onFinish={onSubmit}
          >
             {/* Loop through columns and generate form items */}
        {columns.map((column, index) => (
          
          <Form.Item
            key={index}
            label={column.title} // Use column title as label
            name={column.key}  // Use column's key as the name
          >
           
              <TextInput
                placeholder={`Enter ${column.title}`}
              />
            
          </Form.Item>
        ))}
      
         </Form>
        </div>
      </div>:null}
    </CustomDrawer>
  );
};

export default AddRow;
