import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { Helmet } from "react-helmet";
import TextInput from "components/Inputs/TextInput/TextInput";
import "./Login.css";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail } from "../../../validator/antdValidator";
import { useTranslation } from "react-i18next";
import Spinner from "components/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { removeActiveApp } from "../../../redux/features/app/activeAppSlice";
import {
  fetchLoginUser,
  logoutUser,
} from "../../../redux/features/user/loginUserSlice";
import { STATUS } from "redux/constant";
import useMessage from "hooks/useMessage";
import centralApi from "services/centralApi";
import { processString } from "helperFunctions/string";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import {EDStatusEnum, SiteSettingsEnum, SSOTypeEnum } from "utils/enums";
import { useForm } from "antd/es/form/Form";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [loginPageInfo, setLoginPageInfo] = useState({
    csrfToken: "",
    adAuthType: "",
    portal_name: "",
    captchaSvg:"",
    siteSettings:{captcha:""}
  });
  const [loginType, setLoginType] = useState(SSOTypeEnum.LOGIN_EMAIL);
  const { status } = useSelector((state: any) => state.loginUser);
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState(true);
  const [form] = useForm();
  const { showError } = useMessage();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<any>({});
  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false);

  const sendDataToApi = async ({
    email,
    password,
    _csrf,
    captcha
  }: {
    email: string;
    password: string;
    _csrf: string;
    captcha:string
  }) => {
    try {
      const payload = {
        email: btoa(email),
        password: processString(password),
        _csrf: _csrf,
        selectedButton: loginType,
        captcha
      };
      const res = await centralApi(
        "POST",
        API_ENDPOINTS.LOGIN_URL,
        payload,
        null
      );
      //if login success
      if (res.auth) {
        //if user is loggedin fetch the login user
        dispatch(fetchLoginUser());
      }
      if (res.redirect) {
        navigate(res.redirectPath);
      }
      
    } catch (error: any) {
      setIsCaptchaVisible(false)
      setLoginPageInfo(error.response?.data || {})
      showError(error.response?.data?.message || t("somethingWrongLabel"));
    }
    form.resetFields(["captcha"])
  };
  //handle input change
  const handleFieldChange=(data:any)=>{
    setFormValues((prevState:any)=>({...prevState,...data}))
  }
  const fetchLogin = async () => {
    try {
      setLoading(true);
      //fetch configration for login page
      const res = await centralApi(
        "GET",
        API_ENDPOINTS.GET_LOGIN_URL,
        null,
        null
      );
      if (res.redirect) {
        if (res.redirectPath == ROUTES.AUTHENTICATION) {
          navigate(res.redirectPath, {
            state: { otpsent: res.otpsent, alreadysent: res.alreadysent },
          });
        } else {
          if (res.redirectPath == ROUTES.LOGOUT) {
            dispatch(logoutUser());
          } else if (
            res.redirectPath !== ROUTES.LOGIN &&
            status !== STATUS.LOGGEDOUT
          ) {
            if (res.redirectPath !== ROUTES.SIGNUP) {
              dispatch(fetchLoginUser());
            }
            navigate(res.redirectPath, { replace: true });
          }
        }
      }
      if(res?.siteSettings && res.siteSettings.is_sso == EDStatusEnum.ENABLE && (res.siteSettings.sso_type == SiteSettingsEnum.ACTIVE_DIRECTORY || res.siteSettings.sso_type == SiteSettingsEnum.SAML_BASED)){
        setLoginType(SSOTypeEnum.LOGIN_SSO)
     }
      setLoginPageInfo(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    dispatch(removeActiveApp());
    fetchLogin();
  }, []);
  useEffect(() => {
    if (loginPageInfo?.csrfToken == undefined) {
      fetchLogin();
    }
  }, [loginPageInfo.csrfToken]);
  const handleSubmit = async () => {
    const captchaStatus=loginPageInfo.siteSettings?.captcha
    if (isCaptchaVisible || captchaStatus==EDStatusEnum.DISABLE || !captchaStatus) {
        //Call API 
        sendDataToApi({...formValues,_csrf:loginPageInfo.csrfToken});
      } 
      else {
        setIsCaptchaVisible(true);
      }
    }
  
  return (
    <div className="container-fluid p-0 login-container d-flex justify-content-center align-items-center  h-100">
      <Helmet>
        <title>Appice | Login</title>
      </Helmet>
      {loading ? (
        <Spinner />
      ) : (
        <div className=" p-5 login-form-wrapper">
          <h4 className="pb-2 text-center">
            {t("signInTitleWithoutName")}
            {loginPageInfo?.portal_name
              ? loginPageInfo?.portal_name
              : t("companyNameLabel")}
          </h4>
          <Form
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            validateTrigger={["onBlur"]}
          >
            {!isCaptchaVisible ? (
              <>
                <Form.Item
                  label={
                    loginType == SSOTypeEnum.LOGIN_SSO
                      ? t("ssoIdLabel")
                      : t("emailLabel")
                  }
                  name="email"
                  rules={[
                    {
                      required: true,
                      message:
                        loginType == SSOTypeEnum.LOGIN_SSO
                          ? `${t("plzEnterYourSsoIdMsg")}`
                          : `${t("emptyEmailMsg")}`,
                    },
                    {
                      validator:
                        loginType == SSOTypeEnum.LOGIN_EMAIL
                          ? validateEmail
                          : undefined,
                    },
                  ]}
                >
                  <TextInput
                    placeholder={
                      loginType == SSOTypeEnum.LOGIN_SSO
                        ? t("enterYourSsoIdMsg")
                        : t("emailLabel")
                    }
                    onChange={(e:any)=>handleFieldChange({email:e.target.value})}
                  />
                </Form.Item>
                <Form.Item
                  label={t("passwordLabel")}
                  name="password"
                  rules={[
                    { required: true, message: `${t("emptyPasswordMsg")}` },
                  ]}
                >
                  <TextInput type="password"
                  onChange={(e:any)=>handleFieldChange({password:e.target.value})}
                  placeholder={t("passwordLabel")} />
                </Form.Item>
              </>
            ) : (
              <>
              <div className="mb-2">
                <img src={`data:image/svg+xml;base64,${loginPageInfo.captchaSvg}`}/>
              </div>
              <Form.Item rules={[{required:true,message:`${t("captchaNotMatchLabel")}`}]} name="captcha">
                <TextInput onChange={(e:any)=>handleFieldChange({captcha:e.target.value})}/>
              </Form.Item>
              </>
            )}

            <Form.Item>
              <PrimaryButton
                type="primary"
                className="w-100 mt-2 "
                style={{ height: 40 }}
                htmlType="submit"
              >
                {t("signInLabel")}
              </PrimaryButton>
            </Form.Item>
          </Form>

          <div className="text-center">
            <span>{t("dontAccLabel")}</span>
            <Link className="mx-1 login-links text-nowrap" to="/signup">
              {t("signUpLabel")}
            </Link>
          </div>
          {loginType == SSOTypeEnum.LOGIN_EMAIL && (
          <div className="  d-flex justify-content-center pt-2 flex-wrap">
            <span>{t("forgetPasswordLabel")}</span>
            <Link className="mx-1 login-links" to="/reset">
              {t("resetPassword")}
            </Link>
          </div>
          )} 
        </div>
      )}
    </div>
  );
};

export default Login;
