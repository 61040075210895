"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ICampaignType = exports.PushActionBtnTypes = exports.PushEventStatusEnum = exports.IIosKeyType = exports.IAppModeProduction = exports.ICertificateType = exports.IResponseMessage = exports.IMakeModel = exports.IPushTypeEnum = void 0;
/**
 * Enum defining types of PUSH providers, currently only supporting FCM (Firebase Cloud Messaging).
 */
var IPushTypeEnum;
(function (IPushTypeEnum) {
    IPushTypeEnum["FCM"] = "fcm";
    IPushTypeEnum["APNS"] = "apns";
    IPushTypeEnum["CNS"] = "cns";
    IPushTypeEnum["HMS"] = "hms";
})(IPushTypeEnum = exports.IPushTypeEnum || (exports.IPushTypeEnum = {}));
/**
 * Enum for defining the models
*/
var IMakeModel;
(function (IMakeModel) {
    IMakeModel["HUAWEI"] = "HUAWEI";
})(IMakeModel = exports.IMakeModel || (exports.IMakeModel = {}));
/**
 * Enum defining response messages for push notifications.
 */
var IResponseMessage;
(function (IResponseMessage) {
    IResponseMessage["SUCCESS"] = "success";
    IResponseMessage["FAILURE"] = "failure";
})(IResponseMessage = exports.IResponseMessage || (exports.IResponseMessage = {}));
/**
 * Enum defining types of certificates (iOS and Android).
*/
var ICertificateType;
(function (ICertificateType) {
    ICertificateType["IOS"] = "ios";
    ICertificateType["ANDROID"] = "android";
})(ICertificateType = exports.ICertificateType || (exports.ICertificateType = {}));
/**
 * Enum defining application modes (production and development).
*/
var IAppModeProduction;
(function (IAppModeProduction) {
    IAppModeProduction["PROD"] = "prod";
    IAppModeProduction["DEV"] = "dev";
})(IAppModeProduction = exports.IAppModeProduction || (exports.IAppModeProduction = {}));
/**
 * Enum defining types of iOS key (P8 and P12).
*/
var IIosKeyType;
(function (IIosKeyType) {
    IIosKeyType["P8"] = "p8";
    IIosKeyType["P12"] = "p12";
})(IIosKeyType = exports.IIosKeyType || (exports.IIosKeyType = {}));
;
var PushEventStatusEnum;
(function (PushEventStatusEnum) {
    PushEventStatusEnum["CREATED"] = "created";
    PushEventStatusEnum["QUEUED"] = "queued";
    PushEventStatusEnum["SENDING"] = "sending";
    PushEventStatusEnum["SENT"] = "sent";
    PushEventStatusEnum["DELIVERED"] = "delivered";
    PushEventStatusEnum["FAILED"] = "failed";
    PushEventStatusEnum["RECEIVED"] = "received";
    PushEventStatusEnum["DISMISSED"] = "dismissed";
    PushEventStatusEnum["UNDELIVERED"] = "undelivered";
})(PushEventStatusEnum = exports.PushEventStatusEnum || (exports.PushEventStatusEnum = {}));
var PushActionBtnTypes;
(function (PushActionBtnTypes) {
    PushActionBtnTypes["REMIND_ME_LATER"] = "rml";
    PushActionBtnTypes["REPLY"] = "rly";
    PushActionBtnTypes["DEEP_LINK"] = "dl";
    PushActionBtnTypes["LANDING_PAGE"] = "lp";
    PushActionBtnTypes["CUSTOM"] = "cus";
    PushActionBtnTypes["DISMISS"] = "dis";
})(PushActionBtnTypes = exports.PushActionBtnTypes || (exports.PushActionBtnTypes = {}));
/**
 * ICampaignType enum to represent campaign Type
*/
var ICampaignType;
(function (ICampaignType) {
    ICampaignType["GEOFENCE"] = "g";
    ICampaignType["ENGAGEMENT"] = "e";
    ICampaignType["TRANSACTIONAL"] = "t";
})(ICampaignType = exports.ICampaignType || (exports.ICampaignType = {}));
