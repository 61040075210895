import React from "react";
import {
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  UploadOutlined,
} from "@ant-design/icons";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { v4 as uuid } from "uuid";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";
import { Col, Upload } from "antd";
import Selector from "../../../../../components/Selector/Selector";
import { JsonTree } from "./JsonTree";
import TreeSelector from "components/Selector/TreeSelector/TreeSelector";
import "./CustomData.css";
import centralApi from "services/centralApi";
import { useDispatch, useSelector } from "react-redux";
import { CustomDataEnum } from "utils/enums";
import useMessage from "hooks/useMessage";
import { t } from "i18next";
import { API_ENDPOINTS } from "utils/constants";
import { getToken } from "redux/features/app/authTokenSlice";

export const CustomData = ({ customDataState, onChange, hideTreeSelector = false }: any) => {
  const { appId } = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);

  const dispatch: any = useDispatch();
  const { NUMBER, IMAGE, TREE, BOOLEAN, DEVICE } = CustomDataEnum;
  const { showSuccess, showError } = useMessage();

  // Add a new entry to the customDataState
  const handleAddClick = () => {
    const newId = uuid();
    const updatedData = { ...customDataState, [newId]: { key: '', value: '', type: 'string' } };
    onChange(updatedData); // Notify parent about the state change
  };
  

  // Remove a key-value pair from the customDataState
  const handleRemoveClick = (idToRemove: string) => {
    const updatedData = { ...customDataState };
    delete updatedData[idToRemove];
    onChange(updatedData); // Notify parent about the state change
  };

  // Handle the type change (boolean, string, etc.)
  const handleTypeChange = (value: string, id: string) => {
    const updatedData = { ...customDataState };
    updatedData[id] = { ...updatedData[id], type: value };
    onChange(updatedData); // Notify parent about the state change
  };

  // Handle the image upload
  const handleImageUpload = async (value: any, id: string) => {
    try {
      const formData = new FormData();
      formData.append("image", value.file);
      formData.append("image_id", value.file.name);
      formData.append("appid", appId);

      const res = await centralApi(
        "POST",
        API_ENDPOINTS.SAVE_CAMPAIGN_IMAGES,
        formData,
        null,
        false,
        authToken
      );

      dispatch(getToken());
      const updatedData = { ...customDataState };
      updatedData[id] = { ...updatedData[id], value: res.baseURL + res.url };
      onChange(updatedData); // Notify parent about the state change
      showSuccess(t("sucessfullyUploadedLabel"));
    } catch (error) {
      dispatch(getToken());
      showError((error as Error).message);
    }
  };

  const valueTypes = [
    { value: "boolean", label: t("booleanLabel"), hasChildren: false },
    { value: "string", label: t("stringLabel"), hasChildren: false },
    { value: "number", label: t("numberLabel"), hasChildren: false },
    { value: "json", label: t("objectLabel"), hasChildren: false },
    {
      label: t("imageLabel"),
      value: "img",
      checkable: false,
      hasChildren: true,
      selectable: false,
      children: [
        { label: t("uploadFromDeviceLabel"), value: "device", hasChildren: false },
        { label: t("uploadFromWebLabel"), value: "image", hasChildren: false },
      ],
    },
  ];

  // Update customDataState with transformed JSON data
  const updateCustomDataState = (id: string, jsonData: any) => {
    const transformedData: any = {};
    jsonData.forEach((item: any) => {
      transformedData[item.key] = item.value;
    });

    const updatedData = { ...customDataState };
    updatedData[id] = { ...updatedData[id], value: encodeURIComponent(JSON.stringify(transformedData)) };
    onChange(updatedData); // Notify parent about the state change
  };

  // Decode JSON string to key-value format
  const decodeJsonValue = (value: string) => {
    if (value !== "" && /^%7B.*%7D$/.test(value)) {
      const decodedData = JSON.parse(decodeURIComponent(value));
      return Object.entries(decodedData)?.map(([key, value]) => ({
        key,
        value,
      }));
    } else {
      return "";
    }
  };

  // Render third input field based on the selected type
  const renderThirdInput = (type: string, id: string) => {
    switch (type) {
      case BOOLEAN:
        return (
          <Selector
            value={customDataState[id]?.value}
            className="custom-data-common-width"
            onChange={(e: any) => {
              const updatedData = { ...customDataState };
              updatedData[id] = { ...updatedData[id], value: e.target.value };
              onChange(updatedData); // Notify parent about the state change
            }}
            options={[
              { label: t("trueLabel"), value: "true" },
              { label: t("falseLabel"), value: "false" },
            ]}
          />
        );
      case NUMBER:
        return (
          <TextInput
            type="number"
            className="custom-data-common-width"
            value={customDataState[id]?.value}
            onChange={(e: any) => {
              const updatedData = { ...customDataState };
              updatedData[id] = { ...updatedData[id], value: e.target.value };
              onChange(updatedData); // Notify parent about the state change
            }}
            placeholder={t("valueLabel")}
          />
        );
      case TREE:
        return (
          <JsonTree
            initialJsonTreeData={decodeJsonValue(customDataState[id]?.value)}
            updateCustomDataState={(treeData: any) => updateCustomDataState(id, treeData)}
          />
        );
      case IMAGE:
        return (
          <TextInput
            type="text"
            className="custom-data-common-width"
            maxLength={1000}
            value={customDataState[id]?.value}
            onChange={(e: any) => {
              const updatedData = { ...customDataState };
              updatedData[id] = { ...updatedData[id], value: e.target.value };
              onChange(updatedData); // Notify parent about the state change
            }}
            placeholder={t("enterUrlLabels")}
          />
        );
      case DEVICE:
        return (
          <div className="custom-data-upload-width">
            <Upload
              showUploadList={{ showDownloadIcon: false, showPreviewIcon: false, showRemoveIcon: false }}
              maxCount={1}
              multiple={false}
              beforeUpload={() => false}
              onChange={(value: any) => handleImageUpload(value, id)}
            >
              <PrimaryButton className="custom-data-common-width">
                <UploadOutlined className="me-2" /> {t("uploadLabel")}
              </PrimaryButton>
            </Upload>
          </div>
        );
      default:
        return (
          <TextInput
            className={!hideTreeSelector ? "custom-data-common-width" : ""}
            maxLength={1000}
            value={customDataState[id]?.value}
            onChange={(e: any) => {
              const updatedData = { ...customDataState };
              updatedData[id] = { ...updatedData[id], value: e.target.value };
              onChange(updatedData); // Notify parent about the state change
            }}
            placeholder={t("valueLabel")}
          />
        );
    }
  };

  return (
    <>
      {Object.keys(customDataState)
        
        ?.map((id: any, index: number) => (
          <Col key={id} className="d-flex custom-data-parent mt-1 gap-1">
            <TextInput
              className={!hideTreeSelector ? "custom-data-key-width" : ""}
              maxLength={1000}
              value={customDataState[id]?.key}
              onChange={(e: any) => {
                const updatedData = { ...customDataState };
                updatedData[id] = { ...updatedData[id], key: e.target.value };
                onChange(updatedData); // Notify parent about the state change
              }}
              placeholder={t("keyLabel")}
            />
            <TreeSelector
              className="custom-data-tree-width"
              value={customDataState[id]?.type}
              onChange={(value: string) => handleTypeChange(value, id)}
              treeData={valueTypes}
              height={800}
              hidden={hideTreeSelector}
              placeholder={t("typeLabel")}
            />
            {renderThirdInput(customDataState[id].type, id)}
            {Object.keys(customDataState)?.length - 1 === index ? (
              <PrimaryButton style={{ width: 40 }} onClick={handleAddClick}>
                <PlusCircleTwoTone />
              </PrimaryButton>
            ) : (
              <PrimaryButton
                className="custom-data-button-width"
                onClick={() => handleRemoveClick(id)}
              >
                <MinusCircleTwoTone />
              </PrimaryButton>
            )}
          </Col>
        ))}
    </>
  );
};
