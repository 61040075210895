import { Form, FormInstance, Tag } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import "./AudienceEvent.css";
import filterIcon from "images/filterIcon.svg";
import TimeWindow from "../WindowContainers/TimeWindow/TimeWindow";
import { useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { t } from "i18next";
import Selector from "components/Selector/Selector";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import FrequencyWindow from "../WindowContainers/FrequencyWindow/FrequencyWindow";
import AttributeWindow from "../WindowContainers/AttributeWindow/AttributeWindow";
import { AudienceEnum } from "utils/enums";
import { getJourneyTypeList } from "helperFunctions/events";
// Type definition for component props
type propType = {
  form: FormInstance; // Form instance
  id: string | number; // ID of the component/block
  segment: any;
  isFunnel: boolean;
  onDataChange: (data: any) => void;
};
const AudienceEvent: React.FC<propType> = ({
  form,
  id,
  segment,
  isFunnel,
  onDataChange,
}) => {
  const eventList = useSelector((state: any) => state.eventList.data); // Selecting event list from Redux state
  // Local state
  const [attributeList, setAttributeList] = useState<any[]>([]); // State for attribute list
  const attributeIntialValue = {
    // Initial value for attributes
    attr: undefined,
    operator: undefined,
    value: undefined,
    e_operator: AudienceEnum.AND,
  };

  // Handle input change function
  const handleInputChange = (data: any, operandReset = false) => {
    // Dispatching action to update audience segment information in Redux state
    let modifiedData = data;
    if (data["attributes"] && data["attributes"]?.length == 0 && !isFunnel) {
      modifiedData = {
        ...data,
        attr: undefined,
        operator: undefined,
        value: undefined,
      };
    }
    // reset operand field if operand is undefined
    if (operandReset) {
      form.setFieldsValue({[`operand_${id}`]: undefined });
    }
    onDataChange(modifiedData);
  };
  // Memoized computation of events filtered by journey
  const eventsByJourney = useMemo(() => {
    // Initialize an empty array to hold filtered events
    let event_by_journey = [];
    // Check if the journey in the segment is not empty
    if (segment.journey) {
       // Filter the events from eventList by matching journey
      event_by_journey = eventList?.filter((event: any) => {
        return event.journey == segment.journey;
      });
    }
    // Map filtered events to the desired structure (label, value, list)
    return event_by_journey?.map((event: any) => ({
      label: event?.displayname, // Display name of the event
      value: event?.event, // value of the event
      list: event?.list, // List of attributes
    }));
  }, [segment?.journey,eventList]);
  // Updating attribute list when segment operand changes
  useEffect(() => {
    // Determine the operand based on whether it's a funnel or not
    const operand = isFunnel ? segment?.eventName : segment?.operand;
    if (operand) {
       // Loop through eventsByJourney to find a match
      eventsByJourney.forEach(({ value, list }: {value:string,list:any[]}) => {
        // If value matches the operand, set the attribute list
        if (value == operand) {
          setAttributeList(list);
             // If it's a funnel, trigger onDataChange with the matched list
          if (isFunnel) {
            // If it's a funnel, trigger onDataChange with the matched list
            onDataChange({ list });
          }
        }
      });
    } else {
        // If no operand is found, reset the attribute list
      setAttributeList([]);
    }
  }, [segment?.operand, segment?.eventName]);
  return (
    <div className="audience-event-box">
      <div className="box-body-container mx-1  px-1">
        <div className="d-flex flex-column py-2 ">
          {!isFunnel && (
            <div className="d-flex flex-row flex-wrap gap-md-4 gap-2">
              <div className="d-flex gap-2">
                {(segment?.since?.have == AudienceEnum.H ||
                  segment?.since?.have !== AudienceEnum.HN) && (
                  <PrimaryButton
                    onClick={() => {
                      handleInputChange(
                        {
                          since: { ...segment.since, have: AudienceEnum.H },
                          operand: undefined,
                        },
                        true
                      );
                    }}
                  >
                    <img src={filterIcon} alt="" />
                    <span className="mx-2 w-100"> {t("didPerfromLabel")} </span>
                  </PrimaryButton>
                )}
                {(segment?.since?.have == AudienceEnum.HN ||
                  segment?.since?.have !== AudienceEnum.H) && (
                  <PrimaryButton
                    onClick={() => {
                      handleInputChange(
                        {
                          since: { ...segment.since, have: AudienceEnum.HN },
                          operand: undefined,
                        },
                        true
                      );
                    }}
                  >
                    <img src={filterIcon} alt="" />
                    <span className="mx-2 w-100">
                      {" "}
                      {t("didNotPerfromLabel")}{" "}
                    </span>
                  </PrimaryButton>
                )}
                {segment?.since?.have && (
                  <CloseOutlined
                    className="mx-2"
                    onClick={() => {
                      handleInputChange(
                        {
                          since: {},
                          attributes: [],
                          operand: undefined,
                        },
                        true
                      );
                    }}
                  />
                )}
              </div>
              <Form.Item
                rules={[{ required: true, message: "" }]}
                name={`category_${id}`}
                className="m-0"
                initialValue={
                  segment?.isLive ? t("liveEventLabel") : t("pastEventLabel")
                }
              >
                <Selector disabled />
              </Form.Item>
              <Form.Item
                label={t("fromJourneyLabel")}
                name={`journey_${id}`}
                initialValue={segment.journey}
                className="m-0 input-size-xlg"
              >
                <Selector
                  onChange={(value) => {
                    handleInputChange(
                      {
                        since: { have: segment?.since?.have },
                        attributes: [],
                        operand: undefined,
                        journey:value
                      },
                      true
                    );
                  }}
                  placeholder={`${t("selectJourneyMsg")}`}
                  options={getJourneyTypeList(eventList)}
                ></Selector>
              </Form.Item>
            </div>
          )}
          <div className="d-flex flex-wrap flex-row gap-md-4 gap-2 align-items-center py-3">
            <div className="d-flex align-items-center ">
              <Form.Item
                rules={[{ required: true, message: "" }]}
                className="event-inputs"
                name={`operand_${id}`}
                initialValue={segment?.operand || segment?.eventName}
              >
                <Selector
                  disabled={!segment?.since?.have && !isFunnel}
                  showSearch
                  onChange={(value: string) => {
                    handleInputChange({
                      operand: value,
                      attributes: [],
                    });
                  }}
                  options={eventsByJourney}
                  placeholder={`${t("selectEventMsg")}`}
                />
              </Form.Item>
            </div>
            {!isFunnel && (
              <FrequencyWindow
                frequency={segment?.frequency}
                setFrequency={(frequency) => handleInputChange({ frequency })}
              />
            )}
            {!isFunnel && (
              <>
                {segment?.since?.time == undefined && segment.since?.have !== AudienceEnum.HN? (
                  <PrimaryButton
                    onClick={() => {
                      handleInputChange({
                        since: { ...segment?.since, time: "" },
                      });
                    }}
                    disabled={
                      !segment.since?.have ||
                      segment.since?.have == AudienceEnum.H
                    }
                    className="audience-w120px"
                  >
                    {t("timeWindowLabel")}
                  </PrimaryButton>
                ) : (
                  <TimeWindow
                    form={form}
                    eventList={eventsByJourney}
                    setValue={(value) => {
                      handleInputChange({
                        since: { ...segment?.since, ...value },
                      });
                    }}
                    id={id}
                    value={segment?.since}
                  ></TimeWindow>
                )}
              </>
            )}
          </div>
          <div className="d-flex flex-wrap w-100 flex-column">
            {!segment.attributes || segment?.attributes?.length == 0 ? (
              <PrimaryButton
                className="audience-w120px"
                onClick={() =>
                  handleInputChange({ attributes: [attributeIntialValue] })
                }
                disabled={attributeList.length == 0}
              >
                {t("attributeLabel")}
              </PrimaryButton>
            ) : (
              segment.attributes.map((attribute: any, attrIndex: number) => {
                return (
                  <AttributeWindow
                    form={form}
                    id={id}
                    key={`${id}_${attrIndex}`}
                    totalAttributes={segment?.attributes?.length}
                    setValue={(data) => {
                      let modifiedAttribute = [...segment.attributes];
                      if (data) {
                        modifiedAttribute[attrIndex] = isFunnel
                          ? {
                              ...data,
                              attributeName: data.attr,
                              attr: undefined,
                            }
                          : data;
                      }
                      //remove the attribute object from attributes in redux
                      else {
                        modifiedAttribute.splice(attrIndex, 1);
                      }
                      handleInputChange({ attributes: modifiedAttribute });
                    }}
                    index={attrIndex}
                    value={attribute}
                    attributes={attributeList}
                  />
                );
              })
            )}
            <div>
              {segment.attributes && segment?.attributes?.length > 0 && (
                <>
                  <Tag
                  className="cursor-pointer"
                    onClick={() => {
                      handleInputChange({
                        attributes: [
                          ...segment.attributes,
                          attributeIntialValue,
                        ],
                      });
                    }}
                  >
                    {t("plusWhereLabel")}
                  </Tag>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudienceEvent;
