import "./UsersInteractions.css";
import React, { useState, useMemo, useEffect } from "react";
import dayjs from "dayjs";
import Selector from "components/Selector/Selector";
import { t } from "i18next";
import CustomTimeline from "components/CustomTimeline/CustomTimeline";
import NoData from "components/NoData/NoData";

interface EventHistoryProps {
  eventHistory: Record<string, { el: Array<EventData> }>;
}

interface EventData {
  k: string;
  t: number;
  p: number;
  segment: Record<string, string>;
}

const UserInteractions: React.FC<EventHistoryProps> = ({ eventHistory }) => {
  const [selectedDid, setSelectedDid] = useState<string | null>(null);
  const [didOptions, setDidOptions] = useState<{ value: string; label: string }[]>([]);

  // Fetch did options directly from eventHistory keys when component mounts
  useEffect(() => {
    const didEventOptions = Object.keys(eventHistory).map((did) => ({
      value: did,
      label: did,
    }));
    setDidOptions(didEventOptions);
    const didValue = Object.entries(eventHistory).find(([key, { el }]) => el?.length);
    if (didValue) {
        const [key] = didValue;
        setSelectedDid(key);
      }
    else{
     setSelectedDid(didEventOptions[0]?.value)
    }
   
  }, [eventHistory]);

  // Memoize the modified event data for the selected DID
  const modifiedEventData = useMemo(() => {
    if (selectedDid) {
      return eventHistory[selectedDid]?.el
        ?.sort((a, b) => b.t - a.t) // Sort in descending order based on `t`
        ?.map((event) => {
          const availableString = Object.entries(event.segment || {})
            .map(([key, value]) => `${key}:${value}`)
            .join(", ");

          return {
            label: dayjs.unix(event.t).format("DD/MM/YY:HH:mm:ss"),
            children: `${event.k}, ${availableString}`,
          };
        });
    }
    return [];
  }, [selectedDid, eventHistory]);

  return (
    <div>
      <div className="pt-3 mt-2 border-bottom">
        <h6 className="m-2">{t("usersInterctionsLabel")}</h6>
      </div>
      <div className="my-2">
        <Selector
          className="input-size-xlg"
          placeholder="Select DID"
          options={didOptions}
          value={selectedDid} // Set the current value
          onChange={(value) => setSelectedDid(value)} // Update the selected DID
        />
      </div>
      
        <div className="c-three-sixty-recent-activity user-interactions-events-width p-4">
          <div className="m-2 mb-4 pt-1">
            <h6 className="m-0">{t("behaviouralDataLabel")}</h6>
          </div>
          {modifiedEventData && modifiedEventData?.length > 0 ? (
            <div> 
            <div className="d-flex p-0  user-interactions-timeline-wrapper pt-2">
            <CustomTimeline mode="left" layout="event" items={modifiedEventData} />
            </div>
            </div>
        ) : (
            <div>
            <NoData />
            </div>
        )}  
        </div>
  
    </div>
  );
};

export default UserInteractions;
