import { useEffect, useMemo, useState } from "react"
import "./Campaign.css"
import closeIcon from "../../images/_close.svg"
import checkIcon from "../../images/_check.svg"
import documentIcon from "../../images/document.png"
import deleteIcon from "../../images/trashpink.svg"
import { Dropdown, type MenuProps } from "antd"
import eyeIcon from "../../images/eye.png"
import reviewIcon from "../../images/Comment.svg"
import editIcon from "../../images/_edit.svg"
import downloadIcon from "../../images/downloadNew.svg";
import { downloadCsv } from "../../helperFunctions/common";
import {
  CheckCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  CopyTwoTone,
  DeleteOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import Search from "../../components/Inputs/Search/Search"
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton"
import InnerNav from "../../components/InnerNav/InnerNav"
import CustomTable from "../../components/CustomTable/CustomTable"
import { Helmet } from "react-helmet"
import { Link, useNavigate } from "react-router-dom"
import { copyText } from "../../helperFunctions/string"
import CustomBreadCrumb from "../../components/CustomBreadCrumb/CustomBreadCrumb"
import Selector from "../../components/Selector/Selector"
import CustomToolTip from "../../components/CustomToolTip/CustomToolTip"
import { t } from "i18next"
import useMessage from "../../hooks/useMessage"
import { useDispatch, useSelector } from "react-redux"
import { setCampaignData } from "../../redux/features/campaign/campaignDataSlice"
import centralApi from "../../services/centralApi"
import ConfirmPopUp from "../../components/PopUp/ConfirmPopUp/ConfirmPopUp"
import { formatDate, getCurrentDate } from "../../helperFunctions/date"
import { setSelectedTemplate } from "../../redux/features/templates_/templateByIdSlice"
import { API_ENDPOINTS } from "utils/constants"
import { DateRangeSelector } from "components/DateTimeSelectors/DateRangeSelector/DateRangeSelector"
import { DateRangeData } from "@customTypes/dateRange"
import { CampaignChannelTypeEnum, CampaignStatusEnum, DateFormatEnum } from "utils/enums"
import useDebouncedValue from "hooks/useDebouncedState"
import { PAGE_OPTIONS, TABLE_OPTIONS } from "utils/constants/selectorOptions"
import { getToken } from "redux/features/app/authTokenSlice"
import { roleCheckTypes } from "utils/constants/constants"
import AccessControl from "components/HOC/AccessControl/AccessControl"
interface ColumnStatus {
  clickedTo: boolean;
  conversion: boolean;
  ctr: boolean;
  impressionRate: boolean;
  receivedTo: boolean;
}
function Campaign() {
  const [campData, setCampData] = useState([])
  const { showError, showSuccess } = useMessage()
  const [totalCamp, setTotalCamp] = useState(0)
  const [campaignTabFilter, setCampaignTabFilter] = useState<string>(CampaignStatusEnum.ACTIVE)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useDebouncedValue<{
    inputValue : string
  }>({ inputValue: "" }, 500);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const loginUser = useSelector((state: any) => state.loginUser)
  const { appId } = useSelector((state: any) => state.activeApp)
  const dispatch : any = useDispatch()
  const [pageData, setPageData] = useState(PAGE_OPTIONS.PAGINATION)
  const [dateRange, setDateRange] = useState<DateRangeData | null>(null);

  const [columnStatus, setColumnStatus] = useState<ColumnStatus>({
    clickedTo: false,
    conversion: false,
    ctr: false,
    impressionRate: false,
    receivedTo: false
  });
  const [selectedChannel, setSelectedChannel] =useState<string>( CampaignChannelTypeEnum.ALL)
  const navigate = useNavigate()
  const handleTextCopy = async (text: string) => {
    const res = await copyText(text)
    showSuccess(res.message)
  }

  // Fetching channel status from redux store
  const features = useSelector((state: any) => state.featuresSetting.data);
  const channelStatus = features[0]?.channel_status;

  // Memoize the options generation for performance
  const options = useMemo(() => {
    // Pre-generate a map of campaign channel enum types for easier access
    const allChannels = Object.values(CampaignChannelTypeEnum);
    
    // If channel_status is undefined, return all enum channels
    if (!channelStatus) {
      return allChannels.map((key) => ({
        value: key,
        label: key,
      }));
    }

    // Otherwise, filter the channels where the status is true
    return allChannels
      .filter((key) => key == CampaignChannelTypeEnum.ALL || channelStatus[key] === true) // Only include enabled channels
      .map((key) => ({
        value: key,
        label: key,
      }));
  }, [channelStatus]); 

  const filename = t('CampaignCsvFileName');
  /**
   * downloadCampaignCsv it downloads the csv file for all campaigns 
   */
  const downloadCampaignCsv = async () => {
    const payload = {
      api_key: loginUser?.data?.api_key,
      app_id: appId,
      status : campaignTabFilter,
      sd: dateRange?.startDate_C ? dateRange.startDate_C * 1000 : undefined, // handle undefined by returning undefined
      ed: dateRange?.endDate_C ? dateRange.endDate_C * 1000 : undefined, // handle undefined by returning undefined
      filename : filename,
      ...(selectedRowKeys?.length > 0 && { cid: JSON.stringify(selectedRowKeys) }) // Add cid only if selectedRowKeys has data

    };
    const url: string = API_ENDPOINTS.DOWNLOAD_ACTIVE_CAMPDATA;

    try {
      const response = await centralApi("GET", url, null, payload, true);
      downloadCsv(response, filename);
      showSuccess(t("downloadedSuccessfullyLabel"));
    } catch (error: any) {
      showError(error.message);
    }
  };
  const startDateSeconds = dateRange?.startDate_C !== undefined ? dateRange.startDate_C * 1000 : null;
  const endDateSeconds = dateRange?.endDate_C !== undefined ? dateRange.endDate_C * 1000 : null;

  //get the campaign data from api and set the state
  async function getCampaignData() {
    try {
      const apiKey = loginUser.data.api_key
      if (apiKey && appId) {
        setLoading(true)
        dispatch(getToken())
        const params={app_id: appId,
        api_key: apiKey,
        status: campaignTabFilter,
        search: search,
        startDateSeconds,
        endDateSeconds,
        page: JSON.stringify(pageData),
        channelType:selectedChannel==CampaignChannelTypeEnum.ALL ?undefined:selectedChannel,
      }
        
      const { data, totalCampaigns }=  await centralApi("GET", API_ENDPOINTS.GET_CAMPAIGNS_BY_STATUS, null, params)
        setLoading(false)
        setCampData( data?.retVal )
        setTotalCamp(totalCampaigns)
        if(data?.columnStatus){
          setColumnStatus(data?.columnStatus)
        }
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  // when the page number of table changes
  const onPageChange = (value: any) => {
    setPageData(value)
  }
  const deleteCamp = async (cid: any) => {
    try {
      const params = {
        cid,
        app_id: appId,
        args: JSON.stringify({
          isdelete: true,
          ud: getCurrentDate(),
        }),
        api_key: loginUser?.data?.api_key,
      }
      await centralApi("GET", API_ENDPOINTS.DELETE_CAMPAIGN_URL, null, params)
      getCampaignData()
      showSuccess(t("campaignDeletedMsg"))
    } catch (error) {
      console.log("error")
    }
  }
  async function copyCamp(cid: any) {
    try {
      const payload = {
        cid,
        app_id:appId,
        api_key: loginUser.data.api_key,
        args: JSON.stringify({ d: true }),

      }

   
      await centralApi("GET", API_ENDPOINTS.COPY_CAMPAIGN_URL, null, payload)
      getCampaignData()
      showSuccess(t("copiedIntoDraftMsg"))
    } catch (error) {
      showError(t("somethingWrongLabel"))
    }
  }
  //will change the status of campaign
  async function setCampStatus(campaign: any, newStatus: string) {
    try {
      const payload= {
        args: JSON.stringify({
          a:true,
          ud:getCurrentDate(),
          st: newStatus
        }),
        api_key: loginUser.data.api_key,
        cid: campaign.cid,
        app_id: appId,
      
      }
      const data=await centralApi("GET",API_ENDPOINTS.SET_ACTIVE_CAMPAIGN_URL,null,payload)
      if (data?.status === "OK") {
        showSuccess(data.message)
        getCampaignData()
      } else if (data?.status == "ERROR") {
        showError(data.message)
      }
    } catch (error) {
      showError(t("somethingWrongLabel"))
    }
  }

  useEffect(() => {
    getCampaignData()
  }, [campaignTabFilter, pageData,search, dateRange,selectedChannel])
  useEffect(() => {
    dispatch(setCampaignData({}))
    dispatch(setSelectedTemplate(null))
  }, [])
  const handleBulkEdit = (value: any) => {
    if (value =="Copy"){
      // copyCamp(selectedRowKeys)
    }
    if (value =="Delete"){
      // deleteCamp(selectedRowKeys)
    }
    if (value =="ACTIVE" || value == 'DRAFT'){
      // setCampStatus(selectedRowKeys,value)
    }
    
  }
  const items = [
    {
      key: "1",
      label: (
        <div className="d-flex gap-2 align-items-center" onClick={() => handleBulkEdit("Copy")} >
          <CopyOutlined className="campaign-table-container-bulk-edit-icons" />
          <span>{t("copyLabel")}</span>
        </div>
      ),
    },
    campaignTabFilter === CampaignStatusEnum.ACTIVE || campaignTabFilter === CampaignStatusEnum.DRAFT
      ? {
          key: "2",
          label: (
            <div className="d-flex gap-2 align-items-center"onClick={() => handleBulkEdit(campaignTabFilter === CampaignStatusEnum.ACTIVE ?CampaignStatusEnum.DRAFT:CampaignStatusEnum.ACTIVE)}>
              {campaignTabFilter === CampaignStatusEnum.ACTIVE ? (
                <CloseOutlined className="campaign-table-container-bulk-edit-icons" />
              ) : (
                <CheckCircleOutlined className="campaign-table-container-bulk-edit-icons"  />
              )}
              <span>
                {campaignTabFilter === CampaignStatusEnum.ACTIVE ? t("setDraftLabel") : t("setActiveLabel")}
              </span>
            </div>
          ),
        }
      : null,
    campaignTabFilter === CampaignStatusEnum.DRAFT || campaignTabFilter === CampaignStatusEnum.PAST
      ? {
          key: "3",
          label: (
            <div className="d-flex gap-2 align-items-center"onClick={() => handleBulkEdit("Delete")}>
              <DeleteOutlined className="campaign-table-container-bulk-edit-icons" />
              <span>Delete</span>
            </div>
          ),
        }
      : null,
  ]

  
  const columns: any = [
    {
      title: (
        <div className="d-flex justify-content-between align-items-baseline">
          {t("campaignDetailsLabel")}
          {selectedRowKeys.length >= 1 && (   //drop down for bulk edit 
            <div>
              <Dropdown menu={{ items }}>
                <MoreOutlined />
              </Dropdown>
            </div>
          )}
        </div>
      ),
      render: (details: any) => (
        <div className="d-flex flex-column" style={{ minWidth: "220px" }}>
          <Link to="#">{details.nm}</Link>
          <span className="text-disabled d-flex align-items-center justify-content-between">
            {t("IdLabel")}: {details._id}
            <CopyTwoTone onClick={() => handleTextCopy(details._id)} />
          </span>
        </div>
      ),
    },
    {
      title: t("typeLabel"),
      dataIndex: "t",
    },
    {
      title: t("audienceLabel"),
      dataIndex: "segmentName",
      render: (aud: string) => {
        return (
          <p className="p-0 m-0" style={{ minWidth: "130px" }}>
            {aud}
          </p>
        )
      },
    },
    ...(columnStatus?.receivedTo 
    ? [

    {
      title: t("reachableLabel"),
      dataIndex: "reach",
      className: "text-center",
    },
  ]
  : []),
    
    {
      title: t("deliveredLabel"),
      dataIndex: "pushedTo",
      className: "text-center",
    },
    ...(columnStatus?.clickedTo ?[

    {
      title: t("clickedLabel"),
      dataIndex: "clickedTo",
      className: "text-center",
    },]:[]),
    ...(columnStatus?.ctr ?[

    {
      title: t("CTRLabel"),
      dataIndex: "ctr",
      className: "text-center",
    },]:[]),
    ...(columnStatus?.conversion ?[

    {
      title: t("conversionLabel"),
      dataIndex: "conversion",
      className: "text-center",
    },]:[]),

    ...(columnStatus?.impressionRate ?[

      {
        title: t("impressionRateLabel"),
        dataIndex: "impressionRate",
        className: "text-center",
      },]:[]),
    {
      title: t("startDateLabel"),
      dataIndex: "sd",
      className: "text-center",
      render: (sd: number) => {
        return <div>{formatDate(sd,'DD-MM-YYYY hh:mm A')}</div>;
      },
    },
    {
      title: t("endDateLabel"),
      dataIndex: "ed",
      className: "text-center",
      render: (ed: number) => {
        return <div>{formatDate(ed,'DD-MM-YYYY hh:mm A')}</div>;
      },
    },
   
    {
      title: t("actionLabel"),
      width: "200px",
      className: "align-item text-center",
      render: (campaign: any) => {
        const imgStyle = {
          width: 16,
        }
        return (
          
          <span className="d-flex justify-content-center campaign-action-btns">
          <AccessControl accessControl={{role:[roleCheckTypes.Checker]}}>
           {   campaignTabFilter == CampaignStatusEnum.ACTIVE ? (
              <CustomToolTip title="Set Draft">
                <PrimaryButton
                  className="action-btn mx-1"
                  onClick={() => {
                    setCampStatus(campaign, CampaignStatusEnum.DRAFT)
                  }}
                >
                  <img
                    src={closeIcon}
                    alt={"close_icon"}
                    style={{ width: 16 }}
                  ></img>{" "}
                </PrimaryButton>
              </CustomToolTip>
            ) : campaignTabFilter == CampaignStatusEnum.DRAFT ? (
              <CustomToolTip title="Set Active">
                <PrimaryButton
                  className="action-btn mx-1"
                  onClick={() => {
                    setCampStatus(campaign, CampaignStatusEnum.ACTIVE)
                  }}
                >
                  <img
                    src={checkIcon}
                    alt={"close_icon"}
                    style={{ width: 16 }}
                  ></img>
                </PrimaryButton>
              </CustomToolTip>
            ) : (
              <></>
            )}
            <CustomToolTip title={t("copyLabel")}>
             
              <PrimaryButton
                className="action-btn mx-1"
                onClick={() => copyCamp(campaign.cid)}
              >
                <img
                  src={documentIcon}
                  alt={"document_icon"}
                  style={{ width: 16 }}
                ></img>
              </PrimaryButton>
            </CustomToolTip>
            {(campaignTabFilter == CampaignStatusEnum.DRAFT || campaignTabFilter == CampaignStatusEnum.PAST)   &&(
              <ConfirmPopUp
                title={t("deleteCamapignLabel")}
                onConfirm={deleteCamp}
                description={t("areYouSureMsg")}
                placement="left"
                id={campaign._id}
                icon={deleteIcon}
              >
                <CustomToolTip title={t("deleteLabel")}>
                <PrimaryButton className="action-btn mx-1">
                  <img
                    src={deleteIcon}
                    alt={"delete_icon"}
                    style={{ width: 18 }}
                  ></img>
                </PrimaryButton>
                </CustomToolTip>
              </ConfirmPopUp>
            )}
            </AccessControl> 
            <AccessControl accessControl={{role:[roleCheckTypes.Contributor]}}>
            <Link to={`/dashboard/campaign/${campaign.cid}`}>
              <CustomToolTip title="View Details">
                <PrimaryButton className="action-btn mx-1">
                  <img
                    src={eyeIcon}
                    alt={"eye_icon"}
                    style={{ width: 16 }}
                  ></img>
                </PrimaryButton>
              </CustomToolTip>
            </Link>
            </AccessControl>
            {campaignTabFilter == CampaignStatusEnum.DRAFT   &&(
             <Link to={`/dashboard/campaign/review/${campaign.cid}`}>
             <CustomToolTip title="Review">
               <PrimaryButton className="action-btn mx-1">
                 <img
                   src={reviewIcon}
                   alt={"review icon"}
                   style={{ width: 16 }}
                 ></img>
               </PrimaryButton>
             </CustomToolTip>
           </Link>
            )}
            <AccessControl accessControl={{role:[roleCheckTypes.Contributor]}}>
            <CustomToolTip title={t("editLabel")}>
              <PrimaryButton
                className="action-btn mx-1"
                onClick={() =>
                  navigate("create", {
                    state: { cid: campaign._id, type: campaign.t },
                  })
                }
              >
                <img src={editIcon} alt={"edit_icon"} style={imgStyle}></img>
              </PrimaryButton>
            </CustomToolTip>
            </AccessControl>
           
          </span>
        )
      },
    },
  ]
  const filteredColumns =
    campaignTabFilter !== CampaignStatusEnum.ACTIVE
      ? columns.filter(
          (column: any) =>
            !["Conversion", "CTR", "Clicked", "Sent","Impressionrate"].includes(column.dataIndex)
        )
      : columns

  //when the user click on any tab(Active / Draft / Past)
  const onTabClick: MenuProps["onClick"] = (e) => {
    setSelectedRowKeys([]);
    setCampaignTabFilter(e.key);
    setPageData(PAGE_OPTIONS.PAGINATION);
  }
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const handleDateRange = (dateRangeData: any) => {
    setDateRange(dateRangeData);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  return (
    <>
      <Helmet>
        <title>Appice | Campaign </title>
      </Helmet>

      <div className="mx-md-2">
        <div className="mx-1 pt-2">
          <CustomBreadCrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard/campaign"}>
                    {t("allCampaignLabel")}
                  </Link>
                ),
              },
            ]}
          />
        </div>
        <div className="d-flex  justify-content-between py-2 w-100">
          <div className="w-50">
            <InnerNav
              theme="light"
              style={{ border: "none" }}
              onTabClick={onTabClick}
              selected={campaignTabFilter}
              menu={[
                {
                  label: <span className="mx-2">{t("Active")}</span>,
                  key: "ACTIVE",
                },
                {
                  label: <span className="mx-2">{t("draftLabel")}</span>,
                  key: "DRAFT",
                },
                {
                  label: <span className="mx-2">{t("pastLabel")}</span>,
                  key: "PAST",
                },
              ]}
            />
          </div>
          <div className=" d-flex align-items-center justify-content-end gap-1 w-50">
            <strong>{t("periodLabel")} </strong>
            <div>
              <DateRangeSelector
              onDateChange={handleDateRange}
              disableRange={{ range: 1, rangeType: "year" }}
              disableType="years"

            />
            </div>
          </div>
        </div>

        <div className="px-sm-2 px-1 campaign-table-container">
          <div className="py-3 m-0 d-flex  flex-row w-100">
            <div className="w-100 d-flex h-100 flex-wrap">
              <Search
                placeholder="Search"
                onChange={(e: any) => setSearch(e.target.value)}
                size="middle"
                className="campaign-search"
              />
              <div className=" d-flex flex-wrap campaign-filter-container">
              <Selector
                placeholder="Select channel"
                onChange={(value) => setSelectedChannel(value)}
                style={{ width: 150 }}
                value={selectedChannel}
                showSearch
                options={options}
              />
                <Selector
                  onChange={(value) => {
                    setPageData({ ...pageData, pageSize: value })
                  }}

                  defaultValue={PAGE_OPTIONS.DEFAULT_VALUE}
                  size="middle"
                  options={PAGE_OPTIONS.DATA}
                />
              </div>
            </div>
            <AccessControl accessControl={{role:[roleCheckTypes.Contributor]}}>
            <div className="d-flex gap-1 ">
             
              <PrimaryButton
                type="primary"
                onClick={() => {
                  navigate("create")
                }}
              >
                <PlusOutlined />
                <span className="mx-2">{t("campaignLabel")}</span>
              </PrimaryButton>
            </div>
            <div className="d-flex gap-1 campaign-csv-download">
            <CustomToolTip title={t("downloadCsvLabel")}>

            <PrimaryButton onClick={downloadCampaignCsv}>
            <img src={downloadIcon}  alt="download icon"></img>
          </PrimaryButton>
          </CustomToolTip>
          </div>
           </AccessControl>
          </div>
          <CustomTable
            columns={filteredColumns}
            dataSource={campData?.map((campaign: any) => ({
              ...campaign,
              key: campaign._id,
            }))}
            size={TABLE_OPTIONS.SIZE}
            rowSelection={rowSelection}
            loading={loading}
            onChange={onPageChange}
            pagination={{
              current: pageData.current,
              pageSize: pageData.pageSize,
              simple: pageData.simple,
              total: totalCamp,
            }}
            scroll={{ x: 1800 }}
          />
        </div>
      </div>
    </>
  )
}
export default Campaign

