import { Divider, Form } from "antd";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import TextInput from "components/Inputs/TextInput/TextInput";
import Selector from "components/Selector/Selector";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import {useSelector } from "react-redux";
import centralApi from "services/centralApi";
import { API_ENDPOINTS } from "utils/constants";
import { ProjectionInfo, QueryPayload } from "utils/models";
interface PersonalizationProps {
  personalizationState: { open: boolean; field: string };
  setPersonalizationState: (value: { open: boolean; field: string }) => void;
  setFormData: (data: any) => void;
}

const Personalization: FC<PersonalizationProps> = ({
  personalizationState,
  setPersonalizationState,
  setFormData,
}) => {
  const [form] = useForm();
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const tableName=`app_users_${appId}`;
  const [loading,setLoading]=useState(false)
  const [metaData, setMetaData] = useState<any>({});
  const [data, setData] = useState<ProjectionInfo>({
    operand:"",
    type:"",
  });

  const fetchVariables = async () => {
    try {
      setLoading(true);
      const queryPayload = new QueryPayload(
            "",
            {
              tableName,
            },  
            [],   
            [],     
          );
      const params = {
        api_key: loginUser.api_key,
        app_id: appId,
        data:queryPayload.toString()
      };
      //call api
      const {columns} = await centralApi(
        "GET",
        API_ENDPOINTS.GET_TRAIT_TABLE_INFO_URL,
        null,
        params
      );
      setMetaData((prevState: any) => ({ ...prevState, columns: columns}));
    } catch (error) {
    }
    setLoading(false);
  };
  //handle form change 
  const handleFormChange = (data:ProjectionInfo) => {
    setData((prevState) => ({ ...prevState, ...data }));
  };
  //handle form submit
  const onSubmit = () => {
    if (personalizationState?.field && data.operand) {
      setFormData((prevState: any) => {
        const existingProjectionInfo = prevState.variableInfo?.projectionInfo || [];
    
        // Check if an object with the same operand exists
        const existingIndex = existingProjectionInfo.findIndex(
          ({operand}:{operand:string}) => operand === data.operand
        );
    
        let updatedProjectionInfo;
    
        if (existingIndex !== -1) {
          // Replace the existing object at the same index
          updatedProjectionInfo = [...existingProjectionInfo];
          updatedProjectionInfo[existingIndex] = data;
        } else {
          // Add the new object to the array
          updatedProjectionInfo = [...existingProjectionInfo, data];
        }
    
        return {
          ...prevState,
          variableInfo: {
            ...prevState.variableInfo,
            tableInfo: { tableName},
            filterInfo: [],
            projectionInfo: updatedProjectionInfo,
          },
          [personalizationState.field]:
            prevState[personalizationState.field] + `{{${data.name?.toString()}}}`,
        };
      });
    }
    setPersonalizationState({ open: false, field: "" });
  };
  useEffect(() => {
    if(personalizationState?.field){
      fetchVariables()
    }
    form.resetFields();
    setData({
      operand:"",
      type:""
    });
  }, [personalizationState]);
  return (
    <CustomDrawer
      footer={
        <div>
          <PrimaryButton type="primary" loading={loading} disabled={loading} onClick={() => form.submit()}>
            {t("saveLabel")}
          </PrimaryButton>
        </div>
      }
      open={personalizationState.open}
      placement="right"
      closable={false}
      onClose={() => setPersonalizationState({ open: false, field: "" })}
    >
      <div className=" mt-1 ">
        <h5 className="fw-semibold">{t("addVariableLabel")}</h5>
        <div className="mt-3">
          <Form
            colon={false}
            labelAlign="left"
            labelCol={{ flex: "110px" }}
            layout="horizontal"
            form={form}
            onFinish={onSubmit}
          >
            <Divider />
              <FormItem className="" label={t("selectVariableMsg")} name={"variableLabel"}  rules={[{ required: true, message: `${t("selectVariableMsg")}` }]}>
                <Selector
                  loading={loading}
                  disabled={loading}
                  onChange={(variable: string) => {
                    const {isIdentity,isVisible,value,label,jsonb,...variableInfo}=metaData?.columns?.find(({value}:{value:string})=>value==variable)
                    handleFormChange({...variableInfo,operand:value,jsonb,name:value?.replace("->",""),displayName:label,modifierInfo:jsonb=="traits"?{limit:1}:undefined});
                  }}
                  showSearch
                  options={metaData?.columns}
                />
              </FormItem>
            <FormItem label={t("defaultValueLabel")} name="defaultValue"
            rules={[{ required: true, message: `${t("plzInputDefaultValueMsg")}` }]}
            >
              <TextInput
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                  handleFormChange({...data,defaultValue:e.target.value});
                }}
                placeholder={t("defaultValueLabel")}
              />
            </FormItem>
            <FormItem label={t("isSensitiveLabel")}
            >
              <CustomCheckbox
              checked={data.isSensitive || false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleFormChange({...data,isSensitive: e.target.checked});
                }}
              />
            </FormItem>
          </Form>
        </div>
      </div>
    </CustomDrawer>
  );
};

export default Personalization;
//replace placeholders with placholder label
export const replacePersonlizedVariable = (
  text: string,
  variables:  QueryPayload | undefined
) => {
  const string = text?.replace(/{{(.*?)}}/g, (match: any, key: any) => {
   const variableInfo= variables?.projectionInfo?.find(({name})=>key==name)
    if (variableInfo?.name) {
      // If isSensitive is true, mask the value with ***
      const valueToReturn = variableInfo.isSensitive ? "***" : variableInfo.defaultValue || "";
      
      return ` {{${variableInfo.displayName}:${valueToReturn}}}`;
    } else {
      // Return the original match if no variableInfo is found
      return match;
    }
  });

  return string || "";
};
