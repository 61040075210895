import { Divider } from "antd";
import "./FirebaseCloudMessaging.css";
import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form, Upload } from "antd";
import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../../../services/centralApi";
import ShowMessage from "../../../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../../../components/Spinner/Spinner";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import React from 'react';
import { FirebaseCloudMessagingProps, FormValues, ShowMessageType, UpdateGCMKeysPayload } from "./interfaces";
import { FcmDeliveryMethod, PushNotificationMode } from "utils/enums";
import { API_ENDPOINTS } from "utils/constants";
import { BOOLEAN_OPTIONS, MODE_OPTIONS, STRING_BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import { AppSettings } from "utils/models";
import { deserialize ,serialize} from "utils/models/serializer";
import { t } from "i18next";
import Selector from "components/Selector/Selector";
import { getToken } from "redux/features/app/authTokenSlice";



const FirebaseCloudMessaging: React.FC<FirebaseCloudMessagingProps> = ({
  currentAppKey,
  Mode,
  fetchCurrentAppKey,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch: any = useDispatch();
  const mode = Mode === PushNotificationMode.DEVELOPMENT ? MODE_OPTIONS.DEV : Mode === PushNotificationMode.PRODUCTION ? MODE_OPTIONS.PROD : "";
  const [proxy ,setProxy] = useState<string>(STRING_BOOLEAN_OPTIONS.FALSE);
  const [batching , setBatching] = useState<string>(STRING_BOOLEAN_OPTIONS.FALSE);
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);
  const [form] = Form.useForm();

  const [showMessage, setShowMessage] = useState<ShowMessageType>({
    type: "success",
    message: "",
    show: BOOLEAN_OPTIONS.FALSE,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const isdev = Mode === PushNotificationMode.DEVELOPMENT ? STRING_BOOLEAN_OPTIONS.TRUE : Mode === PushNotificationMode.PRODUCTION? STRING_BOOLEAN_OPTIONS.FALSE: "";



   /**
   * 
   * @param e will be an event and e.target.value will be a number string
   * @param fieldName will be the key of and object where we are setting the values
   * @returns key with number value
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const inputValue = e.target.value;
    const numberValue = Number(inputValue);
  
    // Validate number or use default values
    const isValid = !isNaN(numberValue) && inputValue.trim() !== "";
  
    // Update form field with the correct number value
    if (isValid) {
      form.setFieldsValue({
        [fieldName]: numberValue,
      });
    }
    else{
      return undefined
    }
  };



  /**
   * it will save the latest fcm key if we select delivey method server key legacy otherwise it will save the one which is already present in db
   * @param values takes formValues whose interface is defined
   */
  const onFormSubmit = async(values: FormValues) => {
        // Serializing and deserializing APNS Payload and appsettings model class
        const deserializedData = deserialize(values, AppSettings);
        const serializedData = serialize(deserializedData as AppSettings);
    

      const payload: UpdateGCMKeysPayload = {
        args: JSON.stringify({
          app_id: appId,
          ...serializedData,
          mode,
        }),
        authToken,
        app_id: appId,
        api_key: api_key,
      };
    await updateGCMKeys(payload);
    dispatch(getToken())

  };

  const handleFileChange = async (formData: any) => {
    try {
      const form_data = new FormData();
      formData.authToken = authToken
      if (formData.csv) {
        form_data.append("app_cert", formData.csv);
        form_data.append("app_cert_name", formData.csv.name);
        form_data.append("app_cert_id", appId);
        form_data.append("isdev", isdev);
        form_data.append("platform", "android");
      } else {
        throw new Error("CSV file is missing.");
      }

      await centralApi("POST", API_ENDPOINTS.UPLOAD_CERTIFICATE, form_data, null , false , authToken);
      const newToken = await dispatch(getToken()).unwrap();
      await centralApi("POST", API_ENDPOINTS.CERTIFICATE, form_data, null, false , newToken);
      dispatch(getToken())
      setShowMessage({
        type: "success",
        message: "Successfully Uploaded",
        show: true,
      });

      const fileName = formData.csv.name;
      form.setFieldsValue({ JsonCert: fileName });
    } catch (error) {
      setShowMessage({
        type: "error",
        message: `Something went wrong`,
        show: true,
      });
    }
  };

  const updateGCMKeys = async (formData: UpdateGCMKeysPayload) => {
    const url: string = API_ENDPOINTS.UPDATE_GCM_KEYS;

    try {
      setLoading(true);
     await centralApi("POST", url, formData, null);
      setShowMessage({
        type: "success",
        message: "Successfully Saved",
        show: true,
      });
      setLoading(false);
      fetchCurrentAppKey();
    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: "Something went wrong",
        show: true,
      });
    }
    hideMessage();
  };

  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };
  useEffect(() => {
    const updatedFormValues = {
      JsonCert: Mode === PushNotificationMode.DEVELOPMENT ? currentAppKey[0]?.dev_android_cert3 : Mode === PushNotificationMode.PRODUCTION ? currentAppKey[0]?.android_cert3 : "",
      http_proxy:  Mode === PushNotificationMode.DEVELOPMENT ? currentAppKey[0]?.dev_http_proxy: Mode === PushNotificationMode.PRODUCTION ?currentAppKey[0]?.http_proxy:"",
      //sets fcm_batchSize from currentkey[0] as per the mode and checks if the key is not undefined aor null otherwise we set up the key as empty string
      fcm_batchSize :Mode  === PushNotificationMode.DEVELOPMENT 

        ? (currentAppKey[0]?.dev_fcm_batchSize !== null && currentAppKey[0]?.dev_fcm_batchSize !== undefined 
           ? currentAppKey[0]?.dev_fcm_batchSize 
            : "") 
        : (currentAppKey[0]?.fcm_batchSize !== null && currentAppKey[0]?.fcm_batchSize !== undefined 
           ? currentAppKey[0]?.fcm_batchSize 
            : ""),
      //sets fcm_chunkSize from currentkey[0] as per the mode and checks if the key is not undefined aor null otherwise we set up the key as empty string   
      fcm_chunkSize : Mode === PushNotificationMode.DEVELOPMENT 
        ? (currentAppKey[0]?.dev_fcm_chunkSize !== null && currentAppKey[0]?.dev_fcm_chunkSize !== undefined 
            ? currentAppKey[0]?.dev_fcm_chunkSize 
             : "") 
        : (currentAppKey[0]?.fcm_chunkSize !== null && currentAppKey[0]?.fcm_chunkSize !== undefined 
            ? currentAppKey[0]?.fcm_chunkSize 
             : ""),
      //sets fcm_delay from currentkey[0] as per the mode and checks if the key is not undefined aor null otherwise we set up the key as empty string   
      fcm_delay : Mode === PushNotificationMode.DEVELOPMENT 
        ? (currentAppKey[0]?.dev_fcm_delay !== null && currentAppKey[0]?.dev_fcm_delay !== undefined 
           ? currentAppKey[0]?.dev_fcm_delay 
            : "") 
        : (currentAppKey[0]?.fcm_delay !== null && currentAppKey[0]?.fcm_delay !== undefined 
           ? currentAppKey[0]?.fcm_delay 
            : "")
    };
    
     //checks if data is in http_proxy we set the enableProxy selector to true
    if(updatedFormValues.http_proxy){
      setProxy(STRING_BOOLEAN_OPTIONS.TRUE)
    }

    //checks if data is in  apns_batchSize  we set the enableBatching selector to true
    if(updatedFormValues.fcm_batchSize){
      setBatching(STRING_BOOLEAN_OPTIONS.TRUE)
    }
    form.setFieldsValue(updatedFormValues);
  }, [mode, currentAppKey, form]);

 

  return (
    <Fragment>
      <div className="firebase-cloud-messaging-container">
        <div className="mt-4 mb-4">
          <h6 className="firebase-cloud-messaging-container-heading">
          Firebase Cloud Messaging (FCM) -- {Mode}
          </h6>
          <Divider className="firebase-cloud-messaging-custom-divider" />
        </div>

        <div className="col-sm-9 mt-4 mb-4 align-items-center firebase-cloud-messaging-form">
          {showMessage.show && (
            <ShowMessage
              type={showMessage.type}
              content={showMessage.message}
            />
          )}
          {loading ? (
            <Spinner />
          ) : (
            <Form
              form={form}
              layout="horizontal"
              onFinish={onFormSubmit}
            >
              
                <Form.Item
                  label={FcmDeliveryMethod.JSON}
                  name={"JsonCert"}
                  rules={[
                    {
                      required: true,
                      message: "Upload Your JSON File",
                    },
                  ]}
                >
                  <TextInput
                    placeholder="Upload Your JSON file"
                    disabled={true}
                    addonAfter={
                      <div>
                        <Upload
                          showUploadList={false}
                          accept=".json"
                          multiple={false}
                          onChange={(value) =>
                            handleFileChange({ csv: value.file })
                          }
                          beforeUpload={() => false}
                        >
                          <PrimaryButton type="primary">
                            Upload
                          </PrimaryButton>
                        </Upload>
                      </div>
                    }
                  />
                </Form.Item>
            
               <Form.Item
                     label={t("enableProxyLabel")}
                  >
                    <Selector
                     value={proxy}
                     onChange={(value: string) =>
                       setProxy(value)
                    }

                      options={[
                        {
                          label: t("TrueLabel"),
                          value: STRING_BOOLEAN_OPTIONS.TRUE,
                        },
                        {
                          label: t("FalseLabel"),
                          value: STRING_BOOLEAN_OPTIONS.FALSE,
                        },
                      ]}
                     
                    />
                  </Form.Item>
        {/* Conditional redering  for http proxy*/}
         {proxy === STRING_BOOLEAN_OPTIONS.TRUE&& (
            <>
              <Form.Item
                label="Proxy (optional)"
                name={"http_proxy"}
              >
                <TextInput placeholder="Http Proxy" />
              </Form.Item>
            </>
          )}
             <Form.Item
                     label={t("enableBatchingLabel")}
                  >
                    <Selector
                     value={batching}
                     onChange={(value: string) =>
                       setBatching(value)
                    }

                      options={[
                        {
                          label: t("TrueLabel"),
                          value: STRING_BOOLEAN_OPTIONS.TRUE,
                        },
                        {
                          label: t("FalseLabel"),
                          value: STRING_BOOLEAN_OPTIONS.FALSE,
                        },
                      ]}
                     
                    />
              </Form.Item>
         {/* Conditional rendering for batch size , chunk size and delay */}
         {batching === STRING_BOOLEAN_OPTIONS.TRUE && (
           <>
              <Form.Item
                    label={t("batchSize")}
                    name={"fcm_batchSize"}
                  >
                    <TextInput
                      onChange={(e : any) => handleChange(e, 'fcm_batchSize')}
                      type="number" 
                    />
               </Form.Item>
               <Form.Item
                    label={t("chunkSize")}
                    name={"fcm_chunkSize"}
                  >
                    <TextInput
                      onChange={(e : any) => handleChange(e, 'fcm_chunkSize')}
                      type="number" 
                    />
               </Form.Item>
               <Form.Item
                    label={t("delay")}
                    name={"fcm_delay"}
                  >
                    <TextInput
                      onChange={(e : any) => handleChange(e, 'fcm_delay')}
                      type="number" 
                    />
               </Form.Item>
              </>
          )}
              <div className="">
                <PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  style={{ border: "none", width: "80px" }}
                >
                  Save
                </PrimaryButton>
              </div>
            </Form>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default FirebaseCloudMessaging;
