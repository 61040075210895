import Selector from "components/Selector/Selector";
import { Form, FormInstance, Tag } from "antd";
import { t } from "i18next";
import "./TraitCondition.css";
import { validateNotEmpty } from "validator/antdValidator";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilterCondition,
  setFilterInfoData,
} from "redux/features/audience/computedTraitSlice";
import { FilterInfo, RowData } from "utils/models";
import TraitConditionNested from "../TraitConditionNested/TraitConditionNested";
type Props = {
  form: FormInstance;
  condition: FilterInfo;
  index: number;
  metaData: any;
  fetchTableInfo: (
    payload?:RowData
  ) => Promise<any>;
};
/**
 * TraitCondition component is responsible for rendering and managing filter conditions
 * used in  traits. It renders the main condition and any nested conditions
 * using `TraitConditionNested` and handles updates to the filter information.
 * 
 * Props:
 * - form: Ant Design Form instance to manage form data.
 * - condition: Current filter condition object (FilterInfo).
 * - index: Index of the current condition in the filter list.
 * - metaData: Metadata for dropdowns and selectors (e.g., available functions).
 * - fetchTableInfo: Function to fetch table information based on selected operand and jsonb.
 */
function TraitCondition({
  form,
  index,
  condition,
  metaData,
  fetchTableInfo,
}: Props) {
  const dispatch: any = useDispatch();
  //get trait form redux store
  const trait = useSelector((state: any) => state.computedTrait.data);
  /**
   * handleFormChange updates the condition in the Redux store with new data.
   * 
   * @param data - The updated filter information (FilterInfo).
   */
  const handleFormChange = (data: FilterInfo) => {
   dispatch(setFilterInfoData({data:{...condition,...data},index,parentIndex:-1}))
  };
  return (
    <>
      <div className="ctrait-condition-container p-2  d-flex flex-column gap-2">
        <div className="d-flex   ps-1 align-items-center">
         {!condition?.group?.length && <span className="ctrait-w100px">
            <Tag
              className="cursor-pointer"
              onClick={() =>
                handleFormChange({
                  compute: !condition.compute,
                  func: undefined,
                })
              }
              color={condition?.compute ? `var(--color-success)` : undefined}
            >
              {(condition?.compute ? "- " : "+ ") + `${t("computeLabel")}`}
            </Tag>
          </span>}
          {condition?.compute && (
            <Form.Item
              className="m-0"
              labelCol={{ flex: "100px" }}
              rules={[
                {
                  validator: validateNotEmpty,
                  message: `${t("selectFunctionLabel")}`,
                },
              ]}
              name={`func[${index}]`}
              initialValue={condition.func || undefined}
              label={t("functionLabel")}
            >
              <Selector
                onChange={(value) => {
                  handleFormChange({ func: value });
                }}
                className="input-size-lg"
                options={metaData?.functions}
              />
            </Form.Item>
          )}
        </div>
        <TraitConditionNested
          condition={condition}
          parentIndex={-1}
          index={index}
          form={form}
          fetchTableInfo={fetchTableInfo}
          metaData={metaData}
        />
        {trait.filterInfo[index]?.group?.map(
          (groupCondition: FilterInfo, childIndex: number) => {
            return (
              <TraitConditionNested
                condition={groupCondition}
                key={childIndex}
                group={trait.filterInfo[index]?.group}
                parentIndex={index}
                index={childIndex}
                form={form}
                fetchTableInfo={fetchTableInfo}
                metaData={metaData}
              />
            );
          }
        )}
      </div>
      <div className="ps-2">
       {!condition.compute && <Tag
          className="ms-1 cursor-pointer"
          onClick={() => {
            dispatch(addFilterCondition({parentIndex:index}))
          }}
        >
          {t("plusWhereLabel")}
        </Tag>}
      </div>
    </>
  );
}

export default TraitCondition;
