import { Modal, Space } from "antd";
import {  useState } from "react";
import Selector from "../../Selector/Selector";
import { useTranslation } from "react-i18next";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";


const AddAtributeModel = ({
  isVisible,
  onOk,
  onCancel,
  selectedAttributes,
  handleAttributeSelect,
}: any) => {
  const { t } = useTranslation();
const selectLabel=t("selectLabel")
const [selectedAttributeCsv, setSelectedAttributeCsv] = useState([]);


const handleOkClick = () => {
  handleAttributeSelect(selectedAttributeCsv);
  onOk();
};
 

  return (
    
    <CustomDrawer
    title="Select Attributes"

        open={isVisible}
        width={400}
        placement="right"
        onClose={() => 
          onCancel()}
        className="p-0  "
        closable={false}
        footer={
          <Space className="d-flex justify-content-end">
            <PrimaryButton
              onClick={() => onCancel()
              }
            >
              {t("cancelLabel")}
            </PrimaryButton>
            <PrimaryButton
              type="primary"
              onClick={() => handleOkClick()
              
}
            >
              {t("saveLabel")}
            </PrimaryButton>
          </Space>
        }
        height={"90%"}
      >
    
      <Selector
      placeholder={selectLabel}
      onChange={(value) => setSelectedAttributeCsv(value)} 
      style={{ width: "100%" }}
        size="large"
        mode="multiple"
        options={selectedAttributes.map((item:any) => ({
          value: item?.name,
          label: item?.name,
        }))}
      />
      </CustomDrawer>
  );
};

export default AddAtributeModel;

