import { FC, useState, useEffect } from "react";
import { Col, Form, Row, Space } from "antd";
import FormItem from "antd/es/form/FormItem";
import { t } from "i18next";
import Selector from "components/Selector/Selector";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import CampaignDevice from "components/CampaignDevice/CampaignDevice";
import TextInput from "components/Inputs/TextInput/TextInput";
import "./InAppNotification.css";
import CustomTextArea from "components/Inputs/CustomTextArea/CustomTextArea";
import { MoreOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "services/centralApi";
import TemplateLayout from "../../../layout/TemplateLayout/TemplateLayout";
import { setCampaignData } from "redux/features/campaign/campaignDataSlice";
import { setCurrentStep } from "redux/features/campaign/campaignCreateCurrentStepSlice";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import { useForm } from "antd/es/form/Form";
import fullImageOn from "images/fullscreen_on.png";
import miniOn from "images/mini_on.png";
import headerOn from "images/header_on.png";
import footerOn from "images/footer_on.png";
import fullImageOff from "images/fullscreen.png";
import miniOff from "images/mini.png";
import headerOff from "images/header.png";
import footerOff from "images/footer.png";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import SegmentedCustom from "components/SegmentedCustom/SegmentedCustom";
import { validateImageDimensions } from "helperFunctions/file";
import { v4 as uuid } from "uuid";
import ShowMessage from "components/ShowMessage/ShowMessage";
import useMessage from "hooks/useMessage";
import InAppContainer from "../../NotificationContainer/InAppContainer/InAppContainer";
import { getInAppBazelInsetValues } from "helperFunctions/common";
import { fetchTemplatesList } from "redux/features/templates_/templatesByTypeSlice";
import {
  fetchTemplateById,
  setSelectedTemplate,
} from "redux/features/templates_/templateByIdSlice";
import Personalization from "../../Personalization/Personalization";
import { validateName } from "services/validateName";
import { CustomData } from "../../CustomData/CustomData";
import selfRenderedOn from "images/Self_Handled_Active_1.svg";
import selfRenderedOff from "images/Self_Handled_1.svg";

import { formatDate, getCurrentDate } from "helperFunctions/date";
import {
  CampaignChannelTypeEnum,
  CustomDataEnum,
  InAppImageUploadType,
  InAppImageValidationType,
  Drawer,
  UploadType,
  ScreenTypes,
  DataTypeEnum
} from "utils/enums";
import { API_ENDPOINTS } from "utils/constants";
import { getToken } from "redux/features/app/authTokenSlice";
import CreateVariant from "../../CreateVariant/CreateVariant";

interface InAppNotificationProps {}

const InAppNotification: FC<InAppNotificationProps> = () => {
  const dispatch: any = useDispatch();
  const [form] = useForm();
  const { DEVICE, IMAGE } = CustomDataEnum;

  const { showError } = useMessage();

  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);
  const currentStep = useSelector(
    (state: any) => state.campaignCreateCurrentStep
  );
  const templates = useSelector((state: any) => state.templatesList.data);
  const selectedTemplate = useSelector((state: any) => state.templateById.data);
  const [personalization, setPersonalization] = useState({
    open: false,
    field: "",
  });
  const [variants, setVariants] = useState<any>([]);
  const [selectedVariant, setSelectedVariant] = useState<any>(null);
  const campaignData = useSelector((state: any) => state.campaignData.data);
  const [showDrawer, setShowDrawer] = useState({ value: false, type: "" });
  const [customDataState, setCustomDataState] = useState<any>({});
  const [image, setImage] = useState({
    miniScreen: { loading: false },
    notificationImage: { loading: false },
    iconImage: { loading: false },
    footerNotificatonImage: { loading: false },
  });
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const [template, setTemplate] = useState({
    template_name: "",
    template_type: CampaignChannelTypeEnum.IN_APP,
    isTemplateDeleted: "false",
  });
  const [uploadType, setUploadType] = useState<UploadType>(UploadType.NORMAL);


  const [oldTemplateName, setOldTemplateName] = useState(""); //old template name store to avoid validation if remplate name does not change
  const [formData, setFormData] = useState<any>({
    view_mode: ScreenTypes.FULL_SCREEN,
    notificationImage: "", //image url for fullscreen image
    iconImage: "", //image url for header footer
    notificationType: "dl", //type of notification
    actionTitle: "Close", //action title to be close by default
    showAction: false,
    headerTextClr: "", //header text color
    descriptionTextClr: "", //description text color
    submitBtnTextClr: "", //submit utton text color
    backgroundColor: "", // Background color of the notification (e.g., in hex format)

    cdata: [],
    actionUrl: "", // URL for action, could be internal or external depending on the notification
    notificationDescription: "", // Main description text for the notification
    externalUrl: "", // External URL to be used if the notification points to an external site
    expandedNotificationDescription: "",
    variableInfo: undefined,
    el: false, // Flag for indicating if  'expanded' true or false
    mediaType:""


  });


  const [blobContainerCDN, setBlobContainerCDN] = useState(""); //cdn url

  useEffect(() => {
    // Dispatch an action to fetch the list of templates using the provided app_id, api_key, and template_type.
    dispatch(
      fetchTemplatesList({
        app_id: appId,
        api_key: loginUser.api_key,
        template_type: CampaignChannelTypeEnum.IN_APP, // Fetches only "IN_APP" templates
      })
    );

    // Retrieve a token using the appId and api_key.
    dispatch( getToken());

   
    if (campaignData.data) {
      dispatch(setSelectedTemplate(campaignData.data));
    }

    // Empty dependency array ensures this effect runs only once after the initial render.
  }, []);

  const handleCustomClick = (type: any) => {
    // Check if the user is logged in (has an API key) and the appId exists
    if (loginUser.api_key && appId) {
      // Update the state for showing a drawer, setting the type and value properties
      setShowDrawer(() => ({ type, value: true }));
    }
  };

  const andIntial = {
    b: "",
    i: [],
    d: 0,
    l: 0,
    g: 0,
    f: "",
    m: "",
  };
  //upload image and validate dimensions
  const handleImageUpload = async (
    value: any,
    type: InAppImageUploadType
   ) => {
    try {
      //validate image dimensions
      const { valid, message } = await validateImageDimensions(
     
          // Determine the validation type based on the type of image being uploaded
    // If the type is NOTIFICATION_IMAGE, use FULL_SCREEN validation
    type == InAppImageUploadType.NOTIFICATION_IMAGE
    ? InAppImageValidationType.FULL_SCREEN

// If the type is MINI_SCREEN, use MINI_SCREEN validation
: type == InAppImageUploadType.MINI_SCREEN
    ? InAppImageValidationType.MINI_SCREEN

// If the type is IN_APP_EXPANDED, use EXPANDED validation
: type == InAppImageUploadType.IN_APP_EXPANDED
    ? InAppImageValidationType.EXPANDED

// If the type is ICON_IMAGE, use SMALL validation
: type == InAppImageUploadType.ICON_IMAGE
    ? InAppImageValidationType.SMALL

// Default case: if no matching type, use SMALL validation as the fallback
: InAppImageValidationType.SMALL,

// Pass the file from the upload value to the validateImageDimensions function
value.file
);

      if (valid && appId) {
        //upload image
            // Set the loading state for the image upload

        setImage((prevState: any) => ({
          ...prevState,
         
          // Determine which image type to update: 
      // If the type is MINI_SCREEN or IN_APP_EXPANDED, set loading for ICON_IMAGE
      // Otherwise, set loading for the current type
    
          [type === InAppImageUploadType.MINI_SCREEN ||
          type === InAppImageUploadType.IN_APP_EXPANDED
            ? InAppImageUploadType.ICON_IMAGE
            : type]: {
            loading: true,
          },
        }));
        const formData = new FormData();
        formData.append("image", value.file); // Append the image file
        formData.append("image_id", value.file.name); // Append the image file name as the image ID
        formData.append("appid", appId); // Append the app ID
    
        // Send a POST request to upload the image using the central API
      
        const res = await centralApi(
          "POST",
          API_ENDPOINTS.SAVE_CAMPAIGN_IMAGES, // API endpoint to save the image
          formData, // The form data containing the image and related info
          null,
          false,
          authToken // No additional headers are passed
          );
          if (type === InAppImageUploadType.IN_APP_EXPANDED) {

          setUploadType(UploadType.EXPANDED);
        } else {
          setUploadType(UploadType.NORMAL);
        }
        setTimeout(() => {
          setBlobContainerCDN(res.baseURL);
          setFormData((prevState: any) => ({
            ...prevState, // Spread the previous formData state to retain existing values
            // Depending on the image upload type, update either the ICON_IMAGE or the specific type
            [type === InAppImageUploadType.MINI_SCREEN || 
             type === InAppImageUploadType.IN_APP_EXPANDED
              ? InAppImageUploadType.ICON_IMAGE // Use ICON_IMAGE for MINI_SCREEN or IN_APP_EXPANDED
              : type]: "/" + res.url, // Otherwise, use the type itself and set the URL from the response
          
          
          }));
       
             setFormData((prevState: any) => ({
              ...prevState,
              mediaType: res.type === "mp4" ? "video" : "",  // If 'mp4', set 'video', else set empty string
            }));
            
          if (res.type == "gif" && type == InAppImageUploadType.NOTIFICATION_IMAGE) {
            setFormData((prevState: any) => ({
              ...prevState,
              and: {
                ...prevState?.and,
                i: res.imgs,
                b: `${res.baseURL}`,
                m: `${res.baseURL + res.url}`,
                f: res.fe,
              },
            }));
          } else if (type !== InAppImageUploadType.MINI_SCREEN && res.type !== "gif") {
            setFormData((prevState: any) => ({
              ...prevState,
              and: andIntial,
            }));
          }
          setImage((prevState: any) => ({
            ...prevState, // Spread the previous image state to retain existing values
          
            // Depending on the image upload type, update either the ICON_IMAGE or the specific type
            [type === InAppImageUploadType.MINI_SCREEN || 
             type === InAppImageUploadType.IN_APP_EXPANDED
              ? InAppImageUploadType.ICON_IMAGE // Use ICON_IMAGE for MINI_SCREEN or IN_APP_EXPANDED
              : type]: {
              loading: false, // Set the loading state to false once the image upload is complete
            },
          }));
          }, 600);
      } else {
        showError(message);
      }
    } catch (error) {
      showError("Invalid name or format"); // Show an error message to the user
    
      // Update the image state to reflect that the loading has stopped due to an error
      setImage((prevState: any) => ({
        ...prevState, // Preserve existing state values
    
        // Depending on the image upload type, update either the ICON_IMAGE or the specific type
        [type === InAppImageUploadType.MINI_SCREEN || 
         type === InAppImageUploadType.IN_APP_EXPANDED
          ? InAppImageUploadType.ICON_IMAGE // Use ICON_IMAGE for MINI_SCREEN or IN_APP_EXPANDED
          : type]: {
          loading: false, // Set the loading state to false since the upload has failed
        },
      }));
    }
     setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2500);
  };
  
  useEffect(() => {
    //destructuring values frm template or redux or formData
    const { template, template_name, template_type, blobContainerCDN } =
      selectedTemplate || campaignData.data || formData;
      //set blob conatiner
    if (blobContainerCDN) {
      setBlobContainerCDN(blobContainerCDN);
    }
    if (template || selectedVariant) {
      const {
        view_mode,
        notificationImage,
        iconImage,
        notificationType,
        actionTitle,
        showAction,
        headerTextClr,
        descriptionTextClr,
        submitBtnTextClr,
        backgroundColor,
        actionUrl,
        cdata,
        notificationDescription,
        externalUrl,
        variableInfo,

        expandedNotificationDescription,
        el,
        mediaType,
      } = 
      Array.isArray(template) || selectedVariant
      ? selectedVariant || template[0]
      : template || formData;
//set data in form data to display in screen
      setFormData((prevState: any) => ({
        ...prevState,
        view_mode,
        notificationImage,
        iconImage,
        notificationType,
        actionTitle,
        showAction,
        headerTextClr,
        descriptionTextClr,
        submitBtnTextClr,
        backgroundColor,
        cdata,
        actionUrl,
        notificationDescription,
        externalUrl,
        expandedNotificationDescription,
        variableInfo,
        el,
        mediaType
      }));
      //set template name and type
      if (template_name) {
        setTemplate((prevState) => ({
          ...prevState,
          template_name,
          template_type,
        }));
      }
      //if expanded image is true
      if (el) {
        setUploadType(UploadType.EXPANDED);
      }
      if (cdata) {
        const custom_data: any = {};
        if (Array.isArray(cdata)) {
          // Handle array of objects
          cdata.forEach((obj: any) => {
            const [key, value] = Object.entries(obj)[0]; // Destructuring to get the first key-value pair
            custom_data[uuid()] = {
              key: key, // Key obtained from destructuring
              value: value, // Value obtained from destructuring
              type: obj?.type || "", // Assuming obj has a property called 'type'
            };
          });
        } else if (typeof cdata === DataTypeEnum.OBJECT  && cdata !== null) {
          // Handle plain object
          Object.keys(cdata).forEach((key: string) => {
            custom_data[uuid()] = { key, value: cdata[key] };
          });
        }
        setCustomDataState(custom_data);
        // setCustomDataState(cdata);
      }
    }
    setOldTemplateName(template_name); // save template name
  }, [campaignData, selectedTemplate,selectedVariant]);
 
  useEffect(() => {
    if (selectedTemplate) {
      dispatch(
        setCampaignData({
          ...campaignData, // Spread the existing campaignData
          data: selectedTemplate, // Update the data field with selectedTemplate
          tid: selectedTemplate._id, // Set the tid field to the id of the selectedTemplate
          t: selectedTemplate.template_type, // Set the t field to the template_type of the selectedTemplate
        })
      );
      if (Array.isArray(selectedTemplate?.template)) {
        setVariants(selectedTemplate.template);
        setSelectedVariant(selectedTemplate.template[0]);
      } else {
        setVariants([]);
        setSelectedVariant(null);
      }
    }
  }, [selectedTemplate]); // Dependency array to re-run the effect when selectedTemplate changes
 
   const saveCdata = () => {
    const cdata: any = []; // Initialize an empty array to hold the collected data
  
    Object.values(customDataState).forEach((value: any) => {
      // Iterate over each value in customDataState
      if (value.value && value.key) { // Check if both value and key are present
        const dataObject = {
          [value.key]: value.value, // Create an object with the key as the property name
          type: value.type === DEVICE ? IMAGE : value?.type, // Set type based on a condition
        };
        cdata.push(dataObject); // Push the constructed object into the cdataa array
      }
    });
  
    setFormData((prevState: any) => ({ ...prevState, cdata })); // Update formData with the new cdata array
  };
  
  const handleSaveOrUpdate = async () => {

    try {
      // Perform name validation
      let isValidTemplateName;
      // Call validateName only when template name is changed
      if (template.template_name !== oldTemplateName) {
        try {
          //validate template name
          isValidTemplateName = await validateName(
            template.template_name,
            CampaignChannelTypeEnum.IN_APP,
            loginUser.api_key,
            appId
          );
          // Proceed with form validation and submission
        } catch (error) {
          showError(t("somethingWrongLabel"));
        }
      }

      // Validate the template name  fields
      if (isValidTemplateName) {
        form.setFields([
          {
            name: "templateName",
            errors: [t("templateNameExistsErrorMessage")],
          },
        ]);
      } else {
         // Validation for formData or variants
      if (variants.length === 0 && formData) {
        // If formData exists, check for notificationImage or iconImage
        if (
          !formData.notificationImage &&
          !formData.iconImage
        ) {
          showError(t("imageValidationError")); // Show error if neither image exists
          return; // Stop further execution
        }
      } else if (variants.length > 0) {
        // If variants are present, loop through each variant
        for (const variant of variants) {
          if (
            !variant.notificationImage &&
            !variant.iconImage
          ) {
            showError(t("imageValidationError")); // Show error if neither image exists in a variant
            return; // Stop further execution
          }
        }
      }
        //check other fields validation
        await form.validateFields();
        // Form is valid, proceed with saving or updating
        const payload = {
          api_key: loginUser.api_key,
          app_id: appId,
          authToken,
          template_id: selectedTemplate ? selectedTemplate._id : undefined, //template id if template is selected otherwise undefined
          args:JSON.stringify({
            ...template,
            template: variants.length > 0 ? variants :formData,
            createdOn: selectedTemplate
              ? undefined
              : formatDate(getCurrentDate(), "MM/DD/YYYY"), // if new template then add created on
            modifiedOn: selectedTemplate //if existing template set modified on
              ? formatDate(getCurrentDate(), "MM/DD/YYYY")
              : undefined,
          
          })
        };

        // Determine the endpoint and method based on the presence of a selected template
        const endpoint = selectedTemplate
          ? API_ENDPOINTS.UPDATE_CAMPAIGN_TEMPLATE
          : API_ENDPOINTS.CREATE_CAMPAIGN_TEMPLATE;
        //call the Api with the selected endpoint
        const response = await centralApi("POST", endpoint, payload);
        // response from API setting its value in redux
        dispatch(
          setCampaignData({
            ...campaignData,
            data: { ...response, blobContainerCDN },

            tid: response._id,
            t: response.template_type,
          })
        );
        dispatch(getToken());
        //moving to next step
        dispatch(setCurrentStep(currentStep + 1));
      }
    } catch (error) {
      dispatch( getToken());
      showError((error as Error).message);
    }
  };
  useEffect(() => {
    // Set initial values when template changes
      form.setFieldsValue({
        templateName: template.template_name,
        notificationDescription: formData?.notificationDescription,
      });
  }, [template, formData]);
  return (
    <div className=" in-app-notification-parent-container container-fluid">
      {showMessage.show && (
        <ShowMessage type={showMessage.type} content={showMessage.message} />
      )}
      <Personalization
        personalizationState={personalization}
        setFormData={setFormData}
        setPersonalizationState={setPersonalization}
      />
      <CustomDrawer
        open={showDrawer.value}
        width={Drawer.WIDTH}
        placement="right"
        onClose={() => {
          setShowDrawer((prevState) => ({ ...prevState, value: false }));
        }}
        className="p-0  "
        closable={false}
        footer={
          <Space className="d-flex justify-content-end">
            <PrimaryButton
              onClick={() => {
                setShowDrawer((prevState) => ({ ...prevState, value: false }));
              }}
            >
              {t("cancelLabel")}
            </PrimaryButton>
            <PrimaryButton
              type="primary"
              onClick={() => {
              
                 showDrawer.type == "cdata" ? (
                  saveCdata()
                ) : (
                  <></>
                );
                setShowDrawer((prevState) => ({ ...prevState, value: false }));
              }}
            >
              {t("saveLabel")}
            </PrimaryButton>
          </Space>
        }
        height={"90%"}
      >
        {
        showDrawer.type == "cdata" ? (
          <CustomData
            customDataState={customDataState}
            onChange={(data:any)=>setCustomDataState(data)}
          />
        ) : null}
      </CustomDrawer>
      <TemplateLayout
        leftItems={
          <CampaignDevice
            height={550}
            bazel={getInAppBazelInsetValues(formData.view_mode)}
          >
            <div className="h-100">
            <InAppContainer
                descriptionText={formData?.notificationDescription} //Description text
                uploadImage={handleImageUpload} //image upload function
                headerImage={
                  formData?.iconImage?
                    `${blobContainerCDN + formData?.iconImage}`
                    : ""
                } //header footer image
                expandedImage={
                  formData?.iconImage?
                  `${blobContainerCDN + formData?.iconImage}`
                    : ""
                } //expanded image
                miniScreenImage={
                  formData?.iconImage?
                    `${blobContainerCDN + formData?.iconImage}`
                    : ""
                } //mini screen Image url
                fullScreenImage={
                  formData?.notificationImage
                    ? `${blobContainerCDN + formData?.notificationImage}`
                    : ""
                }// full screen image
                textColor={formData?.descriptionTextClr} //text color
                background={formData?.backgroundColor} //background color
                imageLoading={image} //image status
                selectedImage={formData.view_mode} //selected type of image
                actionTitle={formData?.actionTitle || "Close"} //action title default
                actionTitleBackground={formData?.backgroundColor} //background color
                actionTitleColor={formData?.submitBtnTextClr} //action title color
                callToAction={formData?.showAction} //show call to action
                variableInfo={formData?.variableInfo} //personalisation
                isExpanded={formData?.el} // is expaned true or false
                mediaType={formData.mediaType}

              /> 

            </div>
          </CampaignDevice>
        }
        // </div>
        rightItems={
          <div className="w-100 in-app-notification-parent-container-right-items">
            <Form
              colon={false}
              labelAlign="left"
              labelCol={{ flex: "170px" }}
              layout="horizontal"
              form={form}
            >
              <Row className="pt-3">
                <h5> {t("inAppLabel")}</h5>
              </Row>

              <Row className="pt-3">
                <Col span={24}>
                  <FormItem className="" name={"template_name"} label="">
                    <Selector
                      onChange={(tempString) =>
                        dispatch(
                          fetchTemplateById({
                            app_id: appId,
                            api_key: loginUser.api_key,
                            template_id: JSON.parse(tempString)._id,
                          })
                        )
                      }
                      //options of templates list
                      options={templates.map(
                        ({
                          template_name,
                          _id,
                        }: {
                          template_name: string;
                          _id: string;
                        }) => ({
                          label: template_name,
                          value: JSON.stringify({ _id, template_name }),
                        })
                      )}
                      placeholder={t("selectingExistingTemplateLabel")}
                    />
                  </FormItem>
                  <CreateVariant template={template} formData={formData} variants={variants} setSelectedVariant={setSelectedVariant} selectedVariant={selectedVariant} setVariants={setVariants}  />

                </Col>
              </Row>
              <Row className="pb-2 position-relative d-flex justify-content-center">
                <Col className="d-flex  " span={24}>
                  <FormItem
                    initialValue={template?.template_name}
                    className="w-100 m-0  "
                    label={t("templateNameLabel")}
                    name={"templateName"}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextInput
                      onChange={(e: any) => {
                        setTemplate((prevState) => ({
                          ...prevState,
                          template_name: e.target.value,
                        }));
                      }}
                      placeholder={t("templateNameLabel")}
                      value={template.template_name}
                    ></TextInput>
                  </FormItem>
                </Col>

                <Col className="d-flex mt-3 " span={24}>
                  <FormItem className="w-100 m-0  " label={t("viewLabel")}>
                    <div className="d-flex gap-4">
                      <div className="d-flex flex-column align-items-center in-app-view-img">
                        <img
                          src={
                            formData.view_mode === ScreenTypes.FULL_SCREEN
                              ? fullImageOn
                              : fullImageOff
                          }
                          alt="full-image"
                          className="mr-2 image-hover"
                          onClick={() => {
                            setFormData((prevState:any)=>({...prevState, notificationImage:"", iconImage:"",view_mode:ScreenTypes.FULL_SCREEN}))
                          }}
                        />
                        <span style={{ fontSize: "12px" }}>
                          {t("intersitialLabel")}
                        </span>
                      </div>
                      <div className="d-flex align-items-center flex-column">
                        <img
                          src={formData.view_mode === ScreenTypes.MINI ? miniOn : miniOff}
                          alt="full-image"
                          className="mr-2 image-hover"
                          onClick={() => {
                            setFormData((prevState:any)=>({...prevState,iconImage:"",notificationImage:"",view_mode:ScreenTypes.MINI}))
                          }}
                        />
                        <span style={{ fontSize: "12px" }}>
                          {t("miniLabel")}
                        </span>
                      </div>
                      <div className="d-flex align-items-center flex-column">
                        <img
                          src={
                            formData.view_mode ===  ScreenTypes.HEADER ? headerOn : headerOff
                          }
                          alt="full-image"
                          className="mr-2 image-hover"
                          onClick={() => {
                            setFormData((prevState:any)=>({...prevState,iconImage:"",notificationImage:"",view_mode:ScreenTypes.HEADER}))

                          }}
                        />
                        <span style={{ fontSize: "12px" }}>
                          {t("headerLabel")}
                        </span>
                      </div>
                      <div className="d-flex align-items-center flex-column">
                        <img
                          src={
                            formData.view_mode === ScreenTypes.FOOTER ? footerOn : footerOff
                          }
                          alt="full-image"
                          className="mr-2 image-hover"
                          onClick={() => {
                            setFormData((prevState:any)=>({...prevState,iconImage:"",notificationImage:"",view_mode:ScreenTypes.FOOTER}))
                          }}
                        />
                        <span style={{ fontSize: "12px" }}>
                          {t("footerLabel")}
                        </span>
                      </div> 
                      <div className="d-flex align-items-center flex-column">
                        <img
                          src={
                            formData.view_mode === ScreenTypes.SELF_RENDERED ? selfRenderedOn : selfRenderedOff
                          }
                          alt="full-image"
                          className="mr-2 image-hover"
                          onClick={() => {
                            setFormData((prevState:any)=>({...prevState,iconImage:"",notificationImage:"",view_mode:ScreenTypes.SELF_RENDERED}))

                          }}
                        />
                        <span style={{ fontSize: "12px",height:"80px" ,marginTop:"3px"}}>
                          {t("selfRenderedLabel")}
                        </span>
                      </div>
                    </div>
                  </FormItem>
                </Col>
                {formData.view_mode !== ScreenTypes.SELF_RENDERED  &&          
<>
                {(formData.view_mode == ScreenTypes.HEADER || formData.view_mode == ScreenTypes.FOOTER) && (
                  <>
                    <div className="d-flex mt-2   w-100 justify-content-end">
                      <MoreOutlined
                        onClick={() => {
                          setPersonalization({
                            open: true,
                            field: "notificationDescription",
                          });
                        }}
                      />
                    </div>
                    <Col className=" d-flex gap-1  mt-1 mb-3" span={24}>
                      <FormItem
                        initialValue={formData?.notificationDescription}
                        className="w-100 m-0  "
                        label={t("inAppDescriptionLabel")}
                        name={"notificationDescription"}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <CustomTextArea
                          autoSize
                          value={formData?.notificationDescription}
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,
                              notificationDescription: e.target.value,
                            }));
                          }}
                          maxLength={80}
                          showCount         
                        />
                      </FormItem>
                    </Col>
                    <Col className=" d-flex gap-1  mt-1 mb-3" span={24}>
                      <FormItem
                        className="m-0 my-2"
                        label={t("expandedImageLabel")}
                      >
                        <SegmentedCustom
                          value={formData.el === true} // Use strict equality comparison for proper type checking
                          onChange={(value: boolean) => {
                            setFormData((prevState: any) => ({
                              ...prevState,
                              el: value, // Convert boolean to string for consistency in formData
                              iconImage:""
                            }));
                          }}
                        />
                      </FormItem>
                    </Col>
                  </>
                )}

                {(formData.view_mode == ScreenTypes.FULL_SCREEN || formData.view_mode == ScreenTypes.MINI) && (
                  <Col className="d-flex mt-3 " span={24}>
                    <FormItem
                      className="w-100 m-0  "
                      label={t("setCallToActionLabel")}
                    >
                      <CustomCheckbox
                        checked={formData?.showAction}
                        onClick={(e: any) =>
                          setFormData((prevState: any) => ({
                            ...prevState,
                            showAction: e.target.checked,
                          }))
                        }
                      />
                    </FormItem>
                  </Col>
                )}

                {formData.view_mode !== ScreenTypes.FULL_SCREEN && (
                  <>
                    <div className="d-flex mb-2 mt-2 w-100 justify-content-end">
                      <MoreOutlined
                        onClick={() => {
                          setPersonalization({
                            open: true,
                            field: "actionUrl",
                          });
                        }}
                      />
                    </div>
                    <Col className="d-flex gap-1  " span={24}>
                      <FormItem
                        className="w-100 m-0"
                        label={t("inAppActionLabel")}
                      >
                        <TextInput
                          onChange={(e: any) =>
                            setFormData((prevState: any) => ({
                              ...prevState,

                              actionUrl: e.target.value,
                            }))
                          }
                          value={formData?.actionUrl}
                        />
                      </FormItem>
                    </Col>
                  </>
                )}
                </>
  }
                <Col className="d-flex justify-content-between" span={24}>
                  <FormItem
                    className="my-0 mt-3 w-50"
                    label={t("customDataLabel")}
                  >
                    <SegmentedCustom
                      value={Object.keys(customDataState)?.length > 0}
                      onChange={(value: boolean) => {
                        setCustomDataState(() =>
                          value
                            ? {
                                [uuid()]: {
                                  key: "",
                                  type: "string",
                                  value: "",
                                },
                              }
                            : {}
                        );
                        !value &&
                          setFormData((prevState: any) => {
                            return { ...prevState, cdata: {} };
                          });
                      }}
                    />
                  </FormItem>
                  {Object.keys(customDataState).length > 0 && (
                    <PrimaryButton
                      className="mt-3"
                      onClick={() => {
                        handleCustomClick("cdata");
                        setShowDrawer((prevState) => ({
                          ...prevState,
                          value: true,
                        }));
                      }}
                      style={{
                        color: "white",
                        background: "var( --color-red-main)",
                      }}
                    >
                                      {t("editViewLabel")}
                    </PrimaryButton>
                  )}
                </Col>
                {formData?.showAction &&
                  (formData.view_mode == ScreenTypes.FULL_SCREEN ||
                    formData.view_mode == ScreenTypes.MINI) && (
                    <>
                      <Col className="d-flex mt-3 " span={24}>
                        <FormItem
                          className="w-100 m-0  "
                          label={t("actionTitleLabel")}
                        >
                          <TextInput
                            onChange={(e: any) => {
                              setFormData((prevState: any) => ({
                                ...prevState,

                                actionTitle: e.target.value,
                              }));
                            }}
                            value={formData?.actionTitle}
                          ></TextInput>
                        </FormItem>
                      </Col>
                      <Col className="" span={24}>
                        <div className="d-flex flex-column justify-content-between w-75">
                          <FormItem
                            className="m-0 mt-3"
                            label={t("urlTypeLabel")}
                          >
                            <SegmentedCustom
                              value={formData?.notificationType}
                              onChange={(value: "lp" | "dl") => {
                                setFormData((prevState: any) => ({
                                  ...prevState,

                                  notificationType: value,
                                }));
                              }}
                              options={[
                                { label: t("landingPageLabel"), value: "lp" },
                                { label: t("deepLinkLabel"), value: "dl" },
                              ]}
                            />
                          </FormItem>
                        </div>
                      </Col>
                      {formData?.notificationType == "lp" && (
                        <Col className="d-flex mt-3 " span={24}>
                          <FormItem label={t("openInLabel")} className=" mt-3 ">
                            <SegmentedCustom
                              options={[
                                { label: t("browserLabel"), value: "browser" },
                                { label: t("webViewLabel"), value: "webview" },
                              ]}
                            />
                          </FormItem>
                        </Col>
                      )}
                      <>
                        <div className="d-flex mb-2 mt-2 w-100 justify-content-end">
                          <MoreOutlined
                            onClick={() => {
                              setPersonalization({
                                open: true,
                                field: "actionUrl",
                              });
                            }}
                          />
                        </div>
                        <Col className="d-flex gap-1  " span={24}>
                          <FormItem
                            className="w-100 m-0"
                            label={t("externalURL/deepLinkURLLabel")}
                          >
                            <TextInput
                              onChange={(e: any) =>
                                setFormData((prevState: any) => ({
                                  ...prevState,

                                  actionUrl: e.target.value,
                                }))
                              }
                              value={formData?.actionUrl}
                            />
                          </FormItem>
                        </Col>
                      </>
                    </>
                  )}
  {formData.view_mode !== ScreenTypes.SELF_RENDERED  &&          
    
                <Col className="d-flex mt-3 " span={24}>
                  <FormItem
                    className="w-100 m-0  in-app-notification-container-text-field-color"
                    label={t("colorsLabel")}
                  >
                    <div className="d-flex  justify-content-between">
                      <FormItem className="text-center">
                        <TextInput
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,

                              headerTextClr: e.target.value,
                            }));
                          }}
                          type="color"
                          className=""
                          style={{ maxWidth: "60px" }}
                          value={formData?.headerTextClr}
                        ></TextInput>
                        <p
                          style={{ fontSize: "12px" }}
                          className="m-0 p-0 mt-2"
                        >
                          Header text
                        </p>
                      </FormItem>
                      <FormItem className="text-center">
                        <TextInput
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,

                              descriptionTextClr: e.target.value,
                            }));
                          }}
                          type="color"
                          className=""
                          value={formData?.descriptionTextClr}
                          style={{ maxWidth: "60px" }}
                        ></TextInput>
                        <p
                          style={{ fontSize: "12px" }}
                          className="m-0 p-0 mt-2"
                        >
                          {t("descriptionTextLabel")}
                        </p>
                      </FormItem>
                      <FormItem className="text-center">
                        <TextInput
                          onChange={(e: any) => {
                            setFormData((prevState: any) => ({
                              ...prevState,

                              backgroundColor: e.target.value,
                            }));
                          }}
                          type="color"
                          className=""
                          style={{ maxWidth: "60px" }}
                          value={formData?.backgroundColor}
                        ></TextInput>
                        <p
                          style={{ fontSize: "12px" }}
                          className="m-0 p-0 mt-2"
                        >
                          {t("backgroundLabel")}
                        </p>
                      </FormItem>
                      {formData?.showAction && (
                        <FormItem className="text-center">
                          <TextInput
                            onChange={(e: any) => {
                              setFormData((prevState: any) => ({
                                ...prevState,

                                submitBtnTextClr: e.target.value,
                              }));
                            }}
                            type="color"
                            className=""
                            style={{ maxWidth: "60px" }}
                            value={formData?.submitBtnTextClr}
                          ></TextInput>
                          <p
                            style={{ fontSize: "12px" }}
                            className="m-0 p-0 mt-2"
                          >
                            {t("actionTitleTextLabel")}
                          </p>
                        </FormItem>
                      )}
                    </div>
                  </FormItem>
                </Col>
  }
              </Row>
            </Form>
          </div>
        }
        footerItem={
          <div>
            <Row className="gap-2  d-flex justify-content-end">
              <PrimaryButton
                disabled={!template.template_name}
                type="primary"
                htmlType="submit"
                onClick={handleSaveOrUpdate}
              >
                {selectedTemplate ? t("updateLabel"): t("saveLabel")}
              </PrimaryButton>
              <PrimaryButton
                style={{
                  color: "white",
                  background: "var( --color-red-main)",
                }}
                onClick={() => {
                  dispatch(setCampaignData({})); // on close set campaign data in redux empty
                  dispatch(setSelectedTemplate(null))
                  dispatch(setCurrentStep(0)); //set step to default
                }}
              >
                {t("closeLabel")}
              </PrimaryButton>
            </Row>
          </div>
        }
      />
    </div>
  );
};

export default InAppNotification;
