export const acqSrcChurnModifiedData = (
  data: any,
  filter: string,
  churn: boolean
) => {
  let newAcquisition = 0;
  let repeatAcquisition = 0;
  let churnModifiedData: any = [];
  let churnTableModifiedData: any = {};
  let churnTotal = 0;
  const platformKeys =
    filter && filter != "all" ? [filter] : Object.keys(data ? data : {});
  if (!churn) {
    platformKeys.forEach((key) => {
      //iterates over each platform key and checks self key
      data[key].forEach((entry : any) => {
      const install = entry?.self?.in;
      const repeatInstall = entry?.self?.ri;
      newAcquisition += isNaN(install) ? 0 : install;
      repeatAcquisition += isNaN(repeatInstall) ? 0 : repeatInstall;
      });
    });
    return {
      data: [
        {
          id: "newAcquisition",
          label: "newAcquisition",
          value: newAcquisition,
        },
        {
          id: "repeatAcquisition",
          label: "repeatAcquisition",
          value: repeatAcquisition,
        },
      ],
      tableData: {
        newAcquisition,
        repeatAcquisition,
        total: newAcquisition + repeatAcquisition,
      },
      total: newAcquisition + repeatAcquisition,
    };
  } else {
    platformKeys.forEach((key) => {
      let tempObj: any = {};
      const uninstall = isNaN(data[key][0]?.self?.un)
        ? 0
        : data[key][0]?.self.un;
      const ruinstall = isNaN(data[key][0]?.self?.ru)
        ? 0
        : data[key][0]?.self.ru;
      tempObj["value"] = uninstall + ruinstall;
      tempObj["label"] = key;
      tempObj["id"] = key;
      churnTableModifiedData[key] = tempObj["value"];
      churnTotal += tempObj["value"];
      churnModifiedData.push(tempObj);
    });
    return {
      data: churnModifiedData,
      tableData: churnTableModifiedData,
      total: churnTotal,
    };
  }
};
export const locationModifiedData = (
  cityData: any,
  countryData: any,
  filter: "country" | "city",
  churn: boolean,
  numberOfRecords = 6
) => {
  try {
    const data = filter == "city" ? cityData : countryData;
    let modifiedData = [];
    if (churn) {
      modifiedData = churnLocationModifiedData(data, filter);
    } else {
      modifiedData = data?.map((item: any) => {
        return { location: item[0], cur: item[1], prev: item[2] };
      });
    }
    return modifiedData
  } catch (error) {
    console.log(error);
  }
  function churnLocationModifiedData(data: any, filter: "city" | "country") {
    let uniqueKeyObject: any = {};
    data.forEach((item: any) => {
      const key: string = filter == "city" ? item.cty : item.cc;
      if (uniqueKeyObject[key]) {
        uniqueKeyObject[key]["cur"] += item.total;
      } else {
        uniqueKeyObject[key] = { location: key, cur: item.total };
      }
    });
    return Object.values(uniqueKeyObject).sort(
      (a: any, b: any) => b.cur - a.cur
    );
  }
};
export const acquisitionOSModifiedData = (
  data: any,
  filter: string,
  numberOfRecords: number
) => {
  let modifiedData: any = [];
  let versions = Object.keys(data ? data : {});
  let allTotal: number = 0;
  versions.forEach((key) => {
    let total = 0;
    let platform = "";
    data[key].forEach((v: any, index: number) => {
      if (!index) {
        platform = v.p;
      }
      if (platform == filter || filter == "all") {
        total += v.total;
      }
    });
    allTotal += total;
    if (platform == filter || filter == "all") {
      modifiedData.push({ id: key, label: key, value: total, platform });
    }
  });
  modifiedData = modifiedData
    .sort((a: any, b: any) => b.value - a.value)
    .slice(0, numberOfRecords);
  return { versions: modifiedData, total: allTotal };
};
export const acqChurnAppVersionModifiedData = (
  data: any,
  filter: string,
  churn: boolean,
  numberOfRecords: number
) => {
  let mData: any = [];
  let total = 0;
  if (churn) {
    let uniqueKeyObject: any = {};
    data.forEach((version: any) => {
      if(version.p==filter || filter=="all"){

        if (uniqueKeyObject[version.av]) {
          total+=version.total
          uniqueKeyObject[version.av].value += version.total;
        } else {
          uniqueKeyObject[version.av] = {
            id: `${version.p + " " + version.av}`,
            label: `${version.p + " " + version.av}`,
            value: version.total,
          };
        }
      }
    });
    mData=Object.values(uniqueKeyObject)

  }
  else{

    
    const platformKeys = data
    ? filter && filter != "all"
    ? [filter]
    : Object.keys(data)
    : [];
    const processedVersions: any = {};
    platformKeys.forEach((key) => {
      data[key].forEach(({ x, value }: any) => {
        total += value;
        if (processedVersions[x]) {
          processedVersions[x].value += value;
        } else {
          processedVersions[x] = { id: x, label: x, value };
        }
      });
      mData = Object.values(processedVersions);
      
    });
  }
  mData = mData
      .sort((a: any, b: any) => b.value - a.value)
      .slice(0, numberOfRecords);
  return { versions: mData, total };
};
export const dayWiseModifiedData = (
  { newData, repeatData }: any,
  filter: string,
  dayOrHour: "d" | "h"
) => {
  let finalInputArray = [...newData, ...repeatData];
  var aggregatedData: any = {};
  finalInputArray.forEach((item: any) => {
    if (filter == "all" || item.p == filter) {
      if (!aggregatedData[item[dayOrHour]]) {
        aggregatedData[item[dayOrHour]] = item.isnew
          ? { ...item, new: item.total, repeat: 0 }
          : { ...item, new: 0, repeat: item.total };
      } else if (aggregatedData[item[dayOrHour]].isnew === item.isnew) {
        if (item.isnew) {
          aggregatedData[item[dayOrHour]].new += item.total;
        } else {
          aggregatedData[item[dayOrHour]].repeat += item.total;
        }
      } else {
        aggregatedData[item[dayOrHour]] = item.isnew
          ? { ...aggregatedData[item[dayOrHour]], new: item.total }
          : { ...aggregatedData[item[dayOrHour]], repeat: item.total };
      }
    }
  });
  const output = Object.values(aggregatedData);
  const modifiedData = output.map((obj: any) => {
    return { new: obj.new, repeat: obj.repeat, name: obj[dayOrHour] };
  });
  return modifiedData;
};
