// Function to get the dimensions of an image file
export async function getFileDimensions(file: string) {
  return new Promise((resolve, reject) => {
    const img = new Image(); // Create an image element
    img.src = file; // Set the source to the provided file
    img.onload = () => {
      // Once the image is loaded, resolve with its dimensions
      const dimensions = { width: img.width, height: img.height };
      resolve(dimensions);
    };
    img.onerror = (error) => {
      // Reject if there's an error loading the image
      reject(error);
    };
  });
}

// Function to get the file size of a video file
export const getVideoFileSize = async (file: any) => {
  return file.size; // Return the size property of the file
};

// Type definition for dimension configuration
type DimensionConfig = {
  width: { from: number; to: number }; // Width range
  height: { from: number; to: number }; // Height range
  maxSize?: number; // Optional maximum size in bytes
  video?: {min : number ; max : number} //video range
  gif? : {min : number ; max : number} // gif range
};

// Define required dimensions for various media types
const requiredDimension: Record<string, DimensionConfig> = {
  pushExpanded: {
    width: { from: 600, to: 800 },
    height: { from: 300, to: 400 },
    video : {min:100 * 1024 , max : 10 * 1024 * 1024}, //100kb to 10mb size for video 
    gif: {min: 48 * 1024 , max: 1 * 1024 * 1024} // 48 kb to 1mb
  },
  pushHeader: {
    width: { from: 60, to: 96 },
    height: { from: 60, to: 96 },
  },
  webPushExpanded: {
    width: { from: 600, to: 800 },
    height: { from: 300, to: 400 },
  },
  webPushHeader: {
    width: { from: 60, to: 96 },
    height: { from: 60, to: 96 },
  },
  inAppNotificationFull: {
    width: { from: 480, to: 480 },
    height: { from: 800, to: 800 },
    maxSize: 600 * 1024, // 600KB
    video : {min:100 * 1024 , max : 10 * 1024 * 1024}, //100kb to 10mb size for video 
  },
  inAppNotificationMini: {
    width: { from: 400, to: 400 },
    height: { from: 400, to: 400 },
    maxSize: 600 * 1024, // 600KB
    video : {min:100 * 1024 , max : 10 * 1024 * 1024}, //100kb to 10mb size for video 
  },
  inAppNotificationSmall: {
    width: { from: 96, to: 96 },
    height: { from: 96, to: 96 },
    maxSize: 500 * 1024, // 500KB
  },
  inAppNotificationHeader: {
    width: { from: 96, to: 96 },
    height: { from: 96, to: 96 },
    maxSize: 500 * 1024, // 500KB
  },
  webPopUp: {
    width: { from: 200, to: 300 },
    height: { from: 100, to: 200 },
  },
  imageWhatsApp: {
    width: { from: 600, to: 800 },
    height: { from: 300, to: 400 },
  },
  inAppNotificationHeaderFooterExpanded: {
    width: { from: 800, to: 800 },
    height: { from: 200, to: 200 },
    maxSize: 600 * 1024, // 600KB
  },
};

// Validate dimensions and size of the file based on its type
export const validateImageDimensions = async (
  type:
    | "pushExpanded"
    | "pushHeader"
    | "webPushExpanded"
    | "webPushHeader"
    | "inAppNotificationFull"
    | "inAppNotificationMini"
    | "inAppNotificationSmall"
    | "inAppNotificationHeader"
    | "webPopUp"
    | "imageWhatsApp"
    | "inAppNotificationHeaderFooterExpanded",
  file: any
) => {
  const typeConfig = requiredDimension[type]; // Get the configuration for the given type
  if (!typeConfig) {
    return { valid: false, message: "Unsupported file type." };
  }
       // Validate video size for all platforms
       if (file.type.startsWith("video/")) {

        if ( typeConfig.video && (file.size < typeConfig.video?.min || file.size > typeConfig.video?.max)) {
          return {
            valid: false,
            message: `Video size must be between ${typeConfig.video?.min / 1024} KB and ${
              typeConfig.video?.max/ (1024 * 1024)
            } MB.`,
          };
        }
        return { valid: true, message: "" };
      }
  // Validate image dimensions against the type configuration
  const imageDimensions: any = await getFileDimensions(URL.createObjectURL(file));
  
  if (
    imageDimensions.width >= typeConfig.width.from &&
    imageDimensions.width <= typeConfig.width.to &&
    imageDimensions.height >= typeConfig.height.from &&
    imageDimensions.height <= typeConfig.height.to
  ) {
 
 
  // Validate GIF dimensions and size
  if (file.type === "image/gif") {
    if (typeConfig.gif && (file.size < typeConfig.gif?.min || file.size > typeConfig.gif?.max)) {
      return {
        valid: false,
        message: `GIF size must be between ${typeConfig.gif?.min / 1024} KB and ${
          typeConfig.gif?.max / (1024 * 1024)
        } MB.`,
      };
    }
    return { valid: true, message: "" };
  }
  
    // If maxSize is defined, validate the file size
    if (typeConfig.maxSize) {
      const sizeValidation = validateFileSize(file, typeConfig.maxSize);
      if (!sizeValidation.valid) return sizeValidation;
    }
    return { valid: true, message: "" };
  } else {
    // Return error message if dimensions are invalid
    return {
      valid: false,
      message: `Image dimensions need to be between (w)${
        typeConfig.width.from
      } x ${typeConfig.height.from}(h) and (w)${
        typeConfig.width.to
      } x ${typeConfig.height.to}(h)`,
    };
  }
};

// Helper function to validate the file size
export const validateFileSize = (file: any, maxSize: number) => {
  if (file.size <= maxSize) {
    return { valid: true, message: "" };
  } else {
    return {
      valid: false,
      message: `File size must be less than or equal to ${maxSize / 1024} KB`,
    };
  }
};
