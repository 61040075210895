import React, { useState } from "react";
import {  Form, message,  Upload } from "antd";
import TextInput from "../../components/Inputs/TextInput/TextInput";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import "./EditProfile.css";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { RcFile } from "antd/es/upload/interface";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import centralApi from "../../services/centralApi";
import {
  validateConfirmPassword,
  validateLetters,
  validatePassword,
} from "../../validator/antdValidator";
import useMessage from "../../hooks/useMessage";
import { getToken } from "redux/features/app/authTokenSlice";
import { EDStatusEnum } from "utils/enums";

const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
    return false;
  }
  const isLt20KB = file.size / 1024 < 20;
  if (!isLt20KB) {
    message.error("Image must be smaller than 20KB!");
    return false;
  }

  return isJpgOrPng && isLt20KB;
};

export const EditProfile: React.FC = () => {
  const loginUser = useSelector((state: any) => state.loginUser);
  const [loading, setLoading] = useState(false);
  const dispatch: any = useDispatch();
  const [imageUrl, setImageUrl] = useState<string>(loginUser?.data?.userpicurl);
  const [profileImageUrl, setProfileImageUrl] = useState<string>();
  const { showError, showSuccess } = useMessage();
  const { api_key } = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);

  const features = useSelector((state: any) => state.featuresSetting.data);
  const siteSetting = features[0]?.siteSettings;
  const handleUploadImage = async (info: any) => {
    const formData = new FormData();

    if (info.image.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.image) {
      // Get this url from response in real world.
      getBase64(info.image.originFileObj, (url: any) => {
        setLoading(false);
        setImageUrl(url);
      });
      formData.append("image", info.image.originFileObj);
      formData.append("image_id", info.image.name);
      formData.append("app_id", appId);

      try {
        setLoading(true);
        const url = await centralApi("POST", "/i/users/images", formData, null,false,authToken);
        dispatch(getToken())
        setProfileImageUrl(url.baseURL + url.url);
        showSuccess(t("sucessfullySavedLabel"));

        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        showError(error.message);
      }
      dispatch(getToken())
    }
  };
  const handleSubmit = async (values: any) => {
    const payload = {
      args: JSON.stringify({
        user_id: loginUser.data._id,
        full_name: values?.full_name,
        title: values?.title,
        company: values?.company,
        ...(siteSetting?.is_sso === EDStatusEnum.DISABLE ? { password: btoa(values?.password) } : {}),

        phone: values?.phone,
        userpicurl: profileImageUrl,
      }),
      app_id: appId,
      authToken,

      api_key: api_key,
    };
    await updateUser(payload);

    dispatch(getToken())
  };
  async function updateUser(formData: any) {
    const url: string = "/i/users/update";

    try {
      setLoading(true);
      const res = await centralApi("POST", url, formData, null);
      showSuccess(res.result);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      showError(error.message);
    }
  }
 

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div>
      <div className=" edit-profile-container">
        <Form>
          <Form.Item className="custom-label  ">
            <div className="edit-profile-uploader-container">
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={(value) => handleUploadImage({ image: value.file })}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                      width: "100%",
                      maxWidth: "200px", // Set the maximum width of the displayed image
                      maxHeight: "100px", // Set the maximum height of the displayed image
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </div>
          </Form.Item>
        </Form>

        <div className="edit-profile-container-form">
          <Form
            onFinish={handleSubmit}
            layout="horizontal"
            colon={false}
            initialValues={{
              loginTime: loginUser?.data?.previous_login_time,
              full_name: loginUser?.data?.full_name,
              title: loginUser?.data?.title,
              company: loginUser?.data?.company,
              phone : loginUser?.data?.phone
            }}
            validateTrigger={["onBlur"]}
          >
            <Form.Item
              label={t("fullNameLabel")}
              name="full_name"
              // className="col-12 pe-1 col-sm-6"
              rules={[
                { validator: validateLetters },
                { required: true, message: `${t("enterFullNameLabel")}` },
              ]}
            >
              <TextInput placeholder={t("fullNameLabel")} />
            </Form.Item>
            <Form.Item
              label={t("titleLabel")}
              name="title"
              // className="col-12 ps-1  col-sm-6"
              rules={[
                { validator: validateLetters },
                { required: true, message: `${t("enterTitleLabel")}` },
              ]}
            >
              <TextInput placeholder={t("titleLabel")} />
            </Form.Item>

            <Form.Item
              label="Company"
              name="company"
              rules={[{ required: true, message: `${t("enterCompanyLabel")}` }]}
            >
              <TextInput placeholder={t("companyLabel")} />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[{ required: true, message: `${t("enterPhoneLabel")}` }]}
            >
              <TextInput placeholder={t("phoneLabel")} type="number" />
            </Form.Item>
{siteSetting?.is_sso==EDStatusEnum.DISABLE ?<>
            <Form.Item
              label={t("passwordLabel")}
              name="password"
              rules={[{ validator: validatePassword }]}
            >
              <TextInput type="password" placeholder={t("passwordLabel")} />
            </Form.Item>
            <Form.Item
              label={t("confirmPasswordLabel")}
              rules={[
                ({ getFieldValue }) => ({
                  validator: (_, value) =>
                    validateConfirmPassword(
                      _,
                      value,
                      getFieldValue("password")
                    ),
                }),
              ]}
            >
              <TextInput
                type="password"
                placeholder={t("confirmPasswordLabel")}
              />
            </Form.Item>
            </>:null}
            <Form.Item label={t("lastLoginTimeLabel")} name="loginTime">
              <TextInput placeholder={t("lastLoginTimeLabel")} disabled />
            </Form.Item>
            <Form.Item>
              <PrimaryButton type="primary" htmlType="submit">
                Save
              </PrimaryButton>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
