import { Helmet } from "react-helmet";
import Selector from "../../../components/Selector/Selector";
import WebPushReportCard from "../../../components/Cards/WebPushReportCard/WebPushReportCard";
import "./CampaignReports.css";
import { t } from "i18next";
import { CampaignChannelTypeEnum } from "utils/enums";
import { DateRangeData } from "@customTypes/dateRange"
import { useEffect, useMemo, useState } from "react";
import { DateRangeSelector } from "components/DateTimeSelectors/DateRangeSelector/DateRangeSelector";
import centralApi from "services/centralApi";
import { API_ENDPOINTS } from "utils/constants";
import { useSelector } from "react-redux";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import { formatDate } from "helperFunctions/date";
import Spinner from "components/Spinner/Spinner";
import CampaignPerformance from "./PerformanceChart/PerformanceChart";

export const CampaignReports = () => {
  const [selectedChannel, setSelectedChannel] =useState<string>( CampaignChannelTypeEnum.ALL);
  const [dateRange, setDateRange] = useState<DateRangeData | null>(null);
  const [campaignPerformance ,setCampaignPerformance] = useState<any>();
  const [loading, setLoading] = useState(BOOLEAN_OPTIONS.FALSE)
  const loginUser = useSelector((state: any) => state.loginUser)
  const { appId } = useSelector((state: any) => state.activeApp)

   // Fetching channel status from redux store
   const features = useSelector((state: any) => state.featuresSetting.data);
   const channelStatus = features[0]?.channel_status;
 
   // Memoize the options generation for performance
   const options = useMemo(() => {
     // Pre-generate a map of campaign channel enum types for easier access
     const allChannels = Object.values(CampaignChannelTypeEnum);
     
     // If channel_status is undefined, return all enum channels
     if (!channelStatus) {
       return allChannels.map((key) => ({
         value: key,
         label: key,
       }));
     }
 
     // Otherwise, filter the channels where the status is true
     return allChannels
       .filter((key) => key == CampaignChannelTypeEnum.ALL || channelStatus[key] === true) // Only include enabled channels
       .map((key) => ({
         value: key,
         label: key,
       }));
   }, [channelStatus]); 

  
    //get the campaign reports from api and set the state
    async function getCampaignReports() {
      try {
        const apiKey = loginUser.data.api_key
        if (apiKey && appId) {
          setLoading(BOOLEAN_OPTIONS.TRUE)
          const params= {
          app_id: appId,
          api_key: apiKey,
          sd:dateRange?.startDate_C ? dateRange?.startDate_C * 1000 : undefined,
          ed:dateRange?.endDate_C ?  dateRange?.endDate_C * 1000 : undefined,
          channelType:selectedChannel == CampaignChannelTypeEnum.ALL ? undefined : selectedChannel,
        }
          
        const data =  await centralApi("GET",API_ENDPOINTS.GET_CAMPAIGNS_PERFORMANCE_REPORTS , null, params)
        setCampaignPerformance(data)
        setLoading(BOOLEAN_OPTIONS.FALSE)
        
        }
      } catch (error) {
        setLoading(BOOLEAN_OPTIONS.FALSE)
      }
    }

  const handleDateRange = (dateRangeData: any) => {
    setDateRange(dateRangeData);
  };


  useEffect(()=>{
    if(dateRange){
    getCampaignReports();
    }
  },[dateRange,selectedChannel]);

  return (
    <div className="container-fluid">    
      <div className="web-push-reports-container">
        <Helmet>
          <title>Appice | Campaign Reports</title>
        </Helmet>
        <div className="w-100 h-100 d-flex justify-content-between flex-wrap my-3">
          <div>
          <h4>{t("overviewLabel")}</h4>
          </div>        
          <div>
              <div className=" d-flex gap-1">
              <Selector
                placeholder="Select channel"
                onChange={(value) => setSelectedChannel(value)}
                style={{ width: 150 }}
                value={selectedChannel}
                showSearch
                options={options}
              />
            <div>
            <strong>{t("periodLabel")} </strong>
              <DateRangeSelector
              className="w-75"
              onDateChange={handleDateRange}
              disableRange={{ range: 1, rangeType: "year" }}
              disableType="years"

            />
           </div>
         </div>
          </div>
        </div>
        {loading ?  (
          <div className="d-flex justify-content-center my-5">
            <Spinner />
          </div>
        ) : (
        <>
        <div className="web-push-reports-cards">
          <WebPushReportCard 
            heading={t("reachableAudienceLabel")} 
            number={campaignPerformance?.reachCount} 
            percentageTwo={campaignPerformance?.reachDiffPercentage}
            timeString={`w.r.t. ${formatDate(
              dateRange?.startDate_P,
              "D MMM, YY"
            )} - ${formatDate(dateRange?.endDate_P, "D MMM, YY")}`}
            />
          <WebPushReportCard
            type="sent"
            heading={t("sentLabel")}
            number={campaignPerformance?.sentCount}
            percentageTwo={campaignPerformance?.sentDiffPercentage}
            timeString={`w.r.t. ${formatDate(
              dateRange?.startDate_P,
              "D MMM, YY"
            )} - ${formatDate(dateRange?.endDate_P, "D MMM, YY")}`}
          />
          <WebPushReportCard
          type="double"
            heading={t("clicksLabel")}
            headingTwo={t("CTRLabel")}
            number={campaignPerformance?.clicksCount}
            headingTwoPercentage={campaignPerformance?.ctrPercentage}
            percentageTwo={campaignPerformance?.clicksDiffPercentage}
            timeString={`w.r.t. ${formatDate(
              dateRange?.startDate_P,
              "D MMM, YY"
            )} - ${formatDate(dateRange?.endDate_P, "D MMM, YY")}`}
          />
          <WebPushReportCard
            heading={t("ConversionLabel")}
            number={campaignPerformance?.conversionCount}
            percentageOne={campaignPerformance?.conversionPercentage}
            percentageTwo={campaignPerformance?.conversionDiffPercentage}
            timeString={`w.r.t. ${formatDate(
              dateRange?.startDate_P,
              "D MMM, YY"
            )} - ${formatDate(dateRange?.endDate_P, "D MMM, YY")}`}
          />
        </div>       
        <div className="mt-3 mb-1">
          <h6>{t("campaignPerformanceLabel")}</h6>
        </div>
        <div className="web-push-reports-campaigns">
            <CampaignPerformance data={campaignPerformance?.campaignPerformanceDayWise} />
        </div>
        </>
        )}
      </div>
   
    </div>
  );
};
