import React, { useEffect,useState } from "react";
import Selector from "components/Selector/Selector";
import "./Usage.css";
import centralApi from "../../services/centralApi";
import { useSelector } from "react-redux";
import HeaderCountPercentage from "./component/HeaderCountPercentage";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import { DonutPieChart } from "components/Charts/DonutPieChart/DonutPieChart";
import CustomTable from "components/CustomTable/CustomTable";
import { colorSchema } from "components/Charts/DonutPieChart/colorSchema";
import { Heatmap } from "components/Charts/Heatmap/Heatmap";
import { aggregateData } from "../../helperFunctions/usage";
import { aggregateTimelySessionData } from "../../helperFunctions/usage";
import { formatData } from "../../helperFunctions/usage";
import { getPercentage } from "../../helperFunctions/number";
import { calculateTotalValues } from "../../helperFunctions/usage";
import { fillMissingValues } from "../../helperFunctions/usage";
import Spinner from "components/Spinner/Spinner";
import { DateRangeSelector } from "components/DateTimeSelectors/DateRangeSelector/DateRangeSelector";
import { DateRangeData } from "@customTypes/dateRange";
import TableChartLayout from "layout/TableChartLayout/TableChartLayout";
import { API_ENDPOINTS } from "utils/constants";
import { PlatformEnum } from "utils/enums";
import { CONSTANTS } from "utils/constants/constants";

type SessionGraphData = {
  id: string;
  data: { x: string; y: number }[];
};
interface UserGraphData {
  id: string;
  label: string;
  value: number;
}


const Usage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const  [avgSessionTotalCount, setAvgSessionTotalCount] = useState(0);  
  const  [previousCount, setPreviousCount] = useState(0);
  const  [avgSessionChart, setAggregateData] = useState([]);
  const  [totalSessionCount, setTotalSessionCount] = useState(0);
  const  [totalPreviousSessionCount, setTotaPreviousSessionCount] = useState(0);
  const  [usersChunkGraphdata,setUsersChunkGraphdata]= useState([]);
  const  [usersChunkTabledata,setUsersChunkTabledata]= useState([]);
  const  [sessionChunkTableData, setSessionChunkTableData] = useState<{ key: string; total: number }[]>([]);
  const  [sessionChunkGraphData, setSessionChunkGraphData] = useState<SessionGraphData[]>([]);
  const  [previousUsersCount, setPreviousUsersCount] = useState(0);
  const  [currentUsersCount, setCurrentUsersCount] = useState(0);  
  const  [avgTableData, setAvgTableData] = useState([]);
  const { appId, appName } = useSelector((state: any) => state.activeApp);
  const [dateRange, setDateRange] = useState<DateRangeData | null>(null);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const [platform, setPlatform] = useState(PlatformEnum.ALL);
  // fetch session stats  data from api
  async function getActiveUserSessionStats() {
    setLoading(true);
    const payload = {
      args: JSON.stringify({ ...dateRange }),
      app_id: appId,
      platform: platform,
      api_key: loginUser.api_key,
    };
    try {
      const data = await centralApi(
        "GET",
        API_ENDPOINTS.GET_SESSION_DETAILS,
        null,
        payload
      );
      setLoading(false);
      if(data.avgSessionMap){
      const { currentCount, previoisTotalCount, currentData } = data.avgSessionMap;
      setAvgSessionTotalCount(Number(parseFloat(currentCount[0].avg_session_duration_in_minutes_overall).toFixed(2)));
      setPreviousCount(Number(parseFloat(previoisTotalCount[0].avg_session_duration_in_minutes_overall).toFixed(2)));
      
      setTotaPreviousSessionCount(previoisTotalCount[0].total_session_count_overall);
      const mapData = currentData.map(({ session_bucket, unique_user_count }: any) => {
        const id = session_bucket.split(' ')[0]; 
        return {
          id,
          label: id,
          value: parseInt(unique_user_count), 
          session: session_bucket,
          user: unique_user_count
        };
      });
      
      setAggregateData(mapData.map(({ id, label, value }: any) => ({ id, label, value })));
      setAvgTableData(mapData.map(({ session, user }: any) => ({ session, user })));
      }
    } catch (error) {}
  }
  //fetch timely session data from api
  async function getActiveUserTimelySessions() {
    const payload = {
      args: JSON.stringify({ ...dateRange }),
      app_id: appId,
      api_key: loginUser.api_key,
      platform,
    };
    try {
      setLoading(true);
      const data = await centralApi(
        "GET",
        API_ENDPOINTS.GET_AVG_SESSION_DETAILS,
        null,
        payload
      );
      const usersGraphData = data.userCurrentGraphResult.map((item: any) => ({
        id: item.session_bucket,
        label: item.session_bucket,
        value: parseInt(item.user_count, 10)
      }));

      setUsersChunkGraphdata(usersGraphData)
      setUsersChunkTabledata(usersGraphData.map((item: any) => ({ session: item.label, user: item.value })));

      const totalPreviosUserCount = data.usersPreviousGraphResult.reduce((sum: any, item: any) => sum + parseInt(item.user_count, 10), 0);
      setPreviousUsersCount(totalPreviosUserCount)

      // Calculate the total user_count
      const totalUserCount = usersGraphData.reduce((sum: any, item: any) => sum + item.value, 0);
      setCurrentUsersCount(totalUserCount)

      const sessionGraphData = data.sessionQueryResult.map(({ time_of_day, session_count }: any) => ({
        id: time_of_day.toLowerCase().replace(/\s+/g, '_'),
        data: [{ x: 'total', y: parseInt(session_count, 10) }],
      }));

      setSessionChunkGraphData(sessionGraphData);

      const sessionTableData = data.sessionQueryResult.map(({ time_of_day, session_count }: any) => ({
        key: time_of_day,
        total: parseInt(session_count, 10),
      }));

      setSessionChunkTableData(sessionTableData);
      const totalSessionSum = sessionTableData.reduce((sum :any , item:any) => sum + item.total, 0);
       setTotalSessionCount(totalSessionSum);

      setLoading(false);
    } catch (error) {
      console.log("EROR 2",error)

      setLoading(false);
    }
  }

  useEffect(() => {
    if (dateRange && loginUser.api_key) {
      getActiveUserSessionStats();
      getActiveUserTimelySessions();
    }
  }, [loginUser, dateRange]);
  const styleBtn = {
    width: "auto",
    marginRight: "10px",
    marginBottom: "10px",
  };

  const userSessionColumn = [
    {
      title: t("sessionAndUsersLabel"), // Hardcoded title
      dataIndex: "session",
      key: "session",
    },
    {
      title: t("usageBoxLabelUser"),
      dataIndex: "user",
      key: "user",
    },
  ];
  const avgSessionLengthColumn = [
    {
      title: t("sessionLengthUsageLabel"),
      dataIndex: "session",
      key: "session",
    },
    {
      title: t("usageBoxLabelUser"),
      dataIndex: "user",
      key: "user",
    },
  ];
  const userTimelySessionColumn = [
    {
      title: t("timePeriodlabel"),
      dataIndex: "key",
    },
    {
      title: t("totalLabel"),
      dataIndex: "total",
      key: "total",
    },
  ];
  const userHeaderComp = (
    <HeaderCountPercentage
      total={currentUsersCount}
      percentage={
        getPercentage(
          previousUsersCount,
          currentUsersCount
        ).percentage
      }
      upDownStatus={
        getPercentage(
          previousUsersCount,
          currentUsersCount
        ).status
      }
    />
  );
  const avgSessionHeaderComp = (
    <HeaderCountPercentage
      total={avgSessionTotalCount}
      percentage={
        getPercentage(
          previousCount,
          avgSessionTotalCount
        ).percentage
      }
      upDownStatus={
        getPercentage(
          previousCount,
          avgSessionTotalCount
        ).status
      }
      title="Minutes" 
    />
  );
  const sessionHeaderComp = (
    <HeaderCountPercentage
      total={totalSessionCount}
      percentage={
        getPercentage(
          totalPreviousSessionCount,
          totalSessionCount
        ).percentage
      }
      upDownStatus={
        getPercentage(
          totalPreviousSessionCount,
          totalSessionCount
        ).status
      }
    />
  );
  return (
    <div className="container-fluid p-sm-2 p-1 ">
      <Helmet>
        <title>Appice | Usage</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center py-3 flex-wrap">
        <div>
          <h4>{t("eventDataLabel")}</h4>
        </div>
        <div className="d-flex">
          {/* HIDE PLATFORM FOR NOW AS WE DON"T SUPPORT PLATFORM IN PG QUERIES YET */}
          {/* <div>
            <strong>{t("platformLabel")} </strong>
            <Selector
              onChange={(value) => {
                setPlatform(value);
              }}
              size="middle"
              defaultValue={platform}
              style={{ ...styleBtn, width: "90px" }}
              options={[
                {
                  value: PlatformEnum.ALL,
                  label: t("allLabel"),
                },
                {
                  value: PlatformEnum.ANDROID,
                  label: t("androidLabel"),
                },
                {
                  value: PlatformEnum.IOS,
                  label: t("iosLabel"),
                },
                {
                  value: PlatformEnum.WEB,
                  label: t("webLabel"),
                },
              ]}
            />
          </div> */}
          <div>
            <strong>{t("periodLabel")} </strong>
            <DateRangeSelector
              onDateChange={(value: DateRangeData) => setDateRange(value)}
              disableRange={{ range: 1, rangeType: "year" }}
              disableType="year"
              numDaysToShow={29} // Example value
            />
          </div>
        </div>
      </div>
      <div className=" d-flex justify-content-between usage-container">
        <TableChartLayout
          title={t("usageBoxLabelUser")}
          className="usage-container-layout-box"
          showDownloadIcon={false}
        >
          <>
            {userHeaderComp}
            {loading ? (
              <Spinner />
            ) : (
              <DonutPieChart
                showLegends
                data={usersChunkGraphdata}
                colorSchema={colorSchema}
                totalEvents={20}
                innerRadius={0.001}
                enableArcLinkLabels={false}
              />
            )}
          </>
          <>
            {userHeaderComp}
            {loading ? (
              <Spinner />
            ) : (
              <CustomTable
                dataSource={usersChunkTabledata}
                columns={userSessionColumn}
                pagination={false}
                className="usage-container-table"
                style={{ marginTop: "35px" }}
              />
            )}
          </>
        </TableChartLayout>
        <TableChartLayout
          title={t("usageBoxLabelAvgSession")}
          className="usage-container-layout-box "
          showDownloadIcon={false}
        >
          <>
            {avgSessionHeaderComp}
            {loading ? (
              <Spinner />
            ) : (
              <DonutPieChart
                showLegends
                data={avgSessionChart}
                colorSchema={colorSchema}
                totalEvents={20}
                innerRadius={0.001}
                enableArcLinkLabels={false}
              />
            )}
          </>
          <>
            {avgSessionHeaderComp}
            {loading ? (
              <Spinner />
            ) : (
              <CustomTable
                dataSource={avgTableData}
                columns={avgSessionLengthColumn}
                pagination={false}
                className="usage-container-table mt-3"
                style={{ marginTop: "35px" }}
              />
            )}
          </>
        </TableChartLayout>
      </div>

      <div>
        <TableChartLayout
          title={t("usageBoxLabelSession")}
          className="col-lg-12 col-md-12 col-12 "
          showDownloadIcon={false}
        >
          <>
            {sessionHeaderComp}
            {loading ? (
              <Spinner />
            ) : (
              <Heatmap
              // HEAT MAP 
                heatMapData={sessionChunkGraphData}
                xInnerPadding={0.005}
                yInnerPadding={0.002}
                top={20}
                right={100}
                bottom={20}
                left={100}
              />
            )}
          </>
          <>
            {sessionHeaderComp}
            {loading ? (
              <Spinner />
            ) : (
              <CustomTable
                dataSource={sessionChunkTableData}
                columns={userTimelySessionColumn}
                pagination={false}
                className="usage-container-table"
                style={{ marginTop: "35px" }}
              />
            )}
          </>
        </TableChartLayout>
      </div>
    </div>
  );
};
export default Usage;
