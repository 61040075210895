import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "services/centralApi";
import ShowMessage from "components/ShowMessage/ShowMessage";
import Spinner from "components/Spinner/Spinner";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import "./ConfigureCampaigns.css";
import { API_ENDPOINTS } from "utils/constants";
import { getToken } from "redux/features/app/authTokenSlice";
import { t } from "i18next";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import { CampaignChannelTypeEnum } from "utils/enums";


interface ConfigureModulesProps {
  currentAppKey: Record<string, any>;
  updateCurrentAppKey: () => void;
}

const ConfigureCampaigns: React.FC<ConfigureModulesProps> = ({
  currentAppKey,
  updateCurrentAppKey,
}) => {
  const [form] = Form.useForm();
  const dispatch:any = useDispatch();
  const [loading, setLoading] = useState(BOOLEAN_OPTIONS.FALSE);
  const activeApp = useSelector((state:any) => state.activeApp.appId)
  const authToken = useSelector((state: any) => state.authToken.token);
  const loginUser = useSelector((state: any) => state.loginUser.data.api_key);
  const channelStatusData = currentAppKey[0]?.channel_status;
  const [channelStatus, setChannelStatus] = useState<Record<string, boolean>>({});
  const [showMessage, setShowMessage] = useState<{
    type: "success" | "error";
    message: string;
    show: boolean;
  }>({ type: "success", message: "", show: BOOLEAN_OPTIONS.FALSE });
  const appId = activeApp
  const api_key = loginUser

  useEffect(() => {
    if (channelStatusData) {
      const initialSettings = Object.entries(CampaignChannelTypeEnum)
        .filter(([key, channel]) => channel !== CampaignChannelTypeEnum.ALL)  // Exclude 'ALL'
        .reduce(
          (acc, [key, channel]) => ({
            ...acc,
            [channel]: channelStatusData[channel] ?? BOOLEAN_OPTIONS.TRUE,  // Default to TRUE if not present
          }),
          {}
        );
    
        setChannelStatus(initialSettings);
    } else {
      const defaultSettings = Object.entries(CampaignChannelTypeEnum)
        .filter(([key, channel]) => channel !== CampaignChannelTypeEnum.ALL)  // Exclude 'ALL'
        .reduce(
          (acc, [key, channel]) => ({
            ...acc,
            [channel]: BOOLEAN_OPTIONS.TRUE,  // Default to TRUE for all other channels
          }),
          {}
        );
        setChannelStatus(defaultSettings);
    }
  }, [currentAppKey]);
  
  

  const handleChange = (moduleKey: string, value: boolean) => {
    setChannelStatus((prev) => ({ ...prev, [moduleKey]: value }));
  };

  const hideMessage = () => {
    setTimeout(() => setShowMessage({ type: "success", message: "", show: false }), 2000);
  };

  const onFormSubmit = async () => {
    const payload = {
        args: JSON.stringify({
          app_id: appId,
          channelStatus
        }),
        api_key: api_key,
        app_id:appId,
        authToken
      }
    const url : string = API_ENDPOINTS.SAVE_CAMPAIGN_SETTINGS_URL
    try {
      setLoading(true);
      await centralApi("POST", url, payload, null);
      setShowMessage({ type: "success", message: "Settings saved successfully.", show: true });
      updateCurrentAppKey();
    } catch {
      setShowMessage({ type: "error", message: "Failed to save settings.", show: true });
    } finally {
      setLoading(false);
      hideMessage();
    }
    dispatch(getToken());
  };

  return (
    <div className="configure-campaigns-container">
    <div className="mb-4">
      <h6 className="configure-campaigns-container-heading">
        {t("configureCampaignHeadingLabel")}
      </h6>
      <p className="configure-campaigns-form-headings">
      {t("configureCampaignsDescLabel")}
      </p>

      <div>
          <div className="  mt-2 align-items-center configure-modules-form ">
              <div className=" col-sm-10 mt-2 align-items-center  ">
               
                {showMessage.show && (
                  <ShowMessage
                    type={showMessage.type}
                    content={showMessage.message}
                  />
                )}
                {loading ? (
                  <Spinner />
                ) : (
                    <Form form={form} layout="vertical" onFinish={onFormSubmit} >
                   {Object.entries(CampaignChannelTypeEnum)
                    .filter(([key, channel]) => channel !== CampaignChannelTypeEnum.ALL) // Exclude 'ALL' value
                    .map(([key, channel]) => (
                      <Form.Item key={key}>
                        <CustomCheckbox
                          label={channel}
                          checked={channelStatus[channel]}
                          onChange={(e: any) => handleChange(channel, e.target.checked)}
                        />
                      </Form.Item>
                    ))}
                     <div className="mt-2">
                      <PrimaryButton
                         size="middle"
                        type="primary"
                        htmlType="submit"
                        style={{ border: "none", width: "70px" }}
                      >
                         {t("saveLabel")}
                      </PrimaryButton>           
                    </div> 
                    </Form>
                )}
              </div>
          </div>
        </div>
     </div>
    </div>
  );
};

export default ConfigureCampaigns;

