import CustomAvatar from "components/CustomAvatar/CustomAvatar";
import { FC, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import "./Customer360.css";
import ThreeSixtyCard from "./components/Customer360Card/Customer360Card";
import { t } from "i18next";
import { CONSTANTS } from "utils/constants/constants";
import { useLocation, useParams } from "react-router-dom";
import centralApi from "services/centralApi";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { API_ENDPOINTS } from "utils/constants";
import UserInteractions from "./components/UsersInteractions/UsersInteractions";
import {UserProfile} from '../../utils/models/index'
import Selector from "components/Selector/Selector";
import CustomisedProfile from "./components/CustomisedProfile/CustomisedProfile";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import Spinner from "components/Spinner/Spinner";

const Customer360: FC = () => {
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [userDetails, setUserDetails] = useState<any>();
  const [userInfo , setUserInfo] = useState<any>();
  const [loading, setLoading] = useState(BOOLEAN_OPTIONS.FALSE);
  const [activeCard , setActiveCard] = useState<{cardNumber: string; cardDetails: any}>({
    cardNumber : "" ,
    cardDetails : {}
})
const [activeOffer , setActiveOffer] = useState<{cardNumber: string; cardDetails: any}>({
  cardNumber : "" ,
  cardDetails : {}
})
const [activeTermDepositsAccounts , setActiveTermDepositsAccounts] = useState<{cardNumber: string; cardDetails: any}>({
  cardNumber : "" ,
  cardDetails : {}
})
const [activeCASAAccounts, setActiveCASAAccounts] = useState<{cardNumber: string; cardDetails: any}>({
  cardNumber : "" ,
  cardDetails : {}
})
const [activeApplications, setActiveApplications] = useState<{cardNumber: string; cardDetails: any}>({
  cardNumber : "" ,
  cardDetails : {}
})
const [cardTransactions , setCardTransactions] = useState<any>();
const [cardPayments, setCardPayments] = useState<any[]>([]);
const [accountTransactions , setAccountTransactions] = useState<any>();



  const params = useParams(); // This will get the ID from the URL path
  const location = useLocation();
  const { id } = useParams();
  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  
  //fetch user details from api
  const fetchAppUserDetails = async () => {
    let payload ;
    //checks if customer id is present in params it will send cci in payload for search
    if(type === 'ci'){
      payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        ci: id,
      };
    }
    //if customer id is not present in parasm it will sedn did as a payload for search
    else{
      payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        did: id,
      };
    }   
    setLoading(BOOLEAN_OPTIONS.TRUE);
    const response  : UserProfile = await centralApi(
      "GET",
      API_ENDPOINTS.GET_USER_DETAILS_URL,
      null,
      payload
    );
    if(response){

      setUserDetails(response);
    }
    setLoading(BOOLEAN_OPTIONS.FALSE);
     

  };
 
 
useEffect(() => {
  if(userDetails){
     // Process cProfile data
     const { filteredObject: filteredCProfile,nullObject: nullCProfile } = processObject(
      userDetails?._custom || {}, // Ensure cProfile exists
    );

    
      // Process Cards data if it exists
      const cardOptions = Object.keys(userDetails?.Cards || {}).map((cardNumber) => ({
        value: cardNumber,
        label: cardNumber,
      }));
      
      const offersOptions = Object.keys(userDetails?.Offers || {}).map((cardNumber) => ({
        value: cardNumber,
        label: cardNumber,
      }));

      const termDepositsAccountsOptions = Object.keys(userDetails?.TermDepositsAccounts || {}).map((cardNumber) => ({
        value: cardNumber,
        label: cardNumber,
      }));

      const CASAAccountsOptions = Object.keys(userDetails?.CASAAccounts || {}).map((cardNumber) => ({
        value: cardNumber,
        label: cardNumber,
      }));

      const applicationsOptions = Object.keys(userDetails?.Applications || {}).map((cardNumber) => ({
        value: cardNumber,
        label: cardNumber,
      }));
      // Process cardTransactionOptions if it exists
      const cardTransactionOptions = Object.keys(userDetails?.CardTransactions || {}).map(cardNumber => ({
        value: cardNumber,
        label: cardNumber,
      }));

      const accountTransactionsOptions = Object.keys(userDetails?.AccountTransactions || {}).map(cardNumber => ({
        value: cardNumber,
        label: cardNumber,
      }));



      // Process cardPaymentOptions if it exists
      const cardPaymentOptions = Object.keys(userDetails?.CardPayments || {}).map(cardNumber => ({
        value: cardNumber,
        label: cardNumber,
      }));



      const userProfileKeys = Object.keys(userDetails);

  
    setUserInfo({
      cifId : userDetails?.userId ,
      name: userDetails?.name || "NA",
      icon : getInitials(userDetails?.name ) || "NA",
      gender: userDetails?.gender || "NA",
      isMarried : userDetails?.isMarried || "NA",
      dob : userDetails?.dob || "NA",
      email : userDetails?.email || "NA",
      phone : userDetails?.phone || "NA",
      nationality : userDetails?.nationality || "NA",
      filteredCProfile,
      cardOptions,
      userProfileKeys,
      cardTransactionOptions,
      cardPaymentOptions,
      accountTransactionsOptions,
      offersOptions,
      termDepositsAccountsOptions,
      CASAAccountsOptions,
      applicationsOptions

    });
  }
}, [userDetails]);

useEffect(() => {
  if (loginUser.api_key && appId) {
    fetchAppUserDetails();
  }
}, [loginUser.api_key, appId]);



      /**
     * Generates an abbreviation from the full name based on the initials.
     * @param fullName - The full name from which to generate the abbreviation.
     * @returns A string with the initials of the name parts.
     */
    function getInitials(fullName: string | undefined): string {
      // Check if fullName is undefined, null, or an empty string
      if (!fullName || typeof fullName !== 'string' || fullName.trim() === '') {
        return ''; // Return an empty string if the input is invalid
      }
      // Split the full name into parts
      const nameParts = fullName.split(' ');

      // Get the first letter of each part and join them
      const initials = nameParts
        .filter(part => part.length > 0) // Ensure the part is not empty
        .map(part => part.charAt(0).toUpperCase()) // Get the first character and convert to uppercase
        .join('');

      return initials;
    }

      const cardFilteredDetails = useMemo(() => {
        if (activeCard?.cardNumber && activeCard.cardDetails) {
          const detailEntries = Object.entries(activeCard.cardDetails)
            .filter(([key, value]) => value !== null && value !== "")
            .map(([key, value]) => ({
              label: key, 
              value,
            }));
      
          // Divide the details into three chunks
          const chunkSize = Math.ceil(detailEntries.length / 3);
          return [
            detailEntries.slice(0, chunkSize),
            detailEntries.slice(chunkSize, chunkSize * 2),
            detailEntries.slice(chunkSize * 2),
          ];
        }
        return [];
      }, [activeCard]);

      const offerFilteredDetails = useMemo(() => {
        if (activeOffer?.cardNumber && activeOffer.cardDetails) {
          const detailEntries = Object.entries(activeOffer.cardDetails)
            .filter(([key, value]) => value !== null && value !== "")
            .map(([key, value]) => ({
              label: key, 
              value,
            }));
      
          // Divide the details into three chunks
          const chunkSize = Math.ceil(detailEntries.length / 3);
          return [
            detailEntries.slice(0, chunkSize),
            detailEntries.slice(chunkSize, chunkSize * 2),
            detailEntries.slice(chunkSize * 2),
          ];
        }
        return [];
      }, [activeOffer]);

      const applicationsFilteredDetails = useMemo(() => {
        if (activeApplications?.cardNumber && activeApplications.cardDetails) {
          const detailEntries = Object.entries(activeApplications.cardDetails)
            .filter(([key, value]) => value !== null && value !== "")
            .map(([key, value]) => ({
              label: key, 
              value,
            }));
      
          // Divide the details into three chunks
          const chunkSize = Math.ceil(detailEntries.length / 3);
          return [
            detailEntries.slice(0, chunkSize),
            detailEntries.slice(chunkSize, chunkSize * 2),
            detailEntries.slice(chunkSize * 2),
          ];
        }
        return [];
      }, [activeApplications]);

      const CASAAccountsFilteredDetails = useMemo(() => {
        if (activeCASAAccounts?.cardNumber && activeCASAAccounts.cardDetails) {
          const detailEntries = Object.entries(activeCASAAccounts.cardDetails)
            .filter(([key, value]) => value !== null && value !== "")
            .map(([key, value]) => ({
              label: key, 
              value,
            }));
      
          // Divide the details into three chunks
          const chunkSize = Math.ceil(detailEntries.length / 3);
          return [
            detailEntries.slice(0, chunkSize),
            detailEntries.slice(chunkSize, chunkSize * 2),
            detailEntries.slice(chunkSize * 2),
          ];
        }
        return [];
      }, [activeCASAAccounts]);

      const termDepositsAccountsFilteredDetails = useMemo(() => {
        if (activeTermDepositsAccounts?.cardNumber && activeTermDepositsAccounts.cardDetails) {
          const detailEntries = Object.entries(activeTermDepositsAccounts.cardDetails)
            .filter(([key, value]) => value !== null && value !== "")
            .map(([key, value]) => ({
              label: key, 
              value,
            }));
      
          // Divide the details into three chunks
          const chunkSize = Math.ceil(detailEntries.length / 3);
          return [
            detailEntries.slice(0, chunkSize),
            detailEntries.slice(chunkSize, chunkSize * 2),
            detailEntries.slice(chunkSize * 2),
          ];
        }
        return [];
      }, [activeTermDepositsAccounts]);

      const filteredCardTransactions = useMemo(() => {
        if (cardTransactions?.length > 0) {
          return cardTransactions.map((transaction: any) => {
            // Convert each transaction object into an array of label-value pairs
            const transactionEntries = Object.entries(transaction).map(([key, value]) => ({
              label: key, 
              value: value === "" ? "NA" : value ?? "NA", // Ensure there's a fallback for undefined values
            }));
            return [
              transactionEntries
            ];
          });
        }
        return [];
      }, [cardTransactions]);

       // Memoized Card Payments
  const filteredCardPayments = useMemo(() => {
    if (cardPayments?.length > 0) {
      return cardPayments.map((payment: any) => {
        const paymentEntries = Object.entries(payment).map(([key, value]) => ({
          label: key,
          value: value === "" ? "NA" : value ?? "NA", // Handle empty string or null
        }));
        return [paymentEntries];
        
      });
        
    }
    return [];
  }, [cardPayments]);

  const filteredAccountTransactions = useMemo(() => {
    if (accountTransactions?.length > 0) {
      return accountTransactions.map((payment: any) => {
        const paymentEntries = Object.entries(payment).map(([key, value]) => ({
          label: key,
          value: value === "" ? "NA" : value ?? "NA", // Handle empty string or null
        }));
        return [paymentEntries];
        
      });
        
    }
    return [];
  }, [accountTransactions]);
      

    function processObject(
      obj: Record<string, any>,
    ): {
      filteredObject: Record<string, any>;
      nullObject: Record<string, any>;
    } {
      const filteredObject: Record<string, any> = {};
      const extractedObject: Record<string, any> = {};
      const nullObject: Record<string, any> = {};
    
      // Iterate through the object
      for (const [key, value] of Object.entries(obj)) {
        if (value === null || value === "null" || value === "N/A") {
          nullObject[key] = value;
        }  else {
         
         const label = key
        .replace(/([A-Z])/g, " $1") // Add spaces before uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter

        if (key === "createdDate" || key === "updateDate") {
          // Format epoch timestamps
          filteredObject[label] = dayjs(new Date(value)).format("DD-MM-YYYY");
        } else if (
          key === "kycExpiryDate" ||
          key === "dateOfJoining"
        ) {
          filteredObject[label] = dayjs(new Date(value)).format("DD-MM-YYYY");
        }
        else{
          // Assign the value to the transformed key in filteredObject
         filteredObject[label] = value;
        }
        }
      }
    
      return { filteredObject,  nullObject };
    }

  return (
    <div className="c-three-sixty-main-container">
      <Helmet>
        <title>Appice | C360</title>
      </Helmet>
      {loading  ? (
      <div className="d-flex justify-content-center align-items-center c-three-sixty-spinner">
        <Spinner /> 
      </div>) 
        : (
        <>
      {/* -----header container starts from here----- */}
      <div className="d-flex justify-content-between my-3">
        <div className="d-flex gap-lg-3 gap-2  c-three-sixty-header-container ">
          <div>
            <CustomAvatar
              className="c-three-sixty-header-avatar"
              shape="square"
              size={100}
            >
              {userInfo?.icon}
            </CustomAvatar>
          </div>
          <div className="d-flex justify-space-between  flex-column py-2 gap-3">
          <h6>{userInfo?.name}</h6>

            {/* First row with CIF ID */}
            <div className="d-flex justify-content-between">
            <span className="me-3">{t("cifIdLabel")}</span>
            <span className="text-dark">{userInfo?.cifId}</span>
          </div>

    
          </div>

        </div>
        <div className="d-flex w-50 justify-content-between">
          <ThreeSixtyCard
            cardType={CONSTANTS.HEADER}
            firstKey={"Gender"}
            firstValue={userInfo?.gender}
            secondKey={"Marital Status"}
            secondValue={userInfo?.isMarried}
            thirdKey={"DOB"}
            thirdValue={userInfo?.dob}
          />
          <ThreeSixtyCard
            cardType={CONSTANTS.HEADER}
            firstKey={"Email"}
            firstValue={userInfo?.email}
            secondKey={"Mobile"}
            secondValue={userInfo?.phone}
            thirdKey={"Nationality"}
            thirdValue={userInfo?.nationality}
          />
        </div>
        
      </div>

    { /**User info starts from here  */}
     {userInfo?.userProfileKeys.includes("_custom")  &&  
        Object.keys(userDetails._custom || {}).length > 0  && (
    <>
     <div className="d-flex gap-lg-3 my-2 gap-2  border-bottom ">
      <h6 className="m-2">Profile</h6>
      </div>

      <CustomisedProfile  labelStyle={{ fontWeight: "bold"}}  data={userInfo?.filteredCProfile} />
    </>
    )}
    { /**user info ends here  */}
     
     { /**CARDS overview starts from here  */}
     {userInfo?.userProfileKeys.includes("Cards")  && 
     Object.keys(userDetails?.Cards || {}).length > 0  && (
    <>
     <div className="d-flex gap-lg-3 mt-2 gap-2  border-bottom ">
      
        <h6 className="m-2">{t("cardsLabel")}</h6>
     
     </div>
     <div className="my-2">
      <Selector
        className="input-size-md"
        placeholder={"Select card number"}
        options={userInfo?.cardOptions}
        onChange={(value: string)=>{
          setActiveCard({
            cardNumber : value ,
            cardDetails : userDetails?.Cards[value as keyof typeof userDetails.Cards]
        })
        }}
        />
     </div>
       <ThreeSixtyCard
            cardType={CONSTANTS.CARDS}
            cardFilteredDetails={cardFilteredDetails}
            
        />
     </>
    )}

 {userInfo?.userProfileKeys.includes("Offers")  && 
     Object.keys(userDetails?.Offers || {}).length > 0  && (
    <>
     <div className="d-flex gap-lg-3 mt-2 gap-2  border-bottom ">
      
        <h6 className="m-2">{"Offers"}</h6>
     
     </div>
     <div className="my-2">
      <Selector
        className="input-size-md"
        placeholder={"Select customer identifier"}
        options={userInfo?.offersOptions}
        onChange={(value: string)=>{
          setActiveOffer({
            cardNumber : value ,
            cardDetails : userDetails?.Offers[value as keyof typeof userDetails.Offers]
        })
        }}
        />
     </div>
       <ThreeSixtyCard
            cardType={CONSTANTS.CARDS}
            cardFilteredDetails={offerFilteredDetails}
            
        />
     </>
    )}

{userInfo?.userProfileKeys.includes("TermDepositsAccounts")  && 
     Object.keys(userDetails?.TermDepositsAccounts || {}).length > 0  && (
    <>
     <div className="d-flex gap-lg-3 mt-2 gap-2  border-bottom ">
      
        <h6 className="m-2">{"Term Deposits Accounts"}</h6>
     
     </div>
     <div className="my-2">
      <Selector
        className="input-size-md"
        placeholder={"Select account number"}
        options={userInfo?.termDepositsAccountsOptions}
        onChange={(value: string)=>{
          setActiveTermDepositsAccounts({
            cardNumber : value ,
            cardDetails : userDetails?.TermDepositsAccounts[value as keyof typeof userDetails.TermDepositsAccounts]
        })
        }}
        />
     </div>
       <ThreeSixtyCard
            cardType={CONSTANTS.CARDS}
            cardFilteredDetails={termDepositsAccountsFilteredDetails}
            
        />
     </>
    )}

{userInfo?.userProfileKeys.includes("CASAAccounts")  && 
     Object.keys(userDetails?.CASAAccounts || {}).length > 0  && (
    <>
     <div className="d-flex gap-lg-3 mt-2 gap-2  border-bottom ">
      
        <h6 className="m-2">{"CASA Accounts"}</h6>
     
     </div>
     <div className="my-2">
      <Selector
        className="input-size-md"
        placeholder={"Select account number"}
        options={userInfo?.CASAAccountsOptions}
        onChange={(value: string)=>{
          setActiveCASAAccounts({
            cardNumber : value ,
            cardDetails : userDetails?.CASAAccounts[value as keyof typeof userDetails.CASAAccounts]
        })
        }}
        />
     </div>
       <ThreeSixtyCard
            cardType={CONSTANTS.CARDS}
            cardFilteredDetails={CASAAccountsFilteredDetails}
            
        />
     </>
    )}

{userInfo?.userProfileKeys.includes("Applications")  && 
     Object.keys(userDetails?.Applications || {}).length > 0  && (
    <>
     <div className="d-flex gap-lg-3 mt-2 gap-2  border-bottom ">
      
        <h6 className="m-2">{"Applications"}</h6>
     
     </div>
     <div className="my-2">
      <Selector
        className="input-size-md"
        placeholder={"Select application type"}
        options={userInfo?.applicationsOptions}
        onChange={(value: string)=>{
          setActiveApplications({
            cardNumber : value ,
            cardDetails : userDetails?.Applications[value as keyof typeof userDetails.Applications]
        })
        }}
        />
     </div>
       <ThreeSixtyCard
            cardType={CONSTANTS.CARDS}
            cardFilteredDetails={applicationsFilteredDetails}
            
        />
     </>
    )}


    { /**CARDS overview ends here  */}
    <div className=" d-flex gap-2 mt-2 border-top">

      {/* Card Transactions Section */}
      {userInfo?.userProfileKeys.includes("CardTransactions")  && 
      Object.keys(userDetails?.CardTransactions || {}).length > 0  && (
        <div className="cards-overview-section" >
          <div className="d-flex gap-lg-3 gap-2 border-bottom">
            <h6 className="m-2">{t("cardTransactionsLabel")}</h6>
          </div>
          <div className="my-2">
            <Selector
              className="input-size-md"
              placeholder={"Select card number"}
              options={userInfo.cardTransactionOptions}
              onChange={(value: string) => {
                const selectedCardTransactions = userDetails?.CardTransactions[value];
                setCardTransactions(selectedCardTransactions || []);
              }}
            />
          </div>
          <ThreeSixtyCard
            cardType={CONSTANTS.OFFER}
            filteredCardTransactions={filteredCardTransactions}
          />
        </div>
      )}

      {/* Card Payments Section */}
      {userInfo?.userProfileKeys.includes("CardPayments")  && 
      Object.keys(userDetails?.CardPayments || {}).length > 0  && (
        <div className="cards-overview-section ">
          <div className="d-flex gap-lg-3 gap-2 border-bottom">
            <h6 className="m-2">{t("cardPaymentsLabel")}</h6>
          </div>
          <div className="my-2">
            <Selector
              className="input-size-md"
              placeholder={"Select card number"}
              options={userInfo.cardPaymentOptions}
              onChange={(value: string) => {
                const selectedCardPayments = userDetails?.CardPayments[value];
                setCardPayments(selectedCardPayments || []);
              }}
            />
          </div>
          <ThreeSixtyCard
            cardType={CONSTANTS.OFFER}
            filteredCardTransactions={filteredCardPayments}
          />
        </div>
      )}

        {/* Card Payments Section */}
        {userInfo?.userProfileKeys.includes("AccountTransactions")  && 
      Object.keys(userDetails?.AccountTransactions || {}).length > 0  && (
        <div className="cards-overview-section ">
          <div className="d-flex gap-lg-3 gap-2 border-bottom">
            <h6 className="m-2">{"Account Transactions"}</h6>
          </div>
          <div className="my-2">
            <Selector
              className="input-size-md"
              placeholder={"Select account number"}
              options={userInfo.accountTransactionsOptions}
              onChange={(value: string) => {
                const selectedCardAccounts = userDetails?.AccountTransactions[value];
                console.log(selectedCardAccounts)
                setAccountTransactions(selectedCardAccounts || []);
              }}
            />
          </div>
          <ThreeSixtyCard
            cardType={CONSTANTS.OFFER}
            filteredCardTransactions={filteredAccountTransactions}
          />
        </div>
      )}

    </div>


      {/*-----------User/Interaction component starts from here*/}

   {userInfo?.userProfileKeys.includes("eventHistory")  && 
     Object.keys(userDetails?.eventHistory || {}).length > 0  && (
    <div>
    <UserInteractions eventHistory={userDetails?.eventHistory } />
    </div>
      )}
      </>
      )} 
    </div>
   
    
  );
};

export default Customer360;
