import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "services/centralApi";
import ShowMessage from "components/ShowMessage/ShowMessage";
import Spinner from "components/Spinner/Spinner";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import "./ConfigureColumns.css";
import { API_ENDPOINTS } from "utils/constants";
import { getToken } from "redux/features/app/authTokenSlice";
import { t } from "i18next";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import {AuditColumns} from "../../../../../utils/constants/index";
import {AppSettings} from "../../../../../utils/models/index";
import { deserialize, serialize } from "utils/models/serializer";



interface ConfigureColumnsProps {
  currentAppKey: Record<string, any>;
  updateCurrentAppKey: () => void;
}

const ConfigureColumns: React.FC<ConfigureColumnsProps> = ({
  currentAppKey,
  updateCurrentAppKey,
}) => {
  const [form] = Form.useForm();
  const dispatch:any = useDispatch();
  const activeApp = useSelector((state:any) => state.activeApp.appId)
  const authToken = useSelector((state: any) => state.authToken.token);
  const loginUser = useSelector((state: any) => state.loginUser.data.api_key);
  const [loading, setLoading] = useState(BOOLEAN_OPTIONS.FALSE);
  const columnsConfigData = currentAppKey[0]?.auditLogsConfig?.columnsConfig
  const [columnsConfig, setColumnsConfig] = useState<Record<string, boolean>>({});
  const [showMessage, setShowMessage] = useState<{
    type: "success" | "error";
    message: string;
    show: boolean;
  }>({ type: "success", message: "", show: BOOLEAN_OPTIONS.FALSE });
  const appId = activeApp
  const api_key = loginUser

  useEffect(() => {

    if (columnsConfigData) {
      const initialSettings = Object.values(AuditColumns).reduce(
        (acc, column) => ({
          ...acc,
          [column.value]: columnsConfigData[column.value] ?? BOOLEAN_OPTIONS.TRUE,
        }),
        {}
      );
      setColumnsConfig(initialSettings);
    }

    else{
        const defaultSettings = Object.values(AuditColumns).reduce(
            (acc, column) => ({
              ...acc,
              [column.value]: BOOLEAN_OPTIONS.TRUE,
            }),
            {}
          );
          setColumnsConfig(defaultSettings);
    }
  }, [currentAppKey]);
  
  

  const handleChange = (columnKey: string, value: boolean) => {
    setColumnsConfig((prev) => ({ ...prev, [columnKey]: value }));
  };

  const hideMessage = () => {
    setTimeout(() => setShowMessage({ type: "success", message: "", show: false }), 2000);
  };

  const onFormSubmit = async () => {
    const auditLogsConfig = {columnsConfig}
    // Serializing and deserializing AuditLogs Config Payload and appsettings model class
    const deserializedData = deserialize({auditLogsConfig}, AppSettings);
    const serializedData = serialize(deserializedData as AppSettings);
    const payload = {
        args: JSON.stringify({
          app_id: appId,
          ...serializedData
        }),
        api_key: api_key,
        app_id:appId,
        authToken
      }
    const url : string = API_ENDPOINTS.UPDATE_AUDIT_CONFIG
    try {
      setLoading(true);
      await centralApi("POST", url, payload, null);
      setShowMessage({ type: "success", message: "Settings saved successfully.", show: true });
      updateCurrentAppKey();
    } catch {
      setShowMessage({ type: "error", message: "Failed to save settings.", show: true });
    } finally {
      setLoading(false);
      hideMessage();
    }
    dispatch(getToken());
  };

  return (
    <div className="configure-columns-container">
      <div className="mb-4">
        <h6 className="configure-columns-container-heading">{t("columnsHeadingLabel")}</h6>
        <p className="configure-columns-form-headings">{t("columnsDescLabel")}</p>

        <div>
          <div className="mt-2 align-items-center configure-columns-form">
            <div className="col-sm-10 mt-2 align-items-center">
              {showMessage.show && (
                <ShowMessage
                  type={showMessage.type}
                  content={showMessage.message}
                />
              )}
              {loading ? (
                <Spinner />
              ) : (
                <Form form={form} layout="vertical" onFinish={onFormSubmit}>
                  {Object.entries(AuditColumns).map(([key, column]) => (
                    <Form.Item key={key}>
                      <CustomCheckbox
                        label={column.name}
                        checked={columnsConfig[column.value]}
                        disabled={column.value === "created_at"}
                        onChange={(e: any) => handleChange(column.value, e.target.checked)}
                      />
                    </Form.Item>
                  ))}
                  <div className="mt-2">
                    <PrimaryButton
                      size="middle"
                      type="primary"
                      htmlType="submit"
                      style={{ border: "none", width: "70px" }}
                    >
                      {t("saveLabel")}
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureColumns;
