import React from "react";
import  AnyChart from "anychart-react";
import anychart from "anychart";
import "anychart/dist/css/anychart-ui.min.css";
import NoData from "../../NoData/NoData";
type propTypes = {
  journeyData?: any;
  onTableData?: any;
  openModal?: any;
  showTable?: any;
  contextMenu?: any;
  isTableVisible?: any;
  height? : number ;
};
const SunburstChart = ({
  journeyData,
  onTableData,
  openModal,
  showTable,
  isTableVisible,
  height
}: propTypes) => {
  const chart = anychart.sunburst(journeyData);
  anychart.licenseKey('semusi.com-df30754a-aa10847e');
chart.credits(false)
  chart.labels().enabled(true);
  chart.labels().position("circular");
  chart.labels().useHtml(true);
  // Enable HTML rendering for labels

  chart.labels().format("{%name}\n Count:{%value}");
  chart.tooltip().format("{%name}\ncount:{%value}\n{%percentage}");

  chart.contextMenu(false);

  const customContextMenu = anychart.ui.contextMenu();
  customContextMenu.attach(chart);

  customContextMenu.itemsProvider(function () {
    const menuItems = [
      {
        text: "Create Funnel",
        eventType: "createFunnel",
      },
    ];

    return isTableVisible ? menuItems : []; // Show the menu only if showTable is true
  });
  customContextMenu.listen("createFunnel", function () {
    openModal();
    return true; // Indicate successful handling of the event
  });

  chart.listen("pointMouseMove", function () {
    showTable(true);
  });
  chart.listen("pointMouseOut", function () {
    showTable(true);
  });

  chart.listen("pointsHover", function (e: any) {
    const pointArray = [];
    if (e.point.node) {
      let item = e.point.node;
      while (item !== null) {
        pointArray.push(item?.la);
        item = item.getParent();
      }
      onTableData(pointArray.reverse());
    }   
  });
  return journeyData?.length == null ? (
    <NoData />
  ) : (
    <AnyChart
      credit={false}
      instance={chart}
      height={height}
      width={800}
    />
  );
};

export default React.memo(SunburstChart);
