import React, { useState, useEffect } from "react";
import { formatDateToReadable } from "helperFunctions/date";
import { t } from "i18next";
import CustomHighChart from "components/Charts/CustomHighChart/CustomHighChart";

// Define the structure of the chart data for type safety
interface ChartData {
  dates: string[];
  pushed: number[];
  clicked: number[];
  reach: number[];
  received: number[];
  ctr: any[];
  conversion: number[];
}

// Component for displaying campaign performance data in a chart
const CampaignPerformance: React.FC<any> = ({ data }) => {

  // State to manage the processed data to be displayed in the chart
  const [chartData, setChartData] = useState<ChartData>({
    dates: [],
    pushed: [],
    clicked: [],
    reach: [],
    received: [],
    ctr: [],
    conversion: [],
  });

  // Function to aggregate campaign data date-wise across all platforms
  function sumCampaignDateWise(data: any) {
    const result: any = {};
    for (const platform in data) {
      const platformData = data[platform];
      for (const date in platformData) {
        if (!result[date]) {
          result[date] = {};
        }
        for (const key in platformData[date]) {
          if (!result[date][key]) {
            result[date][key] = 0;
          }
          // Add values if they are not null
          if (platformData[date]?.[key] !== null) {
            result[date][key] += platformData[date][key];
          }
        }
      }
    }
    return result;
  }

  // Function to sort the aggregated data by date
  function sortByDate(obj: any) {
    const sortedKeys = Object.keys(obj).sort((a, b) => {
      const dateA = new Date(a.replace(/_/g, "-"));
      const dateB = new Date(b.replace(/_/g, "-"));
      return dateA.getTime() - dateB.getTime();
    });

    const sortedData: any = {};
    sortedKeys.forEach((key) => {
      sortedData[key] = obj[key];
    });

    return sortedData;
  }

  // Effect to process the input data whenever it changes
  useEffect(() => {
    if (data) {
      // Step 1: Aggregate data date-wise
      const summedData = sumCampaignDateWise(data);
      // Step 2: Sort the aggregated data by date
      const sortedData = sortByDate(summedData);

      // Step 3: Extract and organize data for chart plotting
      const dates: string[] = [];
      const pushed: number[] = [];
      const clicked: number[] = [];
      const reach: number[] = [];
      const received: number[] = [];
      const ctr: any[] = [];
      const conversion: number[] = [];

      Object.keys(sortedData).forEach((date) => {
        // Format date for readability
        dates.push(formatDateToReadable(date));
        const metrics = sortedData[date];

        // Populate chart data arrays with metric values
        pushed.push(metrics.Campaign_PushedTo || 0);
        clicked.push(metrics.Campaign_ClickedTo || 0);
        reach.push(metrics.Campaign_Reach || 0);
        received.push(metrics.Campaign_ReceivedTo || 0);
        conversion.push(metrics.Campaign_Conversion || 0);

        // Calculate CTR (Click-Through Rate) if data is available
        const ctrValue =
          metrics.Campaign_ClickedTo && metrics.Campaign_PushedTo
            ? parseFloat(
                ((metrics.Campaign_ClickedTo / metrics.Campaign_PushedTo) * 100).toFixed(2)
              )
            : "";
        ctr.push(ctrValue);
      });

      // Update the chartData state with the processed data
      setChartData({
        dates,
        pushed,
        clicked,
        reach,
        received,
        ctr,
        conversion,
      });
    }
  }, [data]);

  const xAxisDataLength = chartData?.dates?.length > 7

  // Configuration options for the HighChart component
  const options = {
    chart: {
      type: 'column',
      scrollablePlotArea: {
        minWidth:xAxisDataLength  ? 2000 : 1000,  // Conditionally set minWidth
        scrollPositionX: 1  // Position the scrollbar to the right
      }
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: chartData.dates,
      scrollbar: {
        enabled: true,  // Enable x-axis scrollbar
      },
    },
    yAxis: [
      {
        title: {
          text: "CTR %", // Y-axis title for CTR
        },
        opposite: false, // Display on the left side
      },
      {
        title: {
          text: "Reach", // Y-axis title for metrics
        },
        opposite: true, // Display on the right side
      },
    ],
    plotOptions: {
      column: {
        pointPadding: xAxisDataLength ? 0 : 0.3,
        groupPadding: xAxisDataLength ? 0 : 0.3,  
        pointWidth: 17,
      },
    },
    series: [
      {
        name: t("clickedLabel"), // Series name for clicked data
        data: chartData.clicked, // Data for clicked series
      },
      {
        name: t("sentLabel"), // Series name for pushed data
        data: chartData.pushed, // Data for pushed series
      },
      {
        name: t("reachLabel"), // Series name for reach data
        data: chartData.reach, // Data for reach series
      },
      {
        name: t("receivedLabel"), // Series name for received data
        data: chartData.received, // Data for received series
      },
      {
        name: t("conversionLabel"), // Series name for conversion data
        data: chartData.conversion, // Data for conversion series
      },
      {
        name: t("ctrLabel"), // Series name for CTR data
        type: "spline", // Line chart for CTR
        data: chartData.ctr, // Data for CTR series
        yAxis: 0, // Use first Y-axis for CTR
      },
    ].filter(item => item.data.some(value => value !== 0 && value !== "")),
  };

  // Render the custom HighChart component with the prepared options
  return <CustomHighChart options={options} />;
};

export default CampaignPerformance;
