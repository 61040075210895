import deleteIcon from "images/trashpink.svg";
import editIcon from "images/_edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import CustomTable from "components/CustomTable/CustomTable";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import "./DataTransformation.css";
import React, { useEffect, useRef, useState } from "react";
import useMessage from "hooks/useMessage";
import centralApi from "services/centralApi";
import TextInput from "components/Inputs/TextInput/TextInput";
import { Space } from "antd";
import ConfirmPopUp from "components/PopUp/ConfirmPopUp/ConfirmPopUp";
import { API_ENDPOINTS } from "utils/constants";
import Spinner from "components/Spinner/Spinner";
import { removeUnderscoreAddSpace } from "helperFunctions/common";
import { getToken } from "redux/features/app/authTokenSlice";
import AddRow from "pages/DataManagement/components/AddRow";

const DataTransformation: React.FC = () => {
  const { showError, showSuccess } = useMessage();
  const dispatch: any = useDispatch();
  const { appId } = useSelector((state: any) => state.activeApp);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const authToken = useSelector((state: any) => state.authToken.token);
  
  const [addRow, setAddRow] = useState({
    open: false,
  });
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const tableName = `customer360mapping_${appId}`; 
  const [rowData, setRowData] = useState([]);

  const [column, setColumns] = useState<any[]>([]);

  async function getMappingList(payload: {
    app_id: string;
    api_key: string;
    tableName: string;
  }) {
    try {
      setLoading(true);
      const res = await centralApi(
        "GET",
        API_ENDPOINTS.GET_MAPPING_LIST,
        null,
        payload
      );
      setTableData(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (loginUser?.api_key) {
      getMappingList({
        app_id: appId,
        api_key: loginUser.api_key,
        tableName,
      });
    }
  }, [loginUser?.api_key]);

  async function updateMappingRowById(formData: any) {
    const payload = {
      args: JSON.stringify(formData),
      tableName, 
      authToken,
      app_id: appId,
      api_key: loginUser?.api_key,
      key: "id",
    };

    try {
      setLoading(true);
      await centralApi(
        "POST",
        API_ENDPOINTS.UPDATE_MAPPING_ROW_BY_ID,
        payload,
        null
      );
      showSuccess(t("sucessfullyUpdatedLabel"));
      dispatch(getToken());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(getToken());
      showError(t("somethingWrongLabel"));
    }
  }


  const handleActionChange = (key: string, index: number, value: string) => {
    // Create a copy of the current table data to avoid mutating state directly

  const data: any = [...tableData];
    // Update the specific row at the given index with the new value for the specified key

  data[index] = { ...data[index], [key]: value };
    // Update the table data state with the modified array

  setTableData(data);
};
//Creates a render function for a TextInput component that can be used in a table.

//common text input for editing
const createRenderTextInput = (dataIndex: string, fontSize:number ) => {
  const RenderTextInputComponent = (_: any, record: any, index: number) => (

    <TextInput
          // Set the value of the input, defaulting to an empty string if record or dataIndex is undefined
      value={record?.[dataIndex] ?? ''}
      style={{ fontSize }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            // Call handleActionChange when the input value changes, passing the dataIndex, row index, and new value

        handleActionChange(dataIndex, index, e.target.value);
      }}
    />
  );
  // Set a display name for the component
  RenderTextInputComponent.displayName = `RenderTextInput(${dataIndex})`;
  // Return the render function

  return RenderTextInputComponent;
};

  const getColumnTitle = (key: string) => {
    const specialTitles: Record<string, string> = {
      destination_col_key: "Destination column root key",
    };

    return specialTitles[key] || removeUnderscoreAddSpace(key);
  };

  const generateColumns = (data:any) => {
    // Get all the keys from the first item in the array
    const excludeKeys = ['id', 'description'];  // Keys to exclude
    const keys = Object?.keys(data[0]).filter(key => !excludeKeys.includes(key));
  
    //adding  font size and width for some rows
      const columnStyles: Record<string, {  width: number }> = {
  
      source_table: {  width: 130 },
  
      source_col: {  width: 320 },
      file_table: {  width: 130 },
  
      source_fields: {  width: 320 },
      destination_col:{width:140},
      display_name: {  width: 320 },  
      destination_col_child_key: {  width: 100 },
      };
    // Map the keys into column definitions
    return keys.map(key => ({
      title: getColumnTitle(key),   // Get title for the column
      dataIndex: key,         // Data index for the column
      key: key,               // Key for the column
      width: columnStyles[key]?.width || 130,  //  width or default to 130
      render: createRenderTextInput(key, 12),  // render text inputs
  
    }));
  };
  
  const actionColumn = {
    title: t("actionLabel"),
    key: "action",
    width: 150,
  
  render: (_: any, record: any) => (
    <Space>
      <ConfirmPopUp
        title={t("deleteLabel")}
        onConfirm={() => deleteRowById(record.id)} // Function to call on confirm
        description={t("areYouSureMsg")}  // Message displayed in the confirmation popup
        placement="left"
        id={record._id} // Unique ID for the record
        icon={deleteIcon} // Icon to display in the popup
  >
        <PrimaryButton className="action-btn mx-1">
          <img src={deleteIcon} alt={"close"} style={{ width: 18 }}></img> {/* Icon for delete button */}
        </PrimaryButton>
      </ConfirmPopUp>
      <ConfirmPopUp
        title={t("updateLabel")}
        onConfirm={() => updateMappingRowById(record)} // Function to call on confirm
        description={t("areYouSureMsg")} // Message displayed in the confirmation popup
        placement="left"
        id={record._id}
        icon={editIcon}
      >
        <PrimaryButton className="action-btn mx-1">
          {t("updateLabel")}
        </PrimaryButton>
      </ConfirmPopUp>
    </Space>
  ),
};
  const dynamicColumns =
    Array.isArray(tableData) && tableData.length > 0
      ? generateColumns(tableData)
      : [];
  const columns = [...dynamicColumns, actionColumn];

  async function deleteRowById(id: number) {
    const payload = {
      args: JSON.stringify({
        value: id,
        key: "id",
        tableName,
      }),
      app_id: appId,
      api_key: loginUser?.api_key,
    };

    try {
      setLoading(true);
      await centralApi(
        "GET",
        API_ENDPOINTS.DELETE_MAPPING_ROW_BY_ID,
        null,
        payload
      );
      showSuccess(t("sucessfullyDeletedLabel"));
      setLoading(false);

      // Refresh the data after deletion
      getMappingList({
        app_id: appId,
        api_key: loginUser.api_key,
        tableName,
      });
    } catch (error) {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (tableData.length > 0) {
      const keys = Object.keys(tableData[0]).filter((key) => key !== "id");
      const columnNames = keys.map((column) => ({
        title: getColumnTitle(column),
        key: column,
      }));
      // Set the columns once
      setColumns(columnNames);
    }
  }, [tableData]);
  const handleAddRow = async (values) => {
   
    // Prepare the payload for inserting the new row
    const payload = {
      args: JSON.stringify({
        ...values,  // The form values to insert
      }),
      tableName: tableName,  // The table name where the row will be inserted

      app_id: appId,  // Application ID
      api_key: loginUser?.api_key,  // API key for authentication
    };
  
    try {
      setLoading(true);  // Show loading spinner while the request is being made
  
      // Make the API call to insert the new row (use POST instead of GET)
      await centralApi(
        "POST",  // Use POST method to insert a row
        API_ENDPOINTS.INSERT_ROW_IN_MAPPING,  // The API endpoint for row insertion
        null,
        payload  // Send the payload containing form values and other data
      );
  
      // Show success message after the row is inserted
      showSuccess(t("rowInsertedLabel"));
  
      // Close the "Add Row" drawer/modal after insertion
      setAddRow({
        open: false,  // Close the drawer
      });
  
      // Refresh the data to reflect the newly inserted row
      getMappingList({
        app_id: appId,
        api_key: loginUser.api_key,
        tableName,
      });
  
      setLoading(false);  // Reset loading state after the request is complete
    } catch (error) {
      // Handle any errors that occur during the insertion
      setLoading(false);  // Reset loading state
      showError(t("somethingWrongLabel"));
    }
  };
  
  
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap my-3">
        <h4 className="fw-semi-bold">{t("c360dataMappingLabel")}</h4>
        <div className="d-flex gap-2">
          <PrimaryButton
            onClick={() => {
              setAddRow({
                open: true,
              });
            }}
            type="primary"
          >
            {t("addRowLabel")}
          </PrimaryButton>
        </div>
      </div>
      <div className="data-transformation-container">
        <div
          className={`${
            tableData.length === 0 ? "data-transformation-container-table" : ""
          } data-transformation-body p-3 mt-3`}
        >
          {dynamicColumns.length === 0 ? (
            <Spinner />
          ) : (
            <CustomTable
              dataSource={tableData}
              columns={columns}
              loading={loading}
              rowKey="id"
              size="small"
              scroll={{ x: 2500, y: 400 }}
            />
          )}
          <AddRow
            addRowState={addRow}
            setFormData={setRowData}
            setAddRowState={setAddRow}
            columns={column}
            onSave={handleAddRow}  // Passing onSave function to AddRow

          />
        </div>
      </div>
    </div>
  );
};

export default DataTransformation;
