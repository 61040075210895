import { Tag, Upload } from "antd";
import { FC, useState } from "react";
import Spinner from "../../../../../../components/Spinner/Spinner";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import { textOverflowThreeDot } from "../../../../../../helperFunctions/string";
import CustomTextArea from "../../../../../../components/Inputs/CustomTextArea/CustomTextArea";
import uploadIcon from "../../../../../../images/uploadImageCustom.svg";
import "./PushContainer.css";
import { useSelector } from "react-redux";
import {pushActionButtonType } from "@customTypes/template";
import { removeDuplicateUrl } from "helperFunctions/common";
import { replacePersonlizedVariable } from "../../Personalization/Personalization";
import { QueryPayload } from "utils/models";
interface PushContainerProps {
  type?: "IOS" | "Android";
  headerImage: string;
  expandedImage: string;
  expandedDescription: string;
  headerText: string;
  imageLoading?: {
    notificationImage: { loading: boolean };
    expandedNotificationImage: { loading: boolean };
  };
  variableInfo?:QueryPayload,
  descriptionText: string;
  uploadImage?: any;
  isExpandedImage: boolean;
  buttons?: pushActionButtonType[];
  mediaType?:string
}
const PushContainer: FC<PushContainerProps> = ({
  type,
  headerImage,
  imageLoading,
  expandedImage,
  uploadImage,
  headerText,
  variableInfo,
  descriptionText,
  isExpandedImage,
  expandedDescription,
  buttons,
  mediaType
}) => {
  const { appName } = useSelector((state: any) => state.activeApp);
  const [showExpandedContainer, setShowExpandedContainer] = useState(false);
  return (
    <div className="push-notification-parent-container mx-2 mt-2">
    <div className="   push-notification-main-container">
      <div className={`row px-2 pt-2 ${expandedDescription ? "pb-0" : "pb-2"}`}>
        <div
          className={`${
            type == "Android" ? "col-11 pb-2" : "col-2"
          }   pt-1  d-flex   gap-2 align-items-center`}
        >
          <Upload
            showUploadList={false}
            multiple={false}
            beforeUpload={() => false}
            onChange={(value: any) => uploadImage(value, "notificationImage")}
            disabled={!uploadImage}

          >
            <div>
              {imageLoading?.notificationImage?.loading ? (
                <Spinner />
              ) : (
                <img
                  loading="lazy"
                  src={ removeDuplicateUrl(headerImage) || uploadIcon}
                  style={{
                    height: 30,
                    width: 30,
                    borderRadius: "var(--border-radius-sm)",
                  }}
                />
              )}
            </div>
          </Upload>
          {headerImage && type == "Android" && (
            <span
              className="text-overflow-ellipsis"
              style={{
                fontWeight: "var(--font-weight-heading-strong)",
                //   background:"transparent",

                fontSize: "var(--font-size-sm)",
              }}
            >
              {appName}
            </span>
          )}
        </div>
        <div
          className={`${
            type == "Android"
              ? !isExpandedImage
                ? "col-12"
                : "col-9"
              : "col-8"
          }  d-flex gap-1  flex-column justify-content-center align-items-center`}
        >
          <TextInput
            className=" text-overflow-ellipsis"
            disabled={true}
            style={{
              color: "black",
              resize: "none",
              fontWeight: "var(--font-weight-heading-strong)",
              //   background:"transparent",

              fontSize: "var(--font-size-xsm)",
            }}
            value={replacePersonlizedVariable(headerText,variableInfo)}
          />
          <CustomTextArea
            disabled={true}
            className=""
            value={textOverflowThreeDot(
              replacePersonlizedVariable(descriptionText,variableInfo),
              showExpandedContainer || expandedDescription ? 60 : 40
            )}
            style={{
              lineHeight: 1.4,
              color: "black",
              resize: "none",
              fontSize: "var(--font-size-xsm)",
              opacity: 0.7,
            }}
          />
        </div>
        {isExpandedImage && (
          <div
            onClick={() => setShowExpandedContainer(!showExpandedContainer)}
            className={`${
              type == "Android" ? "col-3" : "col-2"
            }  cursor-pointer   d-flex  align-items-center justify-content-end `}
          >
           <div>
  {mediaType === "video" ? (
    <div className="m-0">
      <video  
            key={expandedImage} 
            style={{
        height: 35,
        objectFit: "cover",
        width: 35,
        borderRadius: "var(--border-radius-sm)",
      }} >
        <source src={removeDuplicateUrl(expandedImage) || uploadIcon} type="video/mp4" />
      </video>
    </div>
  ) : (
    <img
      loading="lazy"
      src={removeDuplicateUrl(expandedImage) || uploadIcon}
      style={{
        height: 35,
        objectFit: "cover",
        width: 35,
        borderRadius: "var(--border-radius-sm)",
      }}
      alt="media"
    />
  )}
</div>

          </div>
        )}
      </div>
      {/* expanded */}
      {((isExpandedImage && showExpandedContainer) || expandedDescription) && (
        <div className="row m-0 p-0 w-100 h-50">
          <div className="d-flex justify-content-center pb-2 px-2 h-75">
            {isExpandedImage ? (
              <Upload
              disabled={!uploadImage}
                showUploadList={false}
                multiple={false}
                className="p-0 m-0"
                accept=".jpg,.jpeg,.png,.gif,video/*"
                beforeUpload={() => false}
                onChange={(value: any) =>
                  uploadImage(value, "expandedNotificationImage")
                }
              >
               <div className="m-0">
  {imageLoading?.expandedNotificationImage?.loading ? (
    <Spinner />
  ) : (
    mediaType === "video" ? (
      <div className="m-0">
      <video key={expandedImage}  width="261" height="150" controls>
        <source src={removeDuplicateUrl(expandedImage) || uploadIcon} type="video/mp4" />
      </video>
      </div>
    ) : (
      <img
        alt="expandedImg"
        loading="lazy"
        src={removeDuplicateUrl(expandedImage) || uploadIcon}
        className="w-100"
        style={{
          maxHeight: 150,
          borderRadius: "var(--border-radius-lg)",
        }}
      />
    )
  )}
</div>

              </Upload>
            ) : (
              <CustomTextArea
                disabled={true}
                value={
                  textOverflowThreeDot( replacePersonlizedVariable(expandedDescription,variableInfo), 100)               
                } 
                style={{
                  height: 40,
                  lineHeight: 1.2,
                  color: "black",
                  resize: "none",
                  fontSize: "var(--font-size-xsm)",
                }}
              />
            )}
          </div>
        </div>
      )}
     {buttons && buttons?.length>0 && type=="Android" && <div className={`d-flex push-notification-buttons-container-android justify-content-evenly w-100`} >
        {buttons?.map(
          (button, index: number) =>
            button?.actionName && (
              <span
              className="text-overflow-ellipsis"
                key={index}
                style={{
                  color: "var(--color-main-primary)",
                  maxWidth:60,
                  fontWeight:600,
                }}
              >
                {button?.actionName}
              </span>
            )
        )}
      </div>}
    </div>
    {buttons && buttons?.length>0 && type=="IOS" && <div className={`d-flex  mt-1  flex-column push-notification-buttons-container-ios  w-100`} >
    {buttons?.map(
      (button, index: number) =>
        button?.actionName && (
          <div
          className="d-flex justify-content-center w-100"
            key={index}
            style={{
              color: "var(--color-main-primary)",
              fontWeight:600,
              borderBottom:buttons?.length-1!==index ?"1px solid var(--color-text-disabled)":""
            }}
          >
           <span className="text-overflow-ellipsis py-1">
           {button?.actionName}
            </span> 
          </div>
        )
    )}
  </div>}
  </div>
  );
};

export default PushContainer;
