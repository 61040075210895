import { useState, Fragment } from "react";
import InnerNav from "components/InnerNav/InnerNav";
import type { MenuProps } from "antd";
import "./AuditConfiguratorNav.css";
import { t } from "i18next";
import ConfigureModules from "../ConfigureModules/ConfigureModules";
import ConfigureColumns from "../ConfigureColumns/ConfigureColumns";

interface AuditConfiguratorNavProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

const AuditConfiguratorNav: React.FC<AuditConfiguratorNavProps> = ({
  currentAppKey,
  updateCurrentAppKey,
}) => {
  // Default tab and component mapping
  const tabComponents = {
    modules: <ConfigureModules
                currentAppKey={currentAppKey}
                updateCurrentAppKey={updateCurrentAppKey}
              />,
    columns: <ConfigureColumns 
                currentAppKey={currentAppKey}
                updateCurrentAppKey={updateCurrentAppKey}
            />,
  };

  // Default selected tab
  const [appTabFilter, setAppTabFilter] = useState<keyof typeof tabComponents>("modules");

  // Handle tab click
  const onTabClick: MenuProps["onClick"] = (e) => {
    setAppTabFilter(e.key as keyof typeof tabComponents);
  };

  return (
    <Fragment>
      <div className="mt-4 audit-configurator-nav-container">
        <div className="col-sm-11 audit-configurator-nav-para">
          <p>{t("auditLogsConfiguratorDesc")}</p>
        </div>
        <div className="audit-configurator-nav-container">
          <div className="app-setting-nav">
            <InnerNav
              theme="light"
              onTabClick={onTabClick}
              selected={appTabFilter}
              menu={[
                {
                  label: t("configureModulesLabel"),
                  key: "modules",
                },
                {
                  label: t("configureColumnsLabel"),
                  key: "columns",
                },
              ]}
            />
          </div>
          {/* Render the selected component */}
          {tabComponents[appTabFilter]}
        </div>
      </div>
    </Fragment>
  );
};

export default AuditConfiguratorNav;
