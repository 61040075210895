import React, { useState, useEffect } from "react";
import { Divider, Form, Space } from "antd";
import "./Partner.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import { MinusCircleOutlined } from "@ant-design/icons";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import { t } from "i18next";
import Spinner from "../../../../components/Spinner/Spinner";
import Selector from "../../../../components/Selector/Selector";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../services/centralApi";
import useMessage from "hooks/useMessage";
import { getToken } from "redux/features/app/authTokenSlice";
import { API_ENDPOINTS } from "utils/constants";
import { CampaignChannelTypeEnum } from "utils/enums";

interface Partner {
  partnerName: string;
  channel: string;
  default: boolean;
}

const Partner: React.FC = () => {
  const [partnerData, setPartnerData] = useState<Partner[]>([]);
  const [loading, setLoading] = useState(false);
  const { showError, showSuccess } = useMessage();
  const dispatch: any = useDispatch();

  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);

  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;

const handleCheckboxChange = (index: number) => {
  const updatedPartnerData = partnerData.map((partner, i) => {
    // Check if the partner's channel matches the clicked partner's channel
    if (i === index) {
      // Toggle the selected state of the clicked checkbox
      return { ...partner, default: !partner.default };
    }

    // Ensure that only one partner per channel can be set to 'default: true'
    if (partner.channel === partnerData[index].channel) {
      return { ...partner, default: false }; // Set all others in the same channel to false
    }

    return partner;
  });

  setPartnerData(updatedPartnerData);
};


  const handleAddClick = () => {
    const newPartner = {
      partnerName: "",
      channel: "NONE",
      default: false,
    };

    setPartnerData((prevState) => [...prevState, newPartner]);
  };

  const handleRemoveClick = (index: number) => {
    setPartnerData((prevState) => prevState.filter((_, i) => i !== index));
  };

  // Handle text input change
  const handleInputChange = (index: number, field: string, value: string) => {
    const updatedPartnerData = partnerData.map((partner, i) =>
      i === index ? { ...partner, [field]: value } : partner
    );
    setPartnerData(updatedPartnerData);
  };

  // Handle selector change
  const handleSelectorChange = (index: number, value: string) => {
    const updatedPartnerData = partnerData.map((partner, i) =>
      i === index ? { ...partner, channel: value } : partner
    );
    setPartnerData(updatedPartnerData);
  };
  const onFormSubmit = async () => {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        partnerData,
      }),
      app_id: appId,
      api_key: api_key,
      authToken,
    };

    try {
      setLoading(true);
      await centralApi("POST", API_ENDPOINTS.SAVE_PARTNER_INFO, payload, null);
      showSuccess(t("sucessfullySavedLabel"));
      setLoading(false);
      getPartnerInfo(); // Fetch updated partner data
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    }
  };

  async function getPartnerInfo() {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
      }),
      app_id: appId,
      api_key: api_key,
    };

    try {
      setLoading(true);
      const data = await centralApi("GET", API_ENDPOINTS.GET_PARTNER_INFO, null, payload);
      setPartnerData(data?.partnerInfo?.partnerData || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    }
  }

  useEffect(() => {
    getPartnerInfo();
    dispatch(getToken());
  }, [appId]);

  return (
    <div className="partner-setting-container">
      <div className="mt-4 mb-4">
        <h6 className="partner-setting-container-heading">{t("addOrRemovePartnerLabel")}</h6>
        <p className="partner-setting-form-headings">{t("partnerSettingText")}</p>
        <Divider className="partner-setting-custom-divider" />

        <div>
          <div className="col-sm-8 mt-4 align-items-center partner-setting-form">
            {loading ? (
              <Spinner />
            ) : (
              <>
                {partnerData.map((partner, index) => (
                  <Space key={index} style={{ display: "flex" }} align="baseline">
                                    <Form.Item>

                    <CustomCheckbox
                      checked={partner.default}
                      onChange={() => handleCheckboxChange(index)}
                    >
                      {t("selectPartner")}
                    </CustomCheckbox>
                    </Form.Item>
                    <Form.Item>

                    <TextInput
                      value={partner.partnerName}
                      onChange={(e) => handleInputChange(index, "partnerName", e.target.value)}
                      maxLength={100}
                      placeholder="Partner Name"
                    />
                    </Form.Item>
                <Form.Item>

                    <Selector
                      value={partner.channel}
                      onChange={(value) => handleSelectorChange(index, value)}
                      size="middle"
                      style={{ width: 150 }}
                      options={[
                        { value: "NONE", label: t("noneLabel") },
                        { value: CampaignChannelTypeEnum.EMAIL, label: t("emailLabel") },
                        { value: CampaignChannelTypeEnum.WHATSAPP, label: t("whatsappLabel") },
                        { value: CampaignChannelTypeEnum.SMS, label: t("smsLabel") },
                      ]}
                    />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => handleRemoveClick(index)} />
                  </Space>
                ))}

                <Form.Item>
                  <PrimaryButton type="primary" onClick={handleAddClick}>
                    {t("addPartnerLabel")}
                  </PrimaryButton>
                </Form.Item>

                <Form.Item>
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    onClick={onFormSubmit}
                    style={{ border: "none", width: "70px" }}
                  >
                    {t("saveLabel")}
                  </PrimaryButton>
                </Form.Item>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
