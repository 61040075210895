import React, { useState } from 'react';
import { TreeSelect } from 'antd';
import "./TreeSelector.css"
const TreeSelector: React.FC<any> = ({...props}) => {
  
  return (
    <span className='custom-ant-treeselector-parent-container'>

    <TreeSelect
      {...props}
      filterTreeNode={(inputValue, treeNode) => {
        const label = treeNode.title?.toString().toLowerCase() || "";
        
        // Perform the filter logic based on input and label
        return label.includes(inputValue.toLowerCase());
      }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto',scrollBehavior:"smooth", scrollbarWidth:"none" }}
      />
      </span>
  );
};

export default TreeSelector;
