import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import { CustomData } from "../CustomData/CustomData";
import { v4 as uuid } from "uuid";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import { Tag } from "antd";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { LiveActivityTag } from "utils/enums";
interface LiveActivityProps {
  formData?: Record<any, any>;
  onSave?: any;
  setShowLiveActivityDrawer?:any;
}

const LiveActivity: FC<LiveActivityProps> = ({ formData, onSave,setShowLiveActivityDrawer }: any) => {
  const [isCopyToAll, setIsCopyToAll] = useState(false); // State to track checkbox
    const [activeTag, setActiveTag] = useState<LiveActivityTag>(LiveActivityTag.Start);

  const commonStructure = {
    key: "",
    value: "",
    type: "string",
  };

  const getContentAttributess = (section: any = {}) => ({
    content: section?.content?.length > 0 ? {} : { [uuid()]: commonStructure },
    attributes:
      section?.attributes?.length > 0 ? {} : { [uuid()]: commonStructure },
  });
  const [liveActivityData, setLiveActivityData] = useState({
    start: getContentAttributess(),
    update: getContentAttributess(),
    end: getContentAttributess(),
  });

  // The handleTagToggle function with the Tag enum
const handleTagToggle = (tag: LiveActivityTag) => {
  setActiveTag(tag);
};

  const handleSave = () => {
    onSave(liveActivityData);
  };
  useEffect(() => {
    // Initialize the newData structure with empty content and attributes for each tag
    const newData:any = {
      start: getContentAttributess(formData.start),
      update: getContentAttributess(formData.update),
      end: getContentAttributess(formData.end),
    };

    // Map over formData and populate newData accordingly
    Object.keys(formData).forEach((tag) => {
      const { content, attributes } = formData[tag];

      // Map content and attributes, adding UUIDs for each item
      content.forEach((item:any) => {
        const keys = Object.keys(item);
        const key = keys[0]; // The key of the content field
        const value = item[key]; // The value of the content field
        const type = item.type; // The type of the field

        const newEntry = { key, value, type };
        const newUUID = uuid();
        newData[tag].content[newUUID] = newEntry; // Add to content for this tag
      });

      attributes.forEach((item:any) => {
        const keys = Object.keys(item);
        const key = keys[0]; // The key of the attribute field
        const value = item[key]; // The value of the attribute field
        const type = item.type; // The type of the field

        const newEntry = { key, value, type };
        const newUUID = uuid();
        newData[tag].attributes[newUUID] = newEntry; // Add to attributes for this tag
      });
    });
    setLiveActivityData(newData);
   // Assuming formData is defined somewhere
const activeTag: any =
  (Object.keys(formData || {}).find(
    (tag) =>
      formData?.[tag]?.content.length > 0 || formData?.[tag]?.attributes.length > 0
  ) as keyof typeof formData) || LiveActivityTag.Start; // Default to 'start' if no tag has data or if formData is undefined

// Set the active tag dynamically
setActiveTag(activeTag);

    // Update the liveActivityData with the newly mapped data
  }, [formData]); // Dependency on formData
  // Function to update the custom data state dynamically for a given tag and type (content or attributes)
  const handleCustomDataChange = (
    newState: Record<string, any>,
    type: "content" | "attributes"
  ) => {
    // This function updates the state by merging with the previous state
    setLiveActivityData((prevState) => ({
      ...prevState,
      [activeTag]: {
        ...prevState[activeTag],
        [type]: newState,
      },
    }));
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCopyToAll(e.target.checked);

    if (e.target.checked) {
      // Copy the content and attributes from the active tag to the other tags
      const copiedData = liveActivityData[activeTag];
      setLiveActivityData((prevState) => ({
        ...prevState,
        start: copiedData,
        update: copiedData,
        end: copiedData,
      }));
    } else {
      // If unchecked, reset the data to original state
      setLiveActivityData({
        start: getContentAttributess(),
        update: getContentAttributess(),
        end: getContentAttributess(),
      });
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex   gap-1  align-items-center"></div>

      <div className="d-flex  mt-2  gap-1">
        <Tag
          color={activeTag === "start" ? "blue" : "default"}
          onClick={() => handleTagToggle(LiveActivityTag.Start)}
        >
          {t("startLabel")}
        </Tag>
        <Tag
          color={activeTag === "update" ? "blue" : "default"}
          onClick={() => handleTagToggle(LiveActivityTag.Update)}
        >
          {t("updateLabel")}
        </Tag>
        <Tag
          color={activeTag === "end" ? "blue" : "default"}
          onClick={() => handleTagToggle(LiveActivityTag.End)}
        >
                    {t("endLabel")}

        </Tag>
        <CustomCheckbox checked={isCopyToAll} onChange={handleCheckboxChange} />
        <p className="m-0">{t("copyTemplateLabel")}</p>
      </div>
      <div className="d-flexflex-column  gap-1  align-items-center mt-2">
        <p className="m-0">{t("contentStateLabel")}</p>

        <CustomData
          customDataState={liveActivityData[activeTag].content} // Pass content state dynamically
          onChange={(newState:any) => handleCustomDataChange(newState, "content")} // Update content state
        />
      </div>
      <div className="d-flexflex-column  gap-1  align-items-center mt-2">
        <p className="m-0">{t("attributesLabel")}</p>

        <CustomData
          customDataState={liveActivityData[activeTag].attributes} // Pass content state dynamically
          onChange={(newState:any) =>
            handleCustomDataChange(newState, "attributes")
          } // Update content state
        />
      </div>
      <div className=" d-flex gap-2 mt-auto ms-auto">
        <PrimaryButton onClick={handleSave} type="primary">
          {t("saveLabel")}
        </PrimaryButton>
         <PrimaryButton
              onClick={() => {
                setShowLiveActivityDrawer(false);
              }}
            >
              {t("cancelLabel")}
            </PrimaryButton>
      </div>
    </div>
  );
};

export default LiveActivity;
