"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppSettings = exports.PartnerData = exports.HmsConfig = exports.CampaignMapping = exports.Proxy = exports.GenericConfig = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
const infrastructure_1 = require("../config/infrastructure");
// Configuration class for Generic settings
let GenericConfig = class GenericConfig {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], GenericConfig.prototype, "Verb", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], GenericConfig.prototype, "callBackUrl", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], GenericConfig.prototype, "URL", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], GenericConfig.prototype, "emailProviderType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], GenericConfig.prototype, "partner", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], GenericConfig.prototype, "channel", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], GenericConfig.prototype, "key_value", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], GenericConfig.prototype, "api_key", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], GenericConfig.prototype, "app_id", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], GenericConfig.prototype, "appKey", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], GenericConfig.prototype, "partner_id", void 0);
GenericConfig = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], GenericConfig);
exports.GenericConfig = GenericConfig;
// Configuration class for SMS providers
let SmsConfig = class SmsConfig {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], SmsConfig.prototype, "gupshup", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], SmsConfig.prototype, "sinch", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", void 0)
], SmsConfig.prototype, "proxy", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", GenericConfig)
], SmsConfig.prototype, "generic", void 0);
SmsConfig = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], SmsConfig);
// Configuration class for Email providers
let EmailCfg = class EmailCfg {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], EmailCfg.prototype, "aws_ses", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], EmailCfg.prototype, "netcore", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], EmailCfg.prototype, "smtp", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", GenericConfig)
], EmailCfg.prototype, "generic", void 0);
EmailCfg = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], EmailCfg);
// Configuration class for WhatsApp providers
let WhatsAppCfg = class WhatsAppCfg {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], WhatsAppCfg.prototype, "gupshup", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", GenericConfig)
], WhatsAppCfg.prototype, "generic", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], WhatsAppCfg.prototype, "karix", void 0);
WhatsAppCfg = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], WhatsAppCfg);
(0, typescript_json_serializer_1.JsonObject)();
class Proxy {
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], Proxy.prototype, "sms", void 0);
exports.Proxy = Proxy;
// Configuration class for PassThrough settings
let PassThrough = class PassThrough {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], PassThrough.prototype, "url", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], PassThrough.prototype, "verb", void 0);
PassThrough = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], PassThrough);
// Configuration class for WebPushConfig settings
let WebPushConfig = class WebPushConfig {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], WebPushConfig.prototype, "privateKey", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], WebPushConfig.prototype, "publicKey", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], WebPushConfig.prototype, "subject", void 0);
WebPushConfig = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], WebPushConfig);
// Represents a validator that applies to fields in the mappings
let Validator = class Validator {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Validator.prototype, "type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Validator.prototype, "value", void 0);
Validator = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], Validator);
// Represents the response mapping for data transformation
let ResponseMapping = class ResponseMapping {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ResponseMapping.prototype, "sourceKey", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ResponseMapping.prototype, "sourceValue", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ResponseMapping.prototype, "sourceDataType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Validator)
], ResponseMapping.prototype, "validator", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ResponseMapping.prototype, "destinationKey", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ResponseMapping.prototype, "destinationValue", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ResponseMapping.prototype, "destinationDataType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], ResponseMapping.prototype, "isMandatory", void 0);
ResponseMapping = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], ResponseMapping);
// Represents the request mapping for data transformation
let RequestMapping = class RequestMapping {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], RequestMapping.prototype, "sourceKey", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], RequestMapping.prototype, "sourceValue", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], RequestMapping.prototype, "sourceDataType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Validator)
], RequestMapping.prototype, "validator", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], RequestMapping.prototype, "destinationKey", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], RequestMapping.prototype, "destinationValue", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], RequestMapping.prototype, "destinationDataType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], RequestMapping.prototype, "isMandatory", void 0);
RequestMapping = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], RequestMapping);
// Represents the configuration and mapping details of a campaign for a specific channel (e.g., SMS, WhatsApp)
let CampaignMapping = class CampaignMapping {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], CampaignMapping.prototype, "verb", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], CampaignMapping.prototype, "callBackUrl", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], CampaignMapping.prototype, "url", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], CampaignMapping.prototype, "providerType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], CampaignMapping.prototype, "channel", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], CampaignMapping.prototype, "validatorMappings", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: RequestMapping }),
    __metadata("design:type", Array)
], CampaignMapping.prototype, "requestMappings", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: ResponseMapping }),
    __metadata("design:type", Array)
], CampaignMapping.prototype, "responseMappings", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], CampaignMapping.prototype, "api_key", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], CampaignMapping.prototype, "app_id", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], CampaignMapping.prototype, "appKey", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], CampaignMapping.prototype, "partner_id", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], CampaignMapping.prototype, "payload_key", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], CampaignMapping.prototype, "partner", void 0);
CampaignMapping = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], CampaignMapping);
exports.CampaignMapping = CampaignMapping;
// Represents the overall configuration for campaigns, supporting multiple channels (e.g., SMS, WhatsApp)
let CampaignConfigurator = class CampaignConfigurator {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: CampaignMapping }),
    __metadata("design:type", CampaignMapping)
], CampaignConfigurator.prototype, "SMS", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: CampaignMapping }),
    __metadata("design:type", CampaignMapping)
], CampaignConfigurator.prototype, "WhatsApp", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: CampaignMapping }),
    __metadata("design:type", CampaignMapping)
], CampaignConfigurator.prototype, "EMAIL", void 0);
CampaignConfigurator = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], CampaignConfigurator);
// Configuration class for HMS settings (Huawei Push Service)
let HmsConfig = class HmsConfig {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], HmsConfig.prototype, "appId", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], HmsConfig.prototype, "appSecret", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], HmsConfig.prototype, "proxyEnabled", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], HmsConfig.prototype, "host", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], HmsConfig.prototype, "port", void 0);
HmsConfig = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], HmsConfig);
exports.HmsConfig = HmsConfig;
let PartnerData = class PartnerData {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], PartnerData.prototype, "partnerName", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], PartnerData.prototype, "channel", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], PartnerData.prototype, "partnerId", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], PartnerData.prototype, "default", void 0);
PartnerData = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], PartnerData);
exports.PartnerData = PartnerData;
// Main configuration class for AppSettings
let AppSettings = class AppSettings {
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: infrastructure_1.InfrastructureSettings }),
    __metadata("design:type", infrastructure_1.InfrastructureSettings)
], AppSettings.prototype, "infrastructureSettings", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: SmsConfig }),
    __metadata("design:type", SmsConfig)
], AppSettings.prototype, "smsCfg", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: EmailCfg }),
    __metadata("design:type", EmailCfg)
], AppSettings.prototype, "emailCfg", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: EmailCfg }),
    __metadata("design:type", Proxy)
], AppSettings.prototype, "proxy", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: WhatsAppCfg }),
    __metadata("design:type", WhatsAppCfg)
], AppSettings.prototype, "whatsAppCfg", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: PassThrough }),
    __metadata("design:type", PassThrough)
], AppSettings.prototype, "passThrough", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: WebPushConfig }),
    __metadata("design:type", WebPushConfig)
], AppSettings.prototype, "webPush_prod", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: WebPushConfig }),
    __metadata("design:type", WebPushConfig)
], AppSettings.prototype, "webPush_dev", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "whatsAppProviderType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "emailProviderType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "smsProviderType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "pushProviderType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "appInboxType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "webpushProviderType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "storageProviderType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "queueProviderType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], AppSettings.prototype, "isappmodeproduction", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "ios_cert3", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "android_cert3", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "dev_ios_key_type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "ios_key_type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "scriptCode", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "dev_android_cert3", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "dev_ios_cert_key", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "ios_cert_key", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "ios_cert_pem", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "dev_ios_cert_pem", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "proxyUrl", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "proxyPORT", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "dev_ios_cert3", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "ios_teamid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "ios_keyid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "ios_topic", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "ios_bundleid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "ios_passphrase", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "ios_host", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "dev_ios_teamid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "dev_ios_keyid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "dev_ios_topic", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "dev_ios_host", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], AppSettings.prototype, "fcm_batchSize", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], AppSettings.prototype, "fcm_chunkSize", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], AppSettings.prototype, "fcm_delay", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "fcmKey_prod", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "fcmKey_dev", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], AppSettings.prototype, "dev_fcm_batchSize", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], AppSettings.prototype, "dev_fcm_chunkSize", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], AppSettings.prototype, "dev_fcm_delay", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "fcmKey", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "dev_ios_bundleid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "dev_ios_passphrase", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "http_proxy", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AppSettings.prototype, "dev_http_proxy", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], AppSettings.prototype, "dev_isJsonEnabled", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], AppSettings.prototype, "prod_isJsonEnabled", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], AppSettings.prototype, "getAppEnabled", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], AppSettings.prototype, "dev_apns_batchSize", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], AppSettings.prototype, "dev_apns_chunkSize", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], AppSettings.prototype, "dev_apns_delay", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], AppSettings.prototype, "apns_batchSize", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], AppSettings.prototype, "apns_chunkSize", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], AppSettings.prototype, "apns_delay", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: CampaignConfigurator }),
    __metadata("design:type", CampaignConfigurator)
], AppSettings.prototype, "campaignConfigurator", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], AppSettings.prototype, "partnerInfo", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], AppSettings.prototype, "hms", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], AppSettings.prototype, "auditLogsConfig", void 0);
AppSettings = __decorate([
    (0, typescript_json_serializer_1.JsonObject)()
], AppSettings);
exports.AppSettings = AppSettings;
