import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import React, { FC } from "react";
import deleteIcon from "../../images/trash1.svg";
import "./SegmentBlockLayout.css";
import AccessControl from "components/HOC/AccessControl/AccessControl";
import { roleCheckTypes } from "utils/constants/constants";
interface LayoutProps {
  children?: React.ReactNode;
  onDeleteClick?: (e: any) => void;
  showSteps?:boolean;
  index?:number
}

const SegmentBlockLayout: FC<LayoutProps> = ({ children,onDeleteClick,showSteps,index}) => {
  
  return (
    <div className="pb-2">
       
      <div className=" my-2 segment-condition-block-layout position-relative ">
      {showSteps && index !== undefined &&
      <div className="mb-2">
       <span 
       style={{
            position: "absolute",
            left: "30px",
            top: "2px",
            zIndex: 100,
          }}
          >Step {index +1}</span>
      </div>}
      {onDeleteClick &&
       <AccessControl accessControl={{role:[roleCheckTypes.Contributor]}}>
        <PrimaryButton
          type="link"
          style={{
            position: "absolute",
            right: "5px",
            top: "2px",
            zIndex: 100,
          }}
          onClick={onDeleteClick}
        >
          <img src={deleteIcon}></img>
        </PrimaryButton>
        </AccessControl>
        }
        <div className="d-flex flex-row w-100">
          <div className="w-100">
            <div className="w-100  overflow-scroll">
                {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SegmentBlockLayout;
